import { makeStyles } from '@mui/styles';

const NavbarStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    padding: 0,
    marginLeft: '2%',
  },
  title: {
    flexGrow: 1,
  },
  navButton: {
    paddingLeft: 0,
    marginRight: '5vw',
    fontSize: 'medium',
    fontFamily: 'Montserrat',
  },
  profileButton: {
    // backgroundColor: '#2596be',
    // color: 'white',
    // fontFamily: 'Montserrat',
    // borderRadius: '50%',
    // minHeight: '40px',
    // maxHeight: '40px',
    // minWidth: '40px',
    // maxWidth: '40px',
    // paddingLeft: '8px',
  },
}));

export default NavbarStyle;

