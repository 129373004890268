import axios from "axios";
let UrlFromFetch;

async function APICALL() {
  if (UrlFromFetch !== undefined) {
    return UrlFromFetch;
  } else {
    const response = await fetch("/config.json");
    const text = response.json();
    UrlFromFetch = text;

    return text;
  }
}
const getAllProducts = (callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const config = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  APICALL().then((response) => {
    return axios
      .get(response.url + "product", config)
      .then((result) => {
        callback.onSuccess(result);
      })
      .catch((error) => {
        callback.onError(error);
      });
  });
};

export { getAllProducts };
