import {makeStyles} from "@mui/styles";
import ContactImg from "../../images/svg/ContactImg.PNG";

const ContactStyle = makeStyles((theme) => ({
  topContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundImage: `url(${ContactImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    justifyContent: "center",
    color: "white",
    height: "350px",
  },
  topGrid: {
    // marginTop: "75px",
    // justifyContent: "center",
    width: "800px",
    position: "relative"
  },
  topTitle: {
    width: "256px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  topPar: {
    textAlign: "center",
    marginLeft: "90px",
    marginRight: "90px",
    marginTop: "30px",
  },

  contactGrid: {
    paddingTop: "50px",
    paddingLeft: "40px",
  },


  formPar: {
    maxWidth: "450px",

    width: '95%',
    marginBottom: "55px",
    fontWeight: "bold",
  },
  contactPar: {
    maxWidth: "450px",

    width: '95%',
    fontWeight: "bold",
    marginBottom: "60px",
  },
  select: {
    maxWidth: "453px",

    width: '95%',
    marginBottom: "55px",
    border: "0",
    outline: "0",
    // background: "transparent",
    borderBottom: "1px solid #0079C2",
    fontSize: "large",
    color: "#0079C2",
    fontFamily: "Montserrat",
    padding: "10px",
    background: "#F8F8F8",
  },
  input: {
    maxWidth: "450px",
    width: '95%',
    marginBottom: "55px",
    border: "0",
    outline: "0",
    background: "transparent",
    borderBottom: "1px solid #CACACA",
    fontSize: "large",
    // color: "#CACACA",
    fontFamily: "Montserrat"
  },
  button: {
    marginTop: "30px",
    borderRadius: "25px",
    width: "223px",
    height: "40px",
    backgroundColor: "#0079C2",
    color: "white",
    // marginLeft: "238px",
    border: "none",
    fontFamily: "Montserrat",
    fontSize: "19px",
    marginBottom: "80px"
  },
  // buttonDisabled: {
  //   color: "rgba(0, 0, 0, 0.26)",
  //   boxShadow: "none",
  //   backgroundColor: "rgba(0, 0, 0, 0.12)",
  //   marginTop: "30px",
  //   borderRadius: "25px",
  //   width: "160px",
  //   height: "35px",
  //   marginLeft: "295px",
  //   border: "0",
  //   fontFamily: "Montserrat",
  //   marginBottom: "80px"
  // },

  rightGridContact: {
    marginLeft: "30px",
    marginBottom: "auto",
    marginTop: "auto",
  },
  nomPar: {
    fontWeight: "bolder",
  },
  mailPar: {
    color: "#0079C2",
  },

  parPrivacy: {
    marginBottom: "30px",
    maxWidth: "450px",
    width: '100%',
    color: "#333333b5",
    lineHeight: "1.5",
    textAlign: "justify",
    fontSize: "12px"
  },
  firstGridPrivacy: {
    marginBottom: "25px",
    maxWidth: "450px",
    width: '95%',
    lineHeight: "1.5",
    textAlign: "justify",
    fontSize: "12px",
    color: "#333333b5",
  },
  secondGridPrivacy: {
    marginBottom: "25px",
    maxWidth: "450px",
    width: '95%',
    lineHeight: "1.5",
    textAlign: "justify",
    fontSize: "12px",
    color: "#333333b5",
  }
}));

export default ContactStyle;
