import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import emptyItem from "../../images/svg/emptyItem.svg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  CheckFavoriteProduct,
  PostFavoritProduct,
  ProduitByID,
  RemoveFavoriteProduct,
} from "./../../API/APIRequest/Categories";
import DropDownChantierInProductDetail from "./../../components/DropDownChantierInProductDetail";
import notifyError from "./../../Shared/notifyError";
import notifySuccess from "./../../Shared/notifySuccess";
import DetailStyle from "./style";
import "./style.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import BackButton from "../../components/BackButton";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, Link, TextField, Typography } from "@mui/material";
import PopUpAddChantier from "../../components/PopUpAddChantier";
import Event from "../../EventEmitter";

const DetailProduct = ({ appInsights }) => {
  let history = useHistory();

  //GET ID PARAMETER FROM URL
  const id = history.location.state.id;

  const solution = history.location.state.solution;
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [caracSelected, setCaracSelected] = useState(true);
  const [descripSelected, setDescripSelected] = useState(false);
  // split Description containing html Link Tag + trim ending blankspace
  const description = String(product.description).split("<a")[0].trimEnd();
  // if Description contain html Link Tag, load it in descLink
  const descLink = String(product.description).split("<a")[1]
  // split Caractèristique containing html Link Tag + trim ending blankspace
  const caracteristiques = String(product.caracteristiques).split("<a")[0].trimEnd();
  // if Caractèristique contain html Link Tag, load it in caracLink
  const caracLink = String(product.caracteristiques).split("<a")[1]
  const besoinChantier = history.location.state.calculateur;
  const [openPopUp, setOpenPopUp] = useState(false);
  const handleClose = () => {
    setOpenPopUp(false);
  };

 

  //IMPORT STYLE
  const classes = DetailStyle;
  //Favorite Management
  const [isFavorite, setIsFavorite] = useState(false);
  var accountInfo = localStorage.getItem("accountInfo");
  var accountInfoParsed = JSON.parse(accountInfo);
  const idUser = accountInfoParsed.account.accountIdentifier;
  useEffect(() => {


    const productInfo = {
      idUser: history.location.state.idUser,
      idProduct: id,
    };
    ProduitByID(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setProduct(response.data.data);
            setIsLoading(false);
          } else {
            notifyError(response.data.error.message);
            setTimeout(() => {
              history.push('/produits');
            }, "2500");
          }
        },
        onError: (error) => notifyError(error),
      },
      id
    );
    CheckFavoriteProduct(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            //Is Favorite ?
            if (response.data.data === false) {
              setIsFavorite(false);
            } else {
              setIsFavorite(true);
            }
          } else {
            return notifyError(response.data.error.message);
          }
        },
        onError: (error) => notifyError(error),
      },
      productInfo
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //CHANGE STYLE BUTTON ONCLICK
  const handleCaracChange = () => {
    setCaracSelected(true);
    setDescripSelected(false);
  };
  const handleDescripChange = () => {
    setCaracSelected(false);
    setDescripSelected(true);
  };
  const FavoriteAddOrRemove = () => {
    //Add Or Remove Favorite
    const productRemoveInfo = {
      idUser: history.location.state.idUser,
      idProduct: id,
    };

    const productAddInfo = {
      idUser: history.location.state.idUser,
      idProduct: id,
      productName: product.name,
      productImage: product.url ? product.url : "",
    };

    if (isFavorite) {
      RemoveFavoriteProduct(
        {
          onSuccess: (response) => {
            if (response.data.isSuccess === true) {
              notifySuccess("Le favori a été supprimé");
              setIsFavorite(false);
            } else {
              notifyError("Une erreur est survenue");
            }
          },
          onError: (error) => {
            notifyError("Une erreur est survenue");
          },
        },
        productRemoveInfo
      );
    } else {
      PostFavoritProduct(
        {
          onSuccess: (response) => {
            if (response.data.isSuccess === true) {
              notifySuccess("Le favori a été ajouté");
              setIsFavorite(true);
            } else {
              notifyError("Une erreur est survenue");
            }
          },
          onError: (error) => {
            notifyError("Une erreur est survenue");
          },
        },
        productAddInfo
      );
    }
  };

  useEffect(() => {
    if (!(history?.location?.state?.isFromFavorite || isNaN(history?.location?.state?.solution?.surfaceChantier))){
      Event.emit("backToSearch", {
        idUser: history.location.state.idUser,
        solution: solution,
        idConstructionType: solution.idConstructionType,
        isFromDetail: true,
      });
    }
  }, []);

  const backToSearchRedirect = () => {
    return history.push({
      pathname: "/recherche",
      state: {
        idUser: history.location.state.idUser,
        solution: solution,
        idConstructionType: solution.idConstructionType,
        isFromDetail: true,
      },
    });
  };

  return (
    // MAIN CONTAINER
    <Grid container style={classes.mainContainer}>
      <ToastContainer />
      <Grid
        container
        sx={{
          minHeight: '80vh'
        }}
      >
        {isLoading ? (
          <Box style={{ position: "absolute", top: "50vh", left: "50vw" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* TOP CONTAINER */}
            <Grid container style={classes.topContainer}>
              <Grid
                marginX={"auto"}
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '1400px',
                  width: '95%',
                  color: 'white',
                }}
                sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}
              >
                <Breadcrumbs productName={product.name} variantColor={'blue'} />
                <BackButton variantColor={'blue'} redirectMethod={(history?.location?.state?.isFromFavorite || isNaN(history?.location?.state?.solution?.surfaceChantier)) ? null : backToSearchRedirect} />
              </Grid>
              <Grid container sx={{
                marginRight: "auto",
                marginLeft: "auto",
                padding: '24px',
              }}>
                <Grid container mobile={12} sx={{ justifyContent: 'center' }}>
                  <Grid

                    sx={{
                      marginTop: { mobile: '30px', desktop: '100px' },
                      order: { mobile: 2, desktop: 1 },
                      '& img': {
                        background: 'white',
                      },
                      display: 'flex',
                    }}
                  >
                    <img
                      src={product.url ? product.url : emptyItem}
                      alt="product"
                      style={classes.Image}
                    />
                  </Grid>
                  <Grid
                    desktop={6}
                    sx={{
                      marginLeft: { mobile: 0, desktop: '6%' },
                      order: { mobile: 1, desktop: 2 },
                    }}
                  >
                    <h1 style={{ color: "#0079C2", marginTop: "50px" }}>{product.name}</h1>
                    <Grid sx={{
                      display: "flex",
                      flexDirection: { mobile: 'column', desktop: "row" },
                      alignItems: { mobile: 'start', desktop: "center" },
                      margin: '24px 0'
                    }}>
                      <DropDownChantierInProductDetail
                        setOpenPopUp={setOpenPopUp}
                        productInfos={product}
                      />
                      <Button
                        variant="contained"
                        color={"primary"}
                        sx={{
                          borderRadius: "25px",
                          fontFamily: "Montserrat",
                          fontSize: "19px",
                          fontWeight: "bold",
                          padding: "8px 22px",
                          height: '40px',
                          margin: { mobile: '24px 0 0 0', desktop: '0 0 0 16px' },
                          display: "flex",
                          width: 'fit-content',
                        }}
                        onClick={() => FavoriteAddOrRemove()}
                        aria-label="Bouton ajouter retirer favoris"
                      >
                        {isFavorite ? <StarIcon style={{ color: '#ffcc01' }} color={'inherit'} /> : <StarBorderIcon />}
                        <Typography
                          style={{ fontFamily: "Montserrat", marginLeft: '10px' }}
                        >
                          Favoris
                        </Typography>
                      </Button>
                    </Grid>
                    <Dialog
                      open={openPopUp}
                      maxWidth='xl'
                      onClose={handleClose}
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                      }}
                    >
                      <DialogContent
                        style={{
                          paddingTop: '35px',
                          paddingRight: '5px',
                          paddingLeft: '30px',
                          maxWidth: '760px',
                        }}>
                        <PopUpAddChantier
                          fromDetail={true}
                          produitsList={product}
                          close={handleClose}
                          idUser={history.location.state.idUser}
                        />
                      </DialogContent>
                    </Dialog>

                    {description &&
                      <>
                        <Grid
                          sx={{ width: '100%', marginTop: '16px', height:'auto' }}
                        >
                          <Typography sx={{ textTransform: 'uppercase' }}>caractéristiques</Typography>
                        </Grid>
                        <TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          id="standard-multiline-flexible"
                          multiline
                          defaultValue={description}
                          disabled
                          sx={{
                            width: '100%',
                            '& .Mui-disabled': {
                              color: 'black !important',
                              '-webkit-text-fill-color': 'black !important',
                            },
                          }}
                        />
                        {descLink && 
                          <Grid 
                            dangerouslySetInnerHTML={{__html:"<a"+descLink}} 
                            sx={{ 
                              fontSize: {mobile: '', desktop: '20px'}, 
                              marginTop: {mobile: '0px', desktop: '0px'},
                              marginBottom: {mobile: '30px', desktop: '30px'},
                              '& a': {
                                color: 'rgb(22, 121, 194)', 
                                textDecoration: 'none',
                                fontWeight: 'bold',
                              }
                            }}
                          />
                        }
                      </>}
           
                    {history.location.state.isFromFavorite ===
                      true ? null : product.isDeleted ? (
                        <Typography
                          sx={{ fontFamily: "Montserrat", marginLeft: '10px', color: 'red', fontSize: '1.5em', width: '100%' }}
                        >
                          Produit en rupture de stock
                        </Typography>
                      ) : history.location.state.isFromDetailChantuer ===
                        true ? null : (
                      <Grid
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            marginLeft: '10px',
                            color: "#0079C2",
                            fontSize: '38px',
                            marginTop: "16px",
                            marginBottom: "0",
                            width: '100%',
                          }}
                        >
                          {besoinChantier} unité(s)
                        </Typography>


                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            marginLeft: '10px',
                            color: "#0079C2",
                            fontSize: '22px',
                            marginTop: "9px",
                            marginBottom: "40px",
                            width: '100%'
                          }}
                        >
                          nécessaire(s) pour votre chantier
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            //width: "540px",
                            marginBottom: "56px",
                            width: '100%'
                          }}
                        >
                          Ces données sont uniquement fournies à titre indicatif
                          et estimatif et peuvent être différentes selon les
                          caractéristiques, les opérateurs et les conditions de
                          chantiers. Pour plus de renseignements, contactez notre
                          service technique ou consultez nos fiches techniques.
                        </Typography>
                      </Grid>
                    )}
                    {caracteristiques &&
                      <>
                        <Grid
                          sx={{ width: '100%', marginTop: '16px', height:'auto' }}
                        >
                          <Typography sx={{ textTransform: 'uppercase' }}>caractéristiques</Typography>
                        </Grid>
                        <TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          multiline
                          defaultValue={caracteristiques}
                          disabled
                          sx={{
                            width: '100%',
                            '& .Mui-disabled': {
                              color: 'black !important',
                              '-webkit-text-fill-color': 'black !important',
                            },
                          }}
                        />
                        {caracLink && 
                          <Grid 
                            dangerouslySetInnerHTML={{__html:"<a"+caracLink}} 
                            sx={{ 
                              fontSize: {mobile: '', desktop: '20px'}, 
                              marginTop: {mobile: '8px', desktop: '20px'},
                              marginBottom: {mobile: '0px', desktop: '20px'},
                              '& a': {
                                color: 'rgb(22, 121, 194)', 
                                textDecoration: 'none',
                                fontWeight: 'bold',
                              }
                            }}
                          />
                        }
                      </>}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            {/*/!* BOTTOM CONTAINER *!/*/}
            <Grid
              container
              style={classes.bottomContainer}
              sx={{
                padding: { mobile: '24px', desktop: '0' },
                margin: '35px 0',
                maxWidth: '950px',
                width: '100%'
                // padding: {mobile: '24px', desktop: '0'},
              }}
            >
              <Grid style={{ display: "flex" }}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontSize: '25px',
                  }}
                >
                  Documentation
                </Typography>
              </Grid>

              <Grid
                container
                mobile={12}
                sx={{
                  // marginBottom: "5%",
                }}
              >
                {product.externalLink ? (
                  product.externalLink.map((item) => {
                    return item.multimedias !== null ? (
                      <Grid
                        container
                        sx={{
                          margin: '24px 0 0 0',
                          padding: '16px',
                          borderRadius: '5px',
                          justifyContent: 'space-between',
                          boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                          display: 'flex',
                          flexDirection: { mobile: 'column', desktop: 'row' }
                        }}
                      >
                        <Typography
                          desktop={9}
                          sx={{
                            textAlign: 'start',
                            fontFamily: 'Montserrat',
                            fontSize: '15px',
                            fontWeight: 700
                          }}>
                          {item.category}
                        </Typography>
                        <Grid

                          desktop={3}
                          sx={{
                            alignItem: 'center',
                            fontFamily: "Montserrat",
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '8px'
                          }}
                        >
                          {item.multimedias.map((element) => {
                            if (
                              element.languageid === "FR" ||
                              element.languageid === "FR-FR"
                            ) {
                              return (
                                <Link
                                  target="_blank"
                                  href={element.url}
                                  sx={{ display: 'flex', padding: '8px', border: '1px solid #0079C2', borderRadius: '20px', margin: '2px' }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    style={{ marginRight: "15px" }}
                                  />
                                  <Typography
                                    style={classes.PdfLink}
                                  >
                                    {element.description}
                                  </Typography>
                                </Link>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Grid>
                      </Grid>
                    ) : null;
                  })
                ) : (
                  <Typography>
                    Pas de documentation disponible pour ce produit
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DetailProduct;
