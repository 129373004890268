import React, { useState } from "react";
import { Dialog, DialogContent, Grid, Button, Typography } from "@mui/material";
import ContactInformationStyle from "./ContactInformation.style";
import defaultContact from "./../../images/svg/DefaultPhotoContact.svg";
import photoContactTechnique from "./../../images/svg/photoContactTechnique.svg";
import mailIcon from "./../../images/svg/iconMail.png";
import PopUpMailContact from "./../../components/PopUpMailContact/PopUpMailContact";
import configFile from "./../../configFile.json";

const ContactInformation = (props) => {
  const { contact, myDto, location, surface, coating, defaultLocation } = props;

  //IMPORT STYLE
  const classes = ContactInformationStyle();

  const [openMailPopUp, setOpenMailPopUp] = useState(false);
  const [isTechnique, setIstechnique] = useState(false);

  //CLOSE POP UP
  const handleClose = () => {
    setOpenMailPopUp(false);
    setIstechnique(false);
  };

  const openPopUp = (isTechnique) => {
    setOpenMailPopUp(true);
    if (isTechnique) {
      return setIstechnique(true);
    }
  };

  const parseToTelHref = (telString) => {
    return 'tel:+33' + telString.substring(1).replace(/ /g, "")
  }

  return (
    <Grid
      sx={{
        maxWidth: "418px",
        display: 'flex',
        width: { mobile: '100%', desktop: '418px' },
        backgroundColor: '#F6F6F6',
        margin: { mobile: '0 0 56px 0', desktop: '24px 0 95px 15px' },
        padding: { mobile: '0', desktop: '"35px 25px 0 25px' },
      }}
    >
      <h1 className={classes.Title}>Besoin d'aide ?</h1>
      <p className={classes.FirstP}>
        Votre référent commercial et nos équipes techniques sont là pour vous
        accompagner.
      </p>
      <p className={classes.BoldP}>Une question commerciale ?</p>
      <>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { mobile: 'column', desktop: 'row' },
          }}
        >
          <Grid>
            {contact.photo ? (
              <img
                src={"data:image/png;base64," + contact.photo}
                className={classes.detailPhoto}
              />
            ) : (
              <img src={defaultContact} className={classes.detailPhoto} />
            )}
          </Grid>
          <Grid
            sx={{
              marginLeft: "22px",
              fontSize: "15px",
            }}
          >
            <p className={classes.FirstParRightGrid}>
              {contact.firstName + " " + contact.lastName}
            </p>
            <p className={classes.SecondParRightGrid}>Référent commercial</p>
            <p className={classes.ThirdParRightGrid}> {contact.region}</p>
            <p className={classes.FourthParRightGrid}>
              <a className={classes.LinkHref} href={parseToTelHref(contact.phoneNumber)}>
                {contact.phoneNumber}
              </a>
            </p>
            <p className={classes.fifthParRightGrid}>
              <a className={classes.LinkHref}
                href={"mailto:" + contact.mail + "?subject=MAPEI MonChantier - Assistance Commerciale"}>
                {contact.mail}
              </a>
            </p>
          </Grid>
        </Grid>
        <p className={classes.ItalicFirstPar}>
          Contactez votre interlocuteur commercial spécialiste de votre métier
          et de votre marché
        </p>
        <Button
          variant="contained"
          color={"primary"}
          sx={{
            borderRadius: "25px",
            fontFamily: "Montserrat",
            fontSize: "19px",
            fontWeight: "bold",
            padding: "8px 22px",
            height: '40px',
            margin: { mobile: '16px auto 0 0 ', desktop: '0 0 0 16px' },
            display: "flex",
            width: 'fit-content',
          }}
          onClick={() => {
            openPopUp();
          }}
          aria-label="Bouton contact 1"
        >
          <img src={mailIcon} className={classes.MailIcon} />
          <Typography
            style={{ fontFamily: "Montserrat", marginLeft: '10px' }}
          >
            Contactez-moi
          </Typography>
        </Button>
        <p className={classes.BoldP}>Une question technique ?</p>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { mobile: 'column', desktop: 'row' },
          }}
        >
          <Grid>
            <img src={photoContactTechnique} className={classes.detailPhoto} />
          </Grid>
          <Grid
            sx={{
              marginLeft: "22px",
              fontSize: "15px",
            }}
          >
            <p className={classes.FirstParSecondGrid}>Service technique</p>
            <p className={classes.SecondParSecondGrid}>
              <a className={classes.LinkHref}
                href={parseToTelHref('0561354859')}>
                05 61 35 48 59
              </a>
            </p>
            <p className={classes.ThirdParSecondGrid}>
              <a className={classes.LinkHref}
                href="mailto:assistance.technique@mapei.fr?subject=MAPEI MonChantier - Assistance Technique">
                assistance.technique@mapei.fr
              </a>
            </p>
          </Grid>
        </Grid>
        <p className={classes.ItalicFirstPar}>
          A votre écoute, l’assistance technique vous conseille et vous
          accompagne dans la réalisation de tous vos chantiers
        </p>

        <Button
          variant="contained"
          color={"primary"}
          sx={{
            alignSelf: 'center',
            borderRadius: "25px",
            fontFamily: "Montserrat",
            fontSize: "19px",
            fontWeight: "bold",
            padding: "8px 22px",
            height: '40px',
            margin: { mobile: '16px auto 0 0 ', desktop: '0 0 0 16px' },
            display: "flex",
            width: 'fit-content',
          }}
          onClick={() => {
            openPopUp(true);
          }}
          aria-label="Bouton contact 2"
        >
          <img src={mailIcon} className={classes.MailIcon} />
          <Typography
            style={{ fontFamily: "Montserrat", marginLeft: '10px' }}
          >
            Contactez-moi
          </Typography>
        </Button>
      </>
      {/* POP UP MESSAGE RIGHT HERE */}
      <Dialog open={openMailPopUp} onClose={handleClose} maxWidth="xl">
        <DialogContent
          style={{
            padding: '20px 24px',
            maxWidth: "760px",
          }}
        >
          <PopUpMailContact
            close={handleClose}
            destinataire={
              isTechnique === true ? configFile.mailTechnique : contact.mail
            }
            isTechnique={isTechnique}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ContactInformation;
