import React from "react";
import { Grid, Typography } from "@mui/material";
import styles from "./styles";
import cookiesList from "../../images/cookiesList.png";
import cookiesClarity from "../../images/clarity-cookies.png"

const CookiesPage = () => {
  return (
    <Grid style={styles.handler}>
      <Typography style={styles.mainTitle}>
        Politique D’Utilisation Des Cookies
      </Typography>
      <Typography style={styles.text}>
        Conformément au règlement européen 2016/679 (« RGPD ») et à la
        législation française relative à la protection des données personnelles,
        MAPEI France S.A. (également «Responsable de traitement»), en tant que
        responsable du traitement, fournit les informations suivantes concernant
        les cookies installés sur le www.monchantieravecmapei.fr et ses
        sous-domaines ainsi que l’application mobile « Mon Chantier avec Mapei »
        (« Le Site»).
      </Typography>
      <Typography style={styles.title}>Que sont les Cookies? </Typography>
      <Typography style={styles.text}>
        Les cookies sont des fichiers textes utilisés pour l'authentification
        automatique, le suivi de session et le stockage d'informations sur des
        utilisateurs spécifiques accédant à un site web ou, dans le cas d'achats
        via internet, sur le contenu de leurs «paniers». Dans le détail, les
        cookies sont des chaînes de texte de petite taille envoyées depuis un
        serveur vers le navigateur de l'utilisateur, puis renvoyées au serveur
        (sans modification) chaque fois que l'utilisateur accède à la même
        partie du même domaine Web. Les cookies peuvent être stockés de manière
        permanente sur l'ordinateur ou l'appareil mobile de l'utilisateur ou
        avoir une durée variable (cookies persistants), mais peuvent également
        disparaître lorsque l'utilisateur ferme le navigateur (cookies de
        session). Les cookies peuvent être installés par le site web visité
        (cookies de première partie) ou peuvent être installés par d'autres
        sites web (cookies tiers).
      </Typography>
      <Typography style={styles.title}>Type de cookies et objectifs</Typography>
      <Typography style={styles.text}>
        Les cookies améliorent l'expérience de navigation: <br />
        - en stockant les paramètres, de sorte que vous n'ayez pas à les
        réinsérer chaque fois que vous visitez une nouvelle page <br />- en
        mesurant votre utilisation du Site pour vous assurer qu'il répond à vos
        besoins. Nos cookies ne sont pas utilisés pour vous identifier
        personnellement. Leur utilisation vise à améliorer le fonctionnement du
        Site pour vous. Vous pouvez les gérer et / ou les supprimer à votre
        discrétion. Plus précisément, nous collectons :
      </Typography>
      <Typography style={styles.title}>- Cookies techniques </Typography>
      <br />
      <Typography style={styles.text}>
        Les cookies techniques (pour lesquels le consentement de l'utilisateur
        n'est pas requis) aident l'utilisateur à naviguer sur le Site ou à
        fournir un service requis par celui-ci. Sans ces cookies, certaines
        opérations pourraient ne pas être possibles ou seraient plus complexes à
        réaliser et / ou moins sécurisées pour l'utilisateur.
      </Typography>
      <Typography style={styles.title}>a. Cookies de navigation</Typography>
      <br />
      <Typography style={styles.text}>
        Nous utilisons des cookies de navigation qui permettent à l'utilisateur
        de naviguer et de profiter du Site de manière sûre et efficace
      </Typography>
      <Typography style={styles.title}>b. Cookies fonctionnels</Typography>
      <br />
      <Typography style={styles.text}>
        Nous utilisons des cookies de fonctionnels pour les activités
        strictement nécessaires au bon fonctionnement du Site.
      </Typography>
      <Typography style={styles.title}>c. Analytiques et statistiques</Typography>
      <br />
      <Typography style={styles.text}>
        Les cookies analytiques et statistiques collectent des informations de manière anonyme nous permettant de connaitre l'utilisation et les performances de notre site, d’établir des statistiques, des volumes de fréquentation et d’utilisation des divers éléments de notre site (contenus visités, parcours) nous permettant d’améliorer l’intérêt et l’ergonomie de notre site.
      </Typography>
      <br />
      <Typography style={styles.title}>
        Pourquoi employons-nous des cookies ?
      </Typography>
      <Typography style={styles.text}>
        Les cookies sont utilisés pour améliorer l'expérience de navigation des
        utilisateurs : ils enregistrent vos préférences afin de ne pas vous
        obliger à les saisir chaque fois que visitez une nouvelle page.
      </Typography>
      <Typography style={styles.text}>
        Nos cookies ne sont pas utilisés pour vous identifier personnellement.
        Leur utilisation est destinée à améliorer le fonctionnement du site pour
        vous : en fait, vous pouvez les gérer et/ou les supprimer à votre guise.
      </Typography>
      <Typography style={styles.title}>
        Comment employons-nous les cookies ?
      </Typography>
      <Typography style={styles.text}>
        On trouvera ci-dessous les informations nécessaires pour identifier les
        cookies installés, leurs fonctions et la durée de leur conservation sur
        votre dispositif.
      </Typography>
      <br /> <br />
      <Grid
        sx={{
          display: { mobile: 'none', desktop: 'block' }
        }}
      >
        <img src={cookiesList} alt="tableau cookie" /> <br />
      </Grid>
      <Grid
        sx={{
          display: { mobile: 'none', desktop: 'block' }
        }}
      >
        <img src={cookiesClarity} alt="tableau cookie-clarity" /> <br />
      </Grid>
      <a
        style={styles.link}
        href="https://docs.microsoft.com/fr-fr/azure/active-directory-b2c/session-behavior?pivots=b2c-user-flow"
      >
        information session de navigateur
      </a>
      <br />
      <Typography style={styles.title}>
        Comment modifier les paramètres des cookies?
      </Typography>
      <Typography style={styles.text}>
        En accédant à n'importe quelle page du Site, une bannière de
        notification apparaît. En cliquant sur "J'accepte", l'utilisateur
        consent à l'utilisation des cookies. Le consentement est enregistré avec
        un "cookie technique" et peut être révoqué par l'utilisateur à tout
        moment. Pour obtenir plus d'informations sur les cookies, y compris sur
        la façon de désactiver tout ou partie des cookies tiers, l'utilisateur
        peut cliquer sur les liens dans la colonne "Informations
        complémentaires" du tableau ci-dessus. L'utilisateur peut s'opposer au
        stockage de cookies sur le disque dur en configurant le navigateur de
        manière à désactiver les cookies. Voici les façons dont les principaux
        navigateurs permettent aux utilisateurs de le faire :
      </Typography>
      <Typography style={styles.text}>
        - Internet Explorer
        <a
          style={styles.cookiesLink}
          href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
          alt="lien cookies internet explorer"
        >
          https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
        </a>
      </Typography>
      <Typography style={styles.text}>
        - Chrome
        <a
          style={styles.cookiesLink}
          href="https://support.google.com/accounts/answer/61416?hl=fr"
          alt="lien cookies chrome"
        >
          https://support.google.com/accounts/answer/61416?hl=fr
        </a>
      </Typography>
      <Typography style={styles.text}>
        - Firefox
        <a
          style={styles.cookiesLink}
          href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences"
          alt="lien cookies firefox"
        >
          https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences
        </a>
      </Typography>
      <Typography style={styles.text}>
        - Opera
        <a
          style={styles.cookiesLink}
          href="https://help.opera.com/en/latest/web-preferences/"
          alt="lien cookies Opera"
        >
          https://help.opera.com/en/latest/web-preferences/
        </a>
      </Typography>
      <Typography style={styles.text}>
        - Safari
        <a
          style={styles.cookiesLink}
          href="https://support.apple.com/kb/PH19214?locale=fr-FR"
          alt="lien cookies Safari"
        >
          https://support.apple.com/kb/PH19214?locale=fr-FR
        </a>
      </Typography>
      <Typography style={styles.text}>
        Cependant, après de telles opérations, certaines fonctions des pages web
        peuvent ne pas être correctement exécutées.
      </Typography>
      <Typography style={styles.title}>
        Communication et diffusion de données
      </Typography>
      <Typography style={styles.text}>
        Les données collectées à l'aide de cookies peuvent être traitées par des
        salariés et des collaborateurs de MAPEI France S.A. ou des sociétés du
        Groupe MAPEI en tant que «personnes chargées du traitement»
        (c'est-à-dire des personnes qui, placées sous l'autorité directe du
        Responsable du traitement ou du Sous-Traitant, sont autorisées à traiter
        les données à caractère personnel, conformément aux articles 4-10 et 29
        du RGPD) ainsi que par les Sous-Traitants. En outre, les données peuvent
        également être traitées par des sociétés du Groupe MAPEI ou par des
        sociétés de confiance fournissant, pour le compte de MAPEI ou des
        sociétés du Groupe MAPEI, en tant que sous-traitant. Leur liste est
        constamment mise à jour et est disponible, sur demande, en s’adressant
        par écrit à l'adresse indiquée ci-dessous ou en envoyant un e-mail à
        privacy@mapei.fr. <br />
        Les données personnelles ne seront pas diffusées.
      </Typography>
      <Typography style={styles.title}>Droits de l’utilisateur</Typography>
      <Typography style={styles.text}>
        En ce qui concerne le traitement de vos données, l'utilisateur peut
        exercer les droits énoncés aux articles 15 à 22 du RGPD (résumés à la
        fin de cette politique). Pour exercer ces droits, l'utilisateur peut
        contacter le responsable du traitement des données en s’adressant par
        écrit à l'adresse indiquée ci-dessous ou en envoyant un e-mail à
        privacy@mapei.fr
      </Typography>
      <Typography style={styles.title}>Responsable de traitement</Typography>{" "}
      <Typography style={styles.text}>
        Le responsable de traitement est: <br />
        Mapei France <br />
        S.A. 29 Avenue Léon Jouhaux <br /> CS40021 <br />
        31141 ST ALBAN CEDEX <br /> France
      </Typography>
      <Typography style={styles.text}>
        Comme mentionné ci-dessus, les données peuvent également être traitées
        par des sociétés de confiance qui fournissent, au nom de MAPEI France
        S.A. ou des sociétés du Groupe MAPEI, des services techniques et
        organisationnels. Ces sociétés sont des collaborateurs directs de MAPEI
        France S.A. ou des sociétés du Groupe MAPEI et sont nommées
        sous-traitant. Leur liste est constamment mise à jour est disponible,
        sur demande, en s’adressant à l'adresse mentionnée ci-dessus ou en
        envoyant un e-mail à
        <a href="mailto:privacy@mapei.fr">privacy@mapei.fr</a> .
      </Typography>
      <Typography style={styles.title}>
        DROITS DE LA PERSONNE CONCERNEE
      </Typography>
      <Typography style={styles.text}>
        Articles 15 à 22 du règlement européen 2016/679 <br />
        Conformément aux articles 15 à 22 du règlement européen 2016/679, la
        personne concernée a le droit d'obtenir du responsable du traitement des
        données la rectification, l'intégration ou l'effacement («droit à
        l’oubli») des données personnelles; le droit d'obtenir la limitation du
        traitement et le droit à la portabilité des données, le droit de
        s'opposer au traitement des données personnelles, y compris le
        profilage, et le droit de déposer une réclamation auprès de l'autorité
        de protection des données.
      </Typography>
    </Grid>
  );
};

export default CookiesPage;
