import { FormControl, Grid, IconButton, Button, Typography, Box } from "@mui/material";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AddChantier } from "../../API/APIRequest/Chantiers/Chantiers";
import Close from "./../../images/svg/Close.svg";
import notifySuccess from "./../../Shared/notifySuccess";
import PopUpStyle from "./PopUpAddChantier.style";

const PopUpAddChantier = (props) => {
  const classes = PopUpStyle();
  const {
    close,
    idUser,
    produitsList,
    fromDetail,
  } = props;
  let history = useHistory();
  //FORM MANAGEMENT
  const { handleSubmit } = useForm();
  const [isClicked, setIsClicked] = useState(false);
  const [listOfProd, setListOfProd] = useState(produitsList);

  const date = Moment(new Date()).format("YYYY-MM-DD");
  const [minEndDate, setMinEndDate] = useState(date);
  const [EndDate, setEndDate] = useState("");
  const [productToSend, setProductToSend] = useState([]);

  const initProduct = () => {
    if (
      history.location.pathname != "/chantiers" &&
      history.location.pathname != "/chantiers/"
    ) {
      if (fromDetail === true) {
        const List = [
          {
            idProduct: produitsList.id,
            productName: produitsList.name,
            productImage: produitsList.url || ' ',
          },
        ];
        return setProductToSend(List);
      } else {
        let fullProduct = [];

        for (let i = 0; i < listOfProd.length; i++) {
          listOfProd[i].products.forEach((element) => {
            fullProduct.push({
              idProduct: element.id,
              productName: element.name,
              productImage: element.url,
            });
          });
        }
        fullProduct.join();
        return setProductToSend(fullProduct);
      }
    } else {
      return null;
    }
  };

  const onSubmit = () => {
    const params = {
      name: chantier.chantierObject.name,
      clientName: chantier.chantierObject.clientName,
      location: chantier.chantierObject.location,
      beginDate:
        chantier.chantierObject.beginDate === ""
          ? Moment(minEndDate).format("DD-MM-YYYY")
          : chantier.chantierObject.beginDate,
      endDate:
        EndDate === ""
          ? Moment(minEndDate).format("DD-MM-YYYY")
          : Moment(EndDate).format("DD-MM-YYYY"),
      description: chantier.chantierObject.description,
      idUser: idUser,
      chantierProduct: productToSend ? productToSend : [],
    };

    setIsClicked(true);
    AddChantier(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess("Chantier créé avec succès");
            close();
          } else {
            console.log(response.data.error.message);
          }
        },
        onError: (error) => console.log("error"),
      },
      params
    );
  };

  //ACCOUNT INFO

  const [chantier, setChantier] = useState((chantier) => ({
    chantierObject: {
      name: "",
      clientName: "",
      beginDate: "",
      endDate: "",
      location: "",
      description: "",
    },
  }));

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val =
      nam === "beginDate"
        ? Moment(event.target.value).format("DD-MM-YYYY")
        : event.target.value;

    if (nam === "beginDate") {
      setMinEndDate(event.target.value);
    } else if (nam === "endDate") {
      setMinEndDate(event.target.value);
      setEndDate(event.target.value);
    }
    setChantier((chantier) => ({
      chantierObject: {
        ...chantier.chantierObject,
        [nam]: val,
      },
    }));
  };

  useEffect(() => {
    initProduct();
  }, []);

  const autoGrow = (e) => {
    e.target.style.height = "5px";
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  return (
    <FormControl
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        position: 'relative'
      }}
    >
      <Grid
        container
        style={{
          width: "auto",
          fontWeight: "bold",
          color: "black",
        }}
      >
        {/* TITLE */}
        <Grid container >
          <Typography
            sx={{
              maxWidth: "510px",
              width: '70%',
              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "bold",
              textWrap: 'wrap',
            }}
          >Nouveau chantier</Typography>
        </Grid>

        {/* BODY */}
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', desktop: 'row' }
          }}
        >
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>
                Nom&nbsp;<span className={classes.colorRed}>*</span>
              </label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="149"
                name="name"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
                required
              ></input>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              item
              className={classes.label}
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Descriptif</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <textarea
                onInput={(e) => autoGrow(e)}
                maxlength="255"
                className={classes.textareaInput}
                name="description"
                onChange={myChangeHandler}
              ></textarea>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Nom du client</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="149"
                name="clientName"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
              ></input>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>
                Dates&nbsp;<span className={classes.colorRed}>*</span>
              </label>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: { mobile: 'column', desktop: 'row' }
              }}
            >
              <Grid
                container
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span
                  className={classes.dateSpan}
                >du</span>
                <input
                  className={classes.dateInput}
                  type="date"
                  name="beginDate"
                  defaultValue={date}
                  onChange={myChangeHandler}
                  required
                ></input>
              </Grid>
              <Grid
                container
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span
                  className={classes.dateSpan}
                >au</span>
                <input
                  className={classes.dateInput}
                  type="date"
                  name="endDate"
                  defaultValue={date}
                  onChange={myChangeHandler}
                  required
                  value={EndDate === "" ? minEndDate : EndDate}
                ></input>
              </Grid>
            </Grid>
          </Grid>



          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Lieu</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="149"
                name="location"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
              ></input>
            </Grid>
          </Grid>
        </Grid>

        {/* BUTTONS */}
        <Grid
          container
          sx={{
            flexDirection: { mobile: 'column', desktop: "row" },
            justifyContent: { mobile: 'center', desktop: "center" },
            marginTop: "2vh",
          }}
        >
          <Button
            rounded
            color={'inherit'}
            variant={'contained'}
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginBottom: { mobile: '16px', desktop: 0 },
            }}
            onClick={() => close()}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Annuler
            </Typography>
          </Button>

          <Button
            rounded
            disabled={isClicked}
            variant={'contained'}
            type="submit"
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginLeft: { mobile: 0, desktop: '32px' }
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Enregistrer
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton onClick={() => close()}
        >
          <img
            className={classes.icon}
            alt="close button"
            src={Close}
          />
        </IconButton>
      </Box>
    </FormControl>
  );
};

export default PopUpAddChantier;
