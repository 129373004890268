import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifySuccess = (mess) => {
  toast.success(mess, {
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    autoClose: 3000,
  });
};

export default notifySuccess;
