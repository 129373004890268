const PopUpStyle = {
  mainContainer: {
    width: "100%",
    position: "static",
    fontWeight: "bold",
    color: "black",
  },
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "2%",
    marginBottom: "2%",
    marginRight: "13%",
  },
  buttonsContainer: {
    marginBottom: "4%",
    marginTop: "4%",
    justifyContent: "flex-end",
  },

  gridTitle: {
    marginLeft: "3%",
  },

  icon: {
    width: "120%",
  },

  inputName: {
    width: "100%",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    height: "52px",
    paddingLeft: "18px",
    fontSize: "15px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },

  Savebutton: {
    boxShadow: "none",
    width: "190px",
    borderRadius: "25px",
    marginLeft: "26px",
    backgroundColor: "#0079C2",
    color: "white",
  },
  SavebuttonDisabled: {
    boxShadow: "none",
    width: "190px",
    borderRadius: "25px",
    marginLeft: "26px",
    backgroundColor: "#e0e0e0",
    color: "white",
  },
  CancelButton: {
    boxShadow: "none",
    width: "190px",
    borderRadius: "25px",
    marginLeft: "260px",
  },
  titlePopUp: {
    fontFamily: "Montserrat",
  },
  nameFieldGrid: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  labelName: {
    marginLeft: "51px",
    fontFamily: "Montserrat",
  },
  gridUrl: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  labelUrl: {
    marginLeft: "51px",
    fontFamily: "Montserrat",
  },
  cancelLarge: {
    width: "250px",
    borderRadius: "25px",
    marginLeft: "260px",
  },
  deleteLarge: {
    width: "250px",
    borderRadius: "25px",
    marginLeft: "26px",
    backgroundColor: "#0079C2",
    color: "white",
  },
};

export default PopUpStyle;
