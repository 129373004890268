import banner from "./productlist_banner.png";

const favoritesSolutionStyle = {
  selectedTabs: {
    color: "#0079C2",
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "25px",
    marginRight: "2vw",
    marginLeft: "2vw",
    fontFamily: "Montserrat",
    cursor: "pointer",
  },
  unSelectedTabs: {
    color: "#7E7E7E",
    fontSize: "25px",
    marginRight: "2vw",
    marginLeft: "2vw",
    fontFamily: "Montserrat",
    cursor: "pointer",
  },
  mainGrid: {
    height: "255px",
    backgroundSize: "cover",
    backgroundImage: `url(${banner})`,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-evenly",
  },
  gridTitleContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "38px",
    color: "white",
    fontFamily: "Montserrat",
    alignItems: "center",
  },
  typoSize: {
    fontSize: "38px",
    fontFamily: "Montserrat",
  },
  typoSizesmall: {
    fontSize: "26px",
    fontFamily: "Montserrat",
  },
  typoBold: {
    fontFamily: "Montserrat",

    fontWeight: "bold",
    fontSize: "38px",
    marginLeft: "10px",
  },
  typoBoldsmall: {
    fontFamily: "Montserrat",

    fontWeight: "bold",
    fontSize: "26px",
    marginLeft: "10px",
  },
  // gridSearch: {
  //   fontFamily: "Montserrat",
  //   justifyContent: "center",
  //   display: "flex",
  // },
  form: {
    fontFamily: "Montserrat",
    fontWeight: '900',
    width: "540px",
    height: "50px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  formField: {
    width: "100%",
    marginLeft: "0px",
    padding: "0px 15px",
  },
  gridBody: {
    fontFamily: "Montserrat",

    display: "flex",
    flexDirection: "column",
  },
  gridTypoContainer: {
    fontFamily: "Montserrat",

    display: "flex",
    justifyContent: "center",
    marginTop: "5vh",
    marginBottom: "5vh",
  },
  gridContainerCard: {
    maxWidth: "1400px",
    width: "95%",
    justifyContent: "center",
    flexDirection: "column",
    alignSelf: "center",
    minHeight: "500px",
  },
  gridMainCardContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
  },
  typoCard: {
    fontFamily: "Montserrat",

    fontWeight: "bold",
    marginRight: "10px",
  },
  gridFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "60px",
    marginBottom: "40px",
  },
  boldTypo: {
    fontWeight: "bold",
    marginRight: "10px",
    fontFamily: "Montserrat",
  },
  gridFavHolder: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginBottom: "60px",
  },
  emptyTypo: {
    fontFamily: "Montserrat",

    display: "flex",
    justifyContent: "center",
    height: "600px",
    marginTop: "200px",
  },
  buttonAjouterChantier: {
    borderRadius: "0px",
    backgroundColor: "#0079C2",
    color: "white",
    fontSize: "15px",
    fontFamily: "Montserrat",
  },
};

export default favoritesSolutionStyle;
