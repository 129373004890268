import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormControl,
  Slide
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import YouTube from 'react-youtube'
import {
  CheckFavoriteSolution,
  Coating,
  ConstructionType,
  DeleteFavoriteSolution,
  getContactByDepartment,
  Location,
  PostFavoriteSolution,
  SolutionByCategories,
  Surface,
} from '../../API/APIRequest/Categories'
import { SelectAPI } from '../../API/APIRequest/SelectAPI/selectAPI'
import ContactInformation from '../../components/ContactInformation'
import DropDownChantierInRecherche from '../../components/DropDownChantierInRecherche'
import PopUpAddChantier from '../../components/PopUpAddChantier'
import PopUpFavoriteSolution from '../../components/PopUpFavoriteSolution'
import BannerPose from '../../images/poseCarlage.jpg'
import BannerReparation from "../../images/svg/homeBg.svg";
import notifyError from './../../Shared/notifyError'
import notifySuccess from './../../Shared/notifySuccess'
import { GetFormattedDepartment } from './RechercheSolution.logic'
import RechercheStyle from './RechercheSolution.style'
import SolutionStep from './SolutionStep'
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ProductFavoritesStyles from "../FavoritesProducts/style";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import EmptySearchList from "../../components/EmptySearchList";

const RechercheSolution = ({ appInsights }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 1,
    },
  }
  const classes = RechercheStyle()
  let history = useHistory()

  const isView = true

  const [location, setLocation] = useState([])
  const [coating, setCoating] = useState([])
  const [surface, setSurface] = useState([])
  const [contact, setContact] = useState()
  const [selectedLocation, setSelectedLocation] = useState([])

  const [selectedCoating, setSelectedCoating] = useState([])
  const [selectedSurface, setSelectedSurface] = useState([])
  // Construction type is for manage origine from home page: 'Pose de ...' or 'Réparation'
  const [selectedConstructionType, setSelectedConstructionType] = useState()
  const ConstructionTypeMap = {
    'pose': '1fe9685a-fe00-4dd7-b138-0d8d55067424',
    'réparation': 'b7d24b84-7a98-40ea-941e-648726dd6147',
    '1fe9685a-fe00-4dd7-b138-0d8d55067424': 'pose',
    'b7d24b84-7a98-40ea-941e-648726dd6147': 'réparation',
  }
  const [metter, setMetter] = useState(
    history.location.state.solution
      ? history.location.state.solution.surfaceChantier
      : null
  )

  const [locationIsAvailable, setLocationIsAvailable] = useState(true)
  const [surfaceIsAvailable, setSurfaceIsAvailable] = useState(true)

  const [constructionList, setConstructionList] = useState([])
  const [isEmpty, setIsEmpty] = useState(true)
  const [DTO, setDTO] = useState([])

  const [favoritePopUpOpen, setFavoritePopUpOpen] = useState(false)

  const [isFavorite, setIsFavorite] = useState(false)
  const [favoriteSolution, setFavoriteSolution] = useState()
  //LOADER
  const [isLoading, setIsLoading] = useState(false)

  // DEFAULT VALUEs WHEN YOU GO BACK FROM DETAIL PRODUCT
  const [defaultCoating, setDefaultCoating] = useState(
    coating.filter((x) => x.id === DTO.idCoating),
  )
  const [defaultLocation, setDefaultLocation] = useState(
    location.filter((x) => x.id === DTO.idLocation),
  )
  const [defaultSupport, setDefaultSupport] = useState(
    surface.filter((x) => x.id === DTO.idSurface),
  )

  const [isSurfaceFiltered, setIsSurfaceFiltered] = useState(false)
  const [filterSelected, setFilterSelected] = useState('neuf')
  const ReverseFilterMap = {
    'neuf': 'renovation',
    'renovation': 'neuf',
    '': ''
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmpty(true)
    if (e.target.value === 'neuf' || e.target.value === 'renovation') {
      setIsSurfaceFiltered(!isSurfaceFiltered);
      setFilterSelected(e.target.value);
      setLocation([]);
      setCoating([]);
      setSurface([]);
      setMetter('');
      setDefaultLocation([])
      setDefaultCoating([])
      setDefaultSupport([])
    }
    getSelectAvailable(1, selectedConstructionType);
  }

  //OPEN FAVORITE SOLUTION POPUP
  const handleOpen = () => {
    if (isFavorite) {
      const deleteFavoriteSolutionInfo = {
        idUser: history.location.state.idUser,
        idCoating: favoriteSolution.idCoating,
        idConstructionType: favoriteSolution.idConstructionType,
        idLocation: favoriteSolution.idLocation,
        idSurface: favoriteSolution.idSurface,
        number: parseInt(favoriteSolution.number),
      }
      DeleteFavoriteSolution(
        {
          onSuccess: (response) => {
            if (response.data.isSuccess === true) {
              notifySuccess('Le favori a été supprimé')
              setIsFavorite(false)
            } else {
              notifyError('Une erreur est survenue')
            }
          },
          onError: (error) => {
            notifyError('Une erreur est survenue')
          },
        },
        deleteFavoriteSolutionInfo,
      )
    } else {
      setFavoritePopUpOpen(!favoritePopUpOpen)
    }
  }

  //MANAGE CHANTIER POP UP
  const [openPopUpChantier, setOpenPopUpChantier] = useState(false)
  const [chantierIsClicked, setChantierIsClicked] = useState(false)
  const handleCloseChantier = () => {
    setOpenPopUpChantier(false)
  }

  //CLOSE FAVORITE SOLUTION POPUP
  const handleClose = () => {
    setFavoritePopUpOpen(!favoritePopUpOpen)
  }


  //SELECT API
  const getSelectAvailable = useCallback((etapeNumber, value) => {
    var params = {
      filterNumber: etapeNumber,
      //idConstructionType: history.location.state.idConstructionType,
      idConstructionType: selectedConstructionType,
      idCoating: selectedCoating.id ? selectedCoating.id : '',
      idLocation: selectedLocation.id ? selectedLocation.id : '',
      idSurface: selectedSurface.id ? selectedSurface.id : '',
    }

    // HERE : 'value' is the ID of item selected ! 
    if (etapeNumber === 1) {
      params['idConstructionType'] = value
      delete params['idCoating']
      delete params['idLocation']
      delete params['idSurface']
    }
    if (etapeNumber === 2) {
      params['idCoating'] = value
      delete params['idLocation']
      delete params['idSurface']
    }
    if (etapeNumber === 3) {
      params['idLocation'] = value
      delete params['idSurface']
    }
    if (etapeNumber === 4) {
      params['idSurface'] = value
    }
    SelectAPI(params, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          if (etapeNumber === 1) {
          }
          //const filteredList = response.data.data.filter((item) => item.filter !== ReverseFilterMap[filterSelected]);
          //const filteredList = response.data.data.filter((item) => item.name !== 'Un carrelage');
          switch (etapeNumber) {
            case 1:
              setCoating(response.data.data)
              setSurface([])
              setLocation([])
              setMetter(null)
              break
            case 2:
              setLocation(response.data.data)
              setSurface([])
              break
            case 3:
              const filteredSupportList = response.data.data.filter((item) => item.filter !== ReverseFilterMap[filterSelected]);
              //const filteredSupportList = response.data.data.filter((item) => !item.name.includes('a'));
              setSurface(filteredSupportList)
              break
            default:
              break
          }
        } else {
        }
      },
      onError: (error) => notifyError(error),
    })

    return true
  })

  const select = (event, props) => {
    setIsLoading(false)
    if (props === 'Coating') {
      setIsEmpty(true)
      setSelectedCoating(event.target.value)
      setLocationIsAvailable(false)
      setSurfaceIsAvailable(true)

      //Reset defaultValue on select
      setDefaultCoating([])
      setDefaultLocation([])
      setDefaultSupport([])

      setSelectedLocation(0)
      setSelectedSurface(0)

      getSelectAvailable(2, event.target.value.id)
    }
    if (props === 'Location') {
      setSelectedLocation(event.target.value)
      setSurfaceIsAvailable(false)

      getSelectAvailable(3, event.target.value.id)
      setSelectedSurface(0)
      setIsEmpty(true)
    }

    if (props === 'Metter') {
      setMetter(event.target.value)
    } else if (props === 'Surface') {
      setIsEmpty(true)

      setSelectedSurface(event.target.value)
      getSelectAvailable(4, event.target.value.id)
    }
  }

  useEffect(() => {
    //GET USER DEPARTMENT AND FORMAT IT
    const department = GetFormattedDepartment()

    getContactByDepartment(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setContact(response.data.data)
          } else {
            return notifyError(response.data.error.message)
          }
        },
        onError: (error) => notifyError(error),
      },
      department,
    )

    ConstructionType({
      onSuccess: (response) => {
        if (response.data.isSuccess == true) {
          return setConstructionList(response.data.data)
        } else {
          notifyError(response.data.error.message)
        }
      },
      onError: (error) => notifyError(error),
    })


    setSelectedConstructionType(history.location.state.idConstructionType)


    //RETRIEVE ID SOLUTION FROM DETAIL PRODUCT
    const isFromDetail = history.location.state.solution ? true : false

    if (isFromDetail) {
      setIsLoading(true)
      //Manage PreSelected selects on each categories

      const favoriteKey = {
        idCoating: history.location.state.solution.idCoating,
        idSurface: history.location.state.solution.idSurface,
        idLocation: history.location.state.solution.idLocation,
        idConstructionType:
          history.location.state.solution.idConstructionType,
        number: history.location.state.solution.surfaceChantier,
        idUser: history.location.state.idUser,
      }

      CheckFavoriteSolution(
        {
          onSuccess: (response) => {
            if (response.data.isSuccess === true) {
              response.data.data !== null
                ? setIsFavorite(true)
                : setIsFavorite(false)
              setFavoriteSolution(response.data.data)
            } else {
              return notifyError(response.data.error.message)
            }
          },
          onError: (error) => notifyError(error),
        },
        favoriteKey,
      )

      const params = {
        idCoating: history.location.state.solution.idCoating,
        idSurface: history.location.state.solution.idSurface,
        idLocation: history.location.state.solution.idLocation,
        idConstructionType:
          history.location.state.solution.idConstructionType,
        surfaceChantier:
          history.location.state.solution.surfaceChantier,
        idUser: history.location.state.idUser,
      }

      SolutionByCategories(
        {
          onSuccess: (response) => {
            if (response.data.isSuccess === true) {
              setMetter(
                history.location.state.solution.surfaceChantier,
              )
              setDTO(response.data.data)
              setIsEmpty(false)
              setIsLoading(false)
            } else {
              return notifyError(response.data.error.message)
            }
          },
          onError: (error) => {
            notifyError(error)
            setIsEmpty(true)
          },
        },
        params,
      )

    }

    Location({
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setSelectedLocation(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idLocation,
            ),
          )
          setDefaultLocation(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idLocation,
            ),
          )
          return setLocation(response.data.data)
        } else {
          notifyError(response.data.error.message)
        }
      },
      onError: (error) => notifyError(error),
    })

    Coating({
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setSelectedCoating(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idCoating,
            ),
          )

          setDefaultCoating(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idCoating,
            ),
          )

          return setCoating(response.data.data)
        } else {
          notifyError(response.data.error.message)
        }
      },

      onError: (error) => notifyError(error),
    })

    Surface({
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setSelectedSurface(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idSurface,
            ),
          )
          setDefaultSupport(
            response.data.data.filter(
              (x) =>
                x.id ===
                history.location.state.solution.idSurface,
            ),
          )
          return setSurface(response.data.data)
        } else {
          notifyError(response.data.error.message)
        }
      },
      onError: (error) => notifyError(error),
    })

    getSelectAvailable(1, history.location.state.idConstructionType)
  }, [history.location.state.idConstructionType, history.location.state.idUser, history.location.state.solution])

  const { handleSubmit } = useForm()

  const onSubmit = () => {
    // navigate to the response area
    document.getElementById('searchBtn').scrollIntoView()

    setIsLoading(true)
    setDTO([])
    setIsEmpty(true)

    const idCoating =
      typeof selectedCoating.id === 'undefined'
        ? defaultCoating[0].id
        : selectedCoating.id
    const idSurface =
      typeof selectedSurface.id === 'undefined'
        ? defaultSupport[0].id
        : selectedSurface.id
    const idLocation =
      typeof selectedLocation.id === 'undefined'
        ? defaultLocation[0].id
        : selectedLocation.id

    //copie colle au dessus prendre clef name et mettre dans phrase

    localStorage.setItem(
      'solution',
      JSON.stringify(
        'Je pose du ' +
        selectedCoating.name.toLowerCase() +
        ' dans ' +
        selectedLocation.name.toLowerCase() +
        ' sur ' +
        selectedSurface.name.toLowerCase() +
        ' sur une surface de ' +
        metter +
        ' m².',
      ),
    )

    const favoriteKey = {
      idCoating: idCoating,
      idSurface: idSurface,
      idLocation: idLocation,
      idConstructionType: selectedConstructionType,
      number: parseInt(metter),
      idUser: history.location.state.idUser,
    }



    CheckFavoriteSolution(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            response.data.data !== null
              ? setIsFavorite(true)
              : setIsFavorite(false)
            setFavoriteSolution(response.data.data)
          } else {
            return notifyError(response.data.error.message)
          }
        },
        onError: (error) => notifyError(error),
      },
      favoriteKey,
    )

    const params = {
      idCoating: idCoating,
      idSurface: idSurface,
      idLocation: idLocation,
      idConstructionType: selectedConstructionType,
      surfaceChantier: parseInt(metter),
      idUser: history.location.state.idUser,
    }

    //PERMIT TO DISPLAY SOLUTION
    SolutionByCategories(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setIsEmpty(false)
            setIsLoading(false)
            return setDTO(response.data.data)
          } else setIsLoading(false)

          return notifyError(response.data.error.message)
        },
        onError: (error) => notifyError(error),
      },
      params,
    )
  }

  const readyForSubmit = () => {
    if (isLoading === true) return true
    if (
      selectedCoating === [] ||
      selectedCoating === 0 ||
      selectedSurface === [] ||
      selectedSurface === 0 ||
      selectedLocation === [] ||
      selectedLocation === 0 ||
      metter === null ||
      metter === ''
    ) {
      return true
    } else return false
  }
  const type = () => {
    const data = constructionList
    const constructionId = selectedConstructionType

    const resultat = data.filter((item) => item.id === constructionId)
    const readyForUpdate = resultat[0] ? resultat[0].name : undefined

    if (readyForUpdate === 'Pose' && readyForUpdate !== undefined) {
      return 'pose'
    } else if (readyForUpdate === 'Réparation') {
      return 'répare'
    } else return null
  }

  const type2 = () => {
    const data = constructionList
    const constructionId = selectedConstructionType

    const resultat = data.filter((item) => item.id === constructionId)
    const readyForUpdate = resultat[0] ? resultat[0].name : undefined

    if (readyForUpdate === 'Pose' && readyForUpdate !== undefined) {
      return 'dans'
    } else if (readyForUpdate === 'Réparation') {
      return 'sur'
    } else return null
  }
  const type3 = () => {
    const data = constructionList
    const constructionId = selectedConstructionType

    const resultat = data.filter((item) => item.id === constructionId)
    const readyForUpdate = resultat[0] ? resultat[0].name : undefined

    if (readyForUpdate === 'Pose' && readyForUpdate !== undefined) {
      return 'sur'
    } else if (readyForUpdate === 'Réparation') {
      return 'par'
    } else return null
  }

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }
  const opts = {
    height: '231',
    width: '356',
    playerVars: {
      // "https://developers.google.com/youtube/player_parameters",
      autoplay: 0,
    },
  }

  const banner = selectedConstructionType === '1fe9685a-fe00-4dd7-b138-0d8d55067424' ? BannerPose : BannerReparation

  return (
    <Grid >
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          backgroundImage: `url(${banner})`,
          backgroundPosition: 'bottom left',
          backgroundRepeat: "no-repeat",
          backgroundSize: 'cover',
          height: '100%',
          paddingBottom: '32px',
          zIndex: '-1',
          backgroundColor: "rgba(0,0,0,1)",
        }}
      >
        <Grid >
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}>
            <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
              <Breadcrumbs variantColor={'white'} />
              <BackButton />
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 'auto',
              fontSize: "38px",
              color: "white",
              fontFamily: "Montserrat",
            }}
          >
            <Grid
              marginX={"auto"}
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  marginTop: { mobile: '40px', desktop: '80px' }
                }}
              >
                <Grid container>
                  <Typography style={{
                    fontSize: "36px",
                    fontFamily: "Montserrat",
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}>
                    Dites-nous en plus sur
                    &nbsp;<strong>votre chantier :</strong>
                  </Typography>
                </Grid>
              </Grid>

              {/*NEW RADIO BUTTONS MODULE*/}
              {ConstructionTypeMap[selectedConstructionType] === 'pose' &&
                <Grid
                  sx={{
                    padding: '8px 24px',
                    height: '70px',
                    borderRadius: "10px",
                    backgroundColor: "#0079c2",
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Montserrat',
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Grid>
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '13px',
                      }}
                    >
                      Veuillez préciser votre type de chantier
                    </Typography>
                  </Grid>
                  <RadioGroup
                    aria-labelledby="radio-group-chantier-type"
                    //defaultValue={ConstructionTypeMap[history.location.state.idConstructionType]}
                    value={filterSelected}
                    name="radio-group-chantier"
                    onChange={handleRadioChange}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <FormControlLabel
                      value="neuf"
                      label="Neuf"
                      labelPlacement="start"
                      control={
                        <Radio
                          color="default"
                          variant="outlined"
                          name="radio-buttons"
                          slotProps={{ input: { 'aria-label': 'neuf' } }}
                          label="neuf"
                          sx={{
                            '&, &.Mui-checked': {
                              color: 'white',
                            },
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      value="renovation"
                      label="Rénovation"
                      labelPlacement="start"
                      control={
                        <Radio
                          color="default"
                          variant="outlined"
                          value="renovation"
                          name="radio-buttons"
                          slotProps={{ input: { 'aria-label': 'rénovation' } }}
                          label="rénovation"
                          sx={{
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            fontWeight: 700,
                            '&, &.Mui-checked': {
                              color: 'white',
                            },
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                </Grid>
              }

              {/*OLD FORM MODULE*/}
              <Grid
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  color: 'black',
                  fontSize: '16px',
                  fontFamily: 'Montserrat',
                  maxWidth: "1300px",
                  width: '100%',
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingTop: "17px",
                  paddingBottom: "16px",
                  marginTop: '30px',
                  minHeight: '140px',
                  padding: '20px',
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                }}
              >
                {(coating[0] + location[0] + surface[0]) ? (
                  <>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "baseline",
                        flexDirection: { mobile: 'column', desktop: 'row' },
                      }}
                    >
                      Je {type()}
                      <Grid container mobile={12} desktop={3}
                        sx={{ margin: { mobile: '12px 0 16px', desktop: 0 } }}
                      >
                        <Tooltip
                          title={
                            selectedCoating.name
                              ? selectedCoating.name
                              : 'Ajouter un élément'
                          }
                          placement='top'>
                          <Select
                            rounded
                            required
                            onChange={(e) =>
                              select(e, 'Coating')
                            }
                            defaultValue={0}
                            sx={{
                              width: { mobile: '100%', desktop: '90%' },
                              fontSize: '16px',
                              color: '#0079C2 !important',
                              fontFamily: 'Montserrat',
                              borderColor: '#0079C2 !important',
                              fontWeight: 'bold',
                              borderRadius: '50px !important',
                              padding: '8px 22px',
                              maxHeight: '40px',
                              '& .MuiMenuItem-root': {
                                whiteSpace: 'normal',
                                textWrap: 'balance',
                              },
                            }}
                          >
                            {defaultCoating[0] &&
                              <MenuItem value={0} disabled>
                                {defaultCoating[0].name}
                              </MenuItem>
                            }
                            {coating.map((item, index) => (
                              <MenuItem
                                key={item.name + index}
                                value={item}
                                sx={{
                                  textWrap: 'balance',
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Tooltip>
                      </Grid>
                      {type2()}
                      <Grid container mobile={12} desktop={3}
                        sx={{ margin: { mobile: '12px 0 16px', desktop: 0 } }}
                      >
                        <Tooltip
                          title={
                            selectedLocation.name
                              ? selectedLocation.name
                              : 'Ajouter un élément'
                          }
                          placement='top'>
                          <Select
                            defaultValue={0}
                            disabled={locationIsAvailable}
                            required
                            onChange={(e) =>
                              select(e, 'Location')
                            }
                            sx={{
                              width: { mobile: '100%', desktop: '90%' },
                              fontSize: '16px',
                              color: '#0079C2 !important',
                              fontFamily: 'Montserrat',
                              borderColor: '#0079C2 !important',
                              fontWeight: 'bold',
                              borderRadius: '50px !important',
                              padding: '8px 22px',
                              maxHeight: '40px',
                            }}
                          >
                            {defaultLocation[0] &&
                              <MenuItem value={0} disabled>
                                {defaultLocation[0].name}
                              </MenuItem>
                            }
                            {location.map((item, index) => (
                              <MenuItem
                                key={item.name + index}
                                value={item}
                                sx={{
                                  textWrap: 'balance',
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Tooltip>
                      </Grid>
                      {type3()}
                      <Grid container mobile={12} desktop={3}
                        sx={{ margin: { mobile: '12px 0 16px', desktop: 0 } }}
                      >
                        <Tooltip
                          title={
                            selectedSurface.name
                              ? selectedSurface.name
                              : 'Ajouter un élément'
                          }
                          placement='top'>
                          <Select
                            defaultValue={0}
                            disabled={surfaceIsAvailable}
                            required
                            onChange={(e) =>
                              select(e, 'Surface')
                            }
                            sx={{
                              width: { mobile: '100% !important', desktop: '90%' },
                              fontSize: '16px',
                              color: '#0079C2 !important',
                              fontFamily: 'Montserrat',
                              borderColor: '#0079C2 !important',
                              fontWeight: 'bold',
                              borderRadius: '50px !important',
                              padding: '8px 22px',
                              maxHeight: '40px',
                            }}
                          >
                            {defaultSupport[0] &&
                              <MenuItem value={0} disabled>
                                {defaultSupport[0].name}
                              </MenuItem>
                            }
                            {surface.map((item, index) => (
                              <MenuItem
                                key={item.name + index}
                                value={item}
                                sx={{
                                  textWrap: 'balance',
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.SurfaceLabel}
                      sx={{
                        display: "flex",
                        flexDirection: { mobile: 'column', desktop: 'row' },
                        justifyContent: "flex-end",
                        alignItems: { mobile: 'start', desktop: "center" },
                        marginRight: { mobile: 0, desktop: '2vw' },
                        marginTop: { mobile: 0, desktop: '20px' },
                      }}
                    >
                      sur une surface de
                      <TextField
                        id='standard-basic'
                        required
                        //defaultValue={defaultMetter}
                        value={metter}
                        type='number'
                        //size='medium'
                        sx={{
                          borderRadius: '50px',
                          width: { mobile: '100%', desktop: '10vw' },
                          maxWidth: { mobile: '100%', desktop: '200px' },
                          margin: { mobile: '12px 0 16px', desktop: '0 0 0 30px' },
                          '-moz-appearance': 'textfield',
                          '-webkit-appearance': 'none',
                        }}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /[^\w\s]/gi,
                          '',
                        ))
                        }
                        label='m²'
                        onChange={(e) => select(e, 'Metter')}
                        variant='outlined'
                        InputLabelProps={{ required: false, shrink: true, color: 'primary' }}
                        InputProps={{
                          style: {
                            fontSize: '1vw', borderRadius: '50px',
                            maxHeight: '40px',
                          },
                          min: '0',
                        }}
                      />
                      <Button
                        id={'searchBtn'}
                        disabled={readyForSubmit()}
                        type='submit'
                        variant='contained'
                        sx={{
                          width: '216px',
                          marginLeft: { mobile: 0, desktop: '30px' },
                          maxHeight: '40px',
                          borderRadius: '25px',
                          fontFamily: 'Montserrat',
                          alignSelf: 'center',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          padding: '8px 22px',
                          height: '40px',
                          display: 'flex',
                        }}
                        className={
                          !readyForSubmit()
                            ? classes.button
                            : classes.buttonDisabled
                        }>
                        C'est parti !
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    container
                    sx={{
                      margin: 'auto',
                      minHeight: '100px',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <CircularProgress />
                  </Grid>
                )}
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </form>
      {/* BOTTOM CONTAINER */}
      {isEmpty ? (
        <Grid
          container
          mobile={12}
          sx={{
            height: "36vh",
            fontSize: "large",
            color: "DimGrey",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {isLoading
              ?
              <CircularProgress />
              :
              <Grid
                sx={{ display: { mobile: 'none', desktop: 'flex' } }}
              >
                <EmptySearchList
                  variantColor={'blue'}
                  firstLine={'Dites-nous en plus sur'}
                  secondLine={'votre chantier'}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              padding: '0 24px',
            }}
          >
            <Grid
              sx={{
                margin: "auto",
                marginTop: "50px",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  textAlign: "center",
                  fontFamily: 'Montserrat',
                  textTransform: 'uppercase',
                  fontSize: "32px",
                }}
              >
                <strong>La solution MAPEI</strong> : Ce dont vous avez besoin
              </Typography>
            </Grid>
          </Grid>

          <Grid
            desktop={3}
            sx={{
              display: "flex",
              flexDirection: { mobile: 'column', desktop: "row" },
              alignItems: { mobile: 'start', desktop: "end" },
              justifyContent: { mobile: 'start', desktop: "start" },
              margin: { mobile: '24px 60px', desktop: '24px' },
              //width: '100%',
              paddingLeft: { mobile: 'auto', desktop: "58%" },
            }}
          >
            {DTO.steps &&
              <DropDownChantierInRecherche
                produitsList={DTO.steps}
                setOpenPopUp={setOpenPopUpChantier}
              />
            }
            <Button
              variant="contained"
              color={"primary"}
              sx={{
                borderRadius: "25px",
                fontFamily: "Montserrat",
                fontSize: "19px",
                fontWeight: "bold",
                padding: "8px 22px",
                height: '40px',
                marginLeft: { mobile: 0, desktop: '16px' },
                marginTop: { mobile: '16px', desktop: 0 },
                display: "flex",
                width: 'fit-content',
              }}
              onClick={() => handleOpen()}
              aria-label="Bouton ajouter retirer favoris"
            >
              {isFavorite ? <StarIcon style={{ color: '#ffcc01' }} color={'inherit'} /> : <StarBorderIcon />}
              <Typography
                style={{ fontFamily: "Montserrat", marginLeft: '10px' }}
              >
                Favoris
              </Typography>
            </Button>
          </Grid>
          <Grid
            container
            mobile={12}
            style={{
              display: 'flex',
              //justifyContent: 'center',
              maxWidth: '1300px',
              width: '100%',
              margin: 'auto',
            }}>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                //justifyContent: 'center',
                flexFlow: 'wrap',
                //padding: '0 24px',
              }}
              container
              mobile={12}
              desktop={7.5}
            >
              <Grid
                sx={{
                  //placeContent: "center",
                  display: "flex",
                  marginBottom: "3vh",
                  flexWrap: "wrap",
                  justifyContent: 'center'
                }}
              >
                {/* RETURN SOLUTION */}
                {DTO['steps']
                  ? DTO['steps'].map((item, index) => {
                    return (
                      <SolutionStep
                        idUser={
                          history.location
                            .state.idUser
                        }
                        isView={isView}
                        addStep={false}
                        key={item + index}
                        myDto={DTO}
                        step={item}
                        index={index}
                        //idConstructionType={
                        //  history.location
                        //    .state
                        //    .idConstructionType
                        //}
                        idConstructionType={
                          selectedConstructionType
                        }
                        surface={metter}
                      />
                    )
                  })
                  : null}
              </Grid>
            </Grid>
            {contact &&
              <Grid
                container
                mobile={12}
                desktop={4}
                sx={{
                  height: 'fit-content',
                  width: { mobile: '100%', desktop: 'fit-content' },
                  marginBottom: { mobile: '0', desktop: '36px' },
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: '#F6F6F6',
                    padding: '0 24px',
                    height: 'fit-content',
                    width: { mobile: '100%', desktop: 'fit-content' },
                  }}
                >
                  <ContactInformation
                    contact={contact}
                    location={selectedLocation}
                    coating={selectedCoating}
                    surface={selectedSurface}
                    defaultLocation={defaultLocation[0]}
                  />
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      )}



      {/* BLOC MEDIA IF EXIST */}
      {!!DTO?.videos?.length
        &&
        (
          <Grid
            style={{
              backgroundColor: '#2E2E2E',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: { desktop: 'row', mobile: 'column' },
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '50px',
              }}>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '36px',
                }}>
                Pour vous aider : Nos
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  marginLeft: '0.5%',
                  fontFamily: 'Montserrat',
                  fontSize: '38px',
                }}>
                tutos vidéos
              </Typography>
            </Grid>

            {/* <Grid
              style={
                DTO.videos.length > 2
                  ? {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '50px',

                  }
                  : {
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '50px',

                  }
              }
          > */}
            <Grid sx={{
              width: '100%',
              '& .carousel-container': {
                margin: 'auto',
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              },
              '& ul': {
                display: 'flex',
                margin: 'auto',
                width: '100%',
                flexDirection: { mobile: "column", desktop: 'row' },
                alignItems: 'center',
                '& li': {
                  width: 'auto !important',
                  maxWidth: '600px',
                  margin: 'auto'
                }
              }
            }}>
              <Carousel
                centerMode={true}
                swipeable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                autoPlaySpeed={1000}
                containerClass='carousel-container'
                itemClass='carousselItem'
                dotListClass='custom-dot-list-style'
                removeArrowOnDeviceType={["mobile"]}
              >
                {DTO.videos.map((item, index) => {
                  return (
                    <Paper
                      key={item.id + index}
                      elevation={3}
                      sx={{
                        //width: 'fit-content',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        height: '341',
                        justifyContent: 'center',
                        margin: '50px auto 50px auto',
                        paddingTop: '20px'
                      }}>
                      <Grid
                        item
                        //xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <YouTube
                          videoId={item.url.split('/')[3]}
                          opts={opts}
                          onReady={onReady}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: '110px',
                          marginTop: '0px',
                          padding: '20px',
                          maxWidth: 'fit-content',
                          minHeight: '110px',
                          maxHeight: '110px',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Typography
                          style={{
                            fontSize: '18px',
                            color: '#0079C2',
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                          }}>
                          {item.name}
                        </Typography>
                      </Grid>
                    </Paper>
                  )
                })}
              </Carousel>
            </Grid>
          </Grid>
        )}



      <Dialog
        open={favoritePopUpOpen}
        maxWidth='xl'
        onClose={handleClose}>
        <DialogContent
          style={{
            paddingTop: '5px',
            paddingRight: '5px',
            paddingLeft: '22px',
            maxWidth: '760px',
          }}>
          <PopUpFavoriteSolution
            constructionTypeId={DTO.idConstructionType}
            coatingId={DTO.idCoating}
            locationId={DTO.idLocation}
            surfaceId={DTO.idSurface}
            metter={metter}
            handleClose={handleClose}
            idUser={history.location.state.idUser}
            idSolution={DTO.id}
            PostFavoriteSolution={PostFavoriteSolution}
            notifyError={notifyError}
            notifySuccess={notifySuccess}
            setIsFavorite={setIsFavorite}
            setFavoriteSolution={setFavoriteSolution}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPopUpChantier}
        maxWidth='xl'
        onClose={handleCloseChantier}>
        <DialogContent
          style={{
            paddingTop: '5px',
            paddingRight: '5px',
            paddingLeft: '30px',
            maxWidth: '760px',
          }}>
          <PopUpAddChantier
            produitsList={DTO.steps}
            close={handleCloseChantier}
            idUser={history.location.state.idUser}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default RechercheSolution
