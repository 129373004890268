import { Button, Grid, IconButton, FormControl, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetIncrementNumber } from "./../../API/APIRequest/Categories";
import Close from "./../../images/svg/Close.svg";
import PopUpStyle from "./PopUpFavoriteSolution.style";

const PopUpFavoriteSolution = (props) => {
  const classes = PopUpStyle();
  const {
    handleClose,
    idUser,
    idSolution,
    PostFavoriteSolution,
    notifyError,
    notifySuccess,
    setIsFavorite,
    setFavoriteSolution,
    constructionTypeId,
    coatingId,
    locationId,
    surfaceId,
    metter,
  } = props;

  const handleInputChange = (e) => {
    setInputValue(e);
  };

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  const [defaultInputvalue, setDefaultInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [firstLoad, setfirstLoad] = useState(true);
  const method = () => {
    const favoriteSolution = {
      idUser: idUser,
      idSolution: idSolution,
      name: inputValue !== "" ? inputValue : defaultInputvalue,
      idConstructionType: constructionTypeId,
      idCoating: coatingId,
      idLocation: locationId,
      idSurface: surfaceId,
      number: parseInt(metter),
    };
    PostFavoriteSolution(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess("La solution a été ajoutée à vos favoris");
            setIsFavorite(true);
            setFavoriteSolution(response.data.data);
          } else {
            notifyError("Une erreur est survenue");
          }
        },
        onError: (error) => {
          notifyError("Une erreur est survenue");
        },
      },
      favoriteSolution
    );
    handleClose();
  };

  useEffect(() => {
    const params = {
      name: "Ma Solution " + date + " - ",
      idUser: idUser,
    };
    GetIncrementNumber(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            return setDefaultInputValue(params.name + " " + response.data.data);
            // return setInputValue(defaultInputvalue);
          } else {
            return notifyError(response.data.error.message);
          }
        },
        onError: (error) => {
          notifyError(error);
        },
      },
      params
    );
  }, []);

  return (
    <FormControl
      sx={{
        position: 'relative'
      }}
    >
      <Grid
        container
        style={{
          width: "auto",
          fontWeight: "bold",
          color: "black",
        }}
      >
        {/* TITLE */}
        <Grid container >
          <Typography
            sx={{
              maxWidth: "510px",
              width: '70%',
              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "bold",
              textWrap: 'wrap',
            }}
          >Nouvelle solution favorite</Typography>
        </Grid>
        {/* BODY */}
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', desktop: 'row' }
          }}
        >
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>
                Nom
              </label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="50"
                name="name"
                type="text"
                className={classes.textInput}
                onChange={(e) => handleInputChange(e.target.value)}
                required
                defaultValue={defaultInputvalue}
              ></input>
            </Grid>
          </Grid>
        </Grid>


        {/* BUTTONS */}
        <Grid
          container
          sx={{
            flexDirection: { mobile: 'column', desktop: "row" },
            justifyContent: { mobile: 'center', desktop: "center" },
            marginTop: "2vh",
          }}
        >
          <Button
            rounded
            color={'inherit'}
            variant={'contained'}
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginBottom: { mobile: '16px', desktop: 0 },
            }}
            onClick={() => handleClose()}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Annuler
            </Typography>
          </Button>

          <Button
            rounded
            variant={'contained'}
            type="submit"
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginRight: { mobile: 0, desktop: '32px' },
              marginLeft: { mobile: 0, desktop: '32px' }
            }}
            onClick={() => method()}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Enregistrer
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton onClick={() => handleClose()}
        >
          <img
            className={classes.icon}
            alt="close button"
            src={Close}
          />
        </IconButton>
      </Box>
    </FormControl >
  );
};

export default PopUpFavoriteSolution;
