import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  getFavoritesByUserId,
  removeFavorite,
  searchFavoriteByName,
  solutionFromFavorite,
  updateFavoriteName,
} from "../../API/APIRequest/Favorites/Favorites";
import FavoritesSolutionsCard from "../../components/FavoritesSolutionsCard";
import notifyError from "../../Shared/notifyError";
import notifySuccess from "../../Shared/notifySuccess";
import "./style.css";
import favoritesSolutionStyle from "./style";
import PopUpDelFavorite from "./popUpDelFavorite";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  tabsClasses,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";

const FavoritesSolutions = ({ appInsights }) => {
  const [isLoading, setIsLoading] = useState("true");

  //UserManagement
  const [idUser, setIdUser] = useState();

  //Favorites
  let FavFullList = [];
  const [fullList, setFullList] = useState([]);
  const [favorites, setFilteredFavorites] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  //TabsManagement
  const [selectedTabs, setSelectedTabs] = useState("Pose");
  const changeTabs = (value, fullList) => {
    setSelectedTabs(value);
    let filtered;
    if (value === "Pose") {
      const filtered = fullList.filter(
        (f) => f.constructionTypeName === "Pose"
      );

      return setFilteredFavorites(filtered);
    } else {
      const filtered = fullList.filter(
        (f) => f.constructionTypeName === "Réparation"
      );
      return setFilteredFavorites(filtered);
    }
  };

  //FilterManagement
  const [filter, setFilter] = React.useState(1);
  const handleChange = (event) => {
    let favoritesToOrder = favorites;
    setFilter(event.target.value);
    if (event.target.value === 1) {
      //FilterByName
      favoritesToOrder.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    } else {
      // FilterByDate
      favoritesToOrder.sort(function (a, b) {
        var dateA = new Date(a.creationDate);
        var dateB = new Date(b.creationDate);
        return dateB - dateA;
      });
      return setFilteredFavorites(favoritesToOrder);
    }
  };

  //EditManagement
  const editFavs = (name, data) => {
    setIsLoading(true);

    let params = data;
    params.name = name;

    //EDIT FAVORTE NAME
    updateFavoriteName(params, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          notifySuccess("Favori modifié avec succès");
          setIsLoading(false);

          getFavoritesByUserId(idUser, {
            onSuccess: (response) => {
              if (response.data.isSuccess === true) {
                setFullList(response.data.data);
                setFilteredFavorites(response.data.data);
                changeTabs(selectedTabs, response.data.data);
                response.data.data.length < 1
                  ? setIsEmpty(true)
                  : setIsEmpty(false);
                setIsLoading(false);
              }
            },
            onError: (error) => {
              notifyError(error);
              setIsLoading(false);
            },
          });
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  };

  //SearchManagement
  const [favToSearch, setFavToSearch] = useState("");
  const handleChangeValue = (e) => {
    if (e.key === "Enter") {
      searchFavByName();
    } else return setFavToSearch(e.target.value);
  };
  const searchFavByName = () => {
    setIsLoading(true);
    if (favToSearch !== "") {
      searchFavoriteByName(idUser, favToSearch, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredFavorites(response.data.data);
            changeTabs(selectedTabs, response.data.data);
            setIsLoading(false);
            response.data.data.length < 1
              ? setIsEmpty(true)
              : setIsEmpty(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    } else {
      getFavoritesByUserId(idUser, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredFavorites(response.data.data);
            changeTabs(selectedTabs, response.data.data);
            response.data.data.length < 1
              ? setIsEmpty(true)
              : setIsEmpty(false);

            setIsLoading(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    }
  };

  //RemoveFavorite
  const removeFav = (data) => {
    setIsLoading(true);

    const params = {
      idUser: idUser,
      idSurface: data.idSurface,
      idConstructionType: data.idConstructionType,
      idLocation: data.idLocation,
      idCoating: data.idCoating,
      number: data.number,
    };
    removeFavorite(params, {
      onSuccess: (response) => {
        notifySuccess("Favori supprimé avec succès");
        if (response.data.isSuccess === true) {
          getFavoritesByUserId(idUser, {
            onSuccess: (response) => {
              if (response.data.isSuccess === true) {
                setFullList(response.data.data);
                setFilteredFavorites(response.data.data);
                changeTabs(selectedTabs, response.data.data);
                response.data.data.length < 1
                  ? setIsEmpty(true)
                  : setIsEmpty(false);

                setIsLoading(false);
              }
            },
            onError: (error) => {
              notifyError(error);
              setIsLoading(false);
            },
          });
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  };

  //IsFavoritesolutionAvailable and Navigation
  let history = useHistory();

  const favIsAvailable = (params) => {
    setIsLoading(true);
    const data = {
      idSurface: params.idSurface,
      idConstructionType: params.idConstructionType,
      idLocation: params.idLocation,
      idCoating: params.idCoating,
    };
    solutionFromFavorite(data, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          //Do Navigation
          history.push({
            pathname: "/recherche",
            state: {
              idUser: idUser,
              solution: {
                surfaceChantier: params.number,
                ...response.data.data,
              },
              idConstructionType: response.data.data.idConstructionType,
              isFromDetail: true,
            },
          });
        } else {
          //Do Error PopUp
          setSelectedFav(params);
          setOpenDelMenu(true);
          setIsLoading(false);
        }
      },
      onError: (error) => notifyError(error),
    });
  };

  //Favorite unaivalable popup
  const [openDelMenu, setOpenDelMenu] = useState(false);
  const [selectedFav, setSelectedFav] = useState({});
  const handleClose = () => {
    setOpenDelMenu(false);
  };

  //UseEffect global
  useEffect(() => {
    setIsLoading(true);
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);

    getFavoritesByUserId(accountInfoParsed.account.accountIdentifier, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setFullList(response.data.data);
          setFilteredFavorites(response.data.data);
          changeTabs("Pose", response.data.data);
          response.data.data.length < 1 ? setIsEmpty(true) : setIsEmpty(false);

          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
    //Get Data by profile info

    return () => {
    };
  }, []);

  // const handleChangeTest = (event: React.SyntheticEvent, newValue) => {
  //   setValue(newValue);
  // };
  const handleChangeTab = (value) => {
    changeTabs(value, fullList)
  }


  return (
    <>
      <ToastContainer />

      <Grid sx={{}}>
        <Grid style={favoritesSolutionStyle.mainGrid}>
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}>
            <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
              <Breadcrumbs variantColor={'white'} />
              <BackButton />
            </Box>
          </Grid>


          <Grid
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: 'inherit'
            }}
          >
            <Grid
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
              }}
              marginX={"auto"}
            >
              <Grid
                container
                justifyContent={'center'}
                alignItems="center"
                direction={'row'}
              >
                <Typography style={{
                  fontFamily: 'Montserrat',
                  fontSize: '38px',
                  fontWeight: 400,
                  marginRight: '8px',
                  letterSpacing: '0.356px',
                  textAlign: 'center'
                }}>Mes solutions favorites</Typography>
              </Grid>

            </Grid>

            <Grid
              marginX={"auto"}
              style={{
                display: 'flex',
                padding: "0 15px",
                margin: '0 20px',
                justifyContent: 'center',
              }}>
              <Grid style={favoritesSolutionStyle.form}>
                <TextField
                  variant={"standard"}
                  InputProps={{ disableUnderline: true }}
                  onKeyUp={(e) => handleChangeValue(e)}
                  style={favoritesSolutionStyle.formField}
                  id="chantier-shearch-field"
                  placeholder="Filtrer ..."
                />
                <SearchIcon
                  htmlColor={"#1D97EF"}
                  onClick={() => searchFavByName()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid
            style={{
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid style={favoritesSolutionStyle.gridBody}>
            <Grid style={favoritesSolutionStyle.gridTypoContainer}>
              <Tabs
                value={selectedTabs}
                // onChange={handleChangeTest}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons={"auto"}
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  margin: "0 16px",
                  [`& *`]: {
                    fontFamily: "Montserrat !important",
                    fontSize: "15px",
                    fontWeight: 900,
                  },
                  [`& [aria-selected="false"]`]: {
                    opacity: 0.5,
                  },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.2 },
                  },
                }}
              >
                <Tab label="Pose de revêtements" value="Pose"
                  onClick={() => changeTabs("Pose", fullList)} />
                <Tab label="Réparation" value="Réparation"
                  onClick={() => changeTabs("Réparation", fullList)} />
              </Tabs>
            </Grid>
            {isEmpty || favorites.length < 1 ? (
              <Typography style={favoritesSolutionStyle.emptyTypo}>
                Aucun favori trouvé
              </Typography>
            ) : (
              <Grid style={favoritesSolutionStyle.gridContainerCard}>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: "end",
                    marginRight: '4%',
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  sx={{
                    marginTop: { mobile: '24px', desktop: 0 },
                  }}>
                  <Typography style={favoritesSolutionStyle.boldTypo}>
                    Trier par
                  </Typography>
                  <Select
                    variant={"standard"}
                    id="sort-select"
                    value={filter}
                    disableUnderline={true}
                    onChange={handleChange}
                    sx={{
                      borderRadius: '5px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                    MenuProps={{
                      sx: {
                        "&& .MuiMenuItem-root": {
                          color: '#6B6B6B',
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 500,
                        }
                      }
                    }}
                  >
                    <MenuItem value={0}>Dernière modification</MenuItem>
                    <MenuItem value={1}>Nom</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  sx={{
                    display: 'grid',
                    gap: 0,
                    gridTemplateColumns: { mobile: '1', desktop: 'repeat(3, 1fr)' },
                    justifyItems: 'center',
                    marginBottom: '48px',
                  }}
                >
                  {favorites
                    ? favorites.map((item, index) => {
                      return (
                        <FavoritesSolutionsCard
                          favIsAvailable={favIsAvailable}
                          removeFav={removeFav}
                          editFavs={editFavs}
                          data={item}
                          key={item.name + index}
                        />
                      );
                    })
                    : null}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Dialog open={openDelMenu} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <PopUpDelFavorite
            selectedFav={selectedFav}
            deleteFavs={removeFav}
            close={() => setOpenDelMenu(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FavoritesSolutions;
