import * as React from 'react';
import { Breadcrumbs, Chip, Stack, styled } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"
import { useHistory, useLocation } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";


declare type CustomBreadcrumbsProps = {
  variantColor?: 'white' | 'blue',
  productName?: string,
}

const CustomBreadcrumb = styled(Chip)(({ theme, variantColor, islastitem }) => {
  const backgroundColor = 'rgba(0, 0, 0, 0)';
  let primaryColor = islastitem ? theme.palette.primary.main : theme.palette.primary.light;
  let whiteColor = islastitem ? 'rgba(255, 255, 255, 0.87)' : `rgba(255, 255, 255, 0.60)`;
  return {
    backgroundColor,
    height: theme.spacing(3),
    '& *': {
      color: `${variantColor === 'white' ? whiteColor : primaryColor} !important`,
    },
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      lineHeight: '150%',
      fontFeatureSettings: "'clig' off, 'liga' off"
    }
  };
});
const CustomBreadcrumbsWrapper = styled(Breadcrumbs)(({ theme, variantColor }) => {
  let primaryColor = theme.palette.primary.light;
  let whiteColor = `rgba(255, 255, 255, 0.60)`;
  return {
    color: `${variantColor === 'white' ? whiteColor : primaryColor} !important`,
    '& .MuiBreadcrumbs-separator': {
      fontSize: '25px',
    }
  };
});

const customPathnames = {
  chantiers: 'Mes chantiers',
  recherche: 'Votre chantier',
  produits: 'Produits',
  contact: 'Contactez-nous',
  // 'solutions-fav': 'Mes solutions',
  // 'produits-fav': 'Mes produits et outils',
  'mes-solutions': 'Mes solutions',
  'mes-produits': 'Mes produits et outils',
  solutions: 'Solutions',
  'compte': 'Compte',
  'supprimer': 'Suppression de vos données'
};

export default function CustomBreadcrumbs({ variantColor = 'white', productName = '' }: CustomBreadcrumbsProps) {
  const history = useHistory();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const breadcrumbs = [
    <CustomBreadcrumb
      key={`first_ship`}
      variantColor={variantColor}
      component="a"
      label="Accueil"
      icon={<HomeIcon fontSize="small" />}
      onClick={() => history.push("/")}
    />,
  ];

  pathnames.map((pathname, index) => {
    if ((index + 1) === pathnames.length) {
      return (
        breadcrumbs.push(
          <CustomBreadcrumb
            key={index + `_ship`}
            islastitem={true}
            variantColor={variantColor}
            component="a"
            label={customPathnames[pathname] || productName}
            style={{ fontWeight: 700 }}
          />
        )
      );
    } else {
      return (
        breadcrumbs.push(
          <CustomBreadcrumb
            key={index + `_ship`}
            variantColor={variantColor}
            component="a"
            label={customPathnames[pathname] || productName}
            onClick={() => history.push(`/${pathnames.slice(0, index + 1).join('/')}`)}
          />
        )
      );
    }
  });

  return (
    <Stack spacing={2} direction="row" sx={{
      minWidth: 'fit-content',
      display: { xs: 'none', sm: 'flex' },
      position: "absolute",
      left: '8px',
      top: '8px',
      zIndex: 10,
      alignItems: 'center'
    }}>
      <CustomBreadcrumbsWrapper separator={<ChevronRight />} aria-label="breadcrumb" variantColor={variantColor}>
        {breadcrumbs}
      </CustomBreadcrumbsWrapper>
    </Stack>
  );
}
