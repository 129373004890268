import { Button, CardMedia, Grid, IconButton, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EastIcon from '@mui/icons-material/East';
import React from "react";
import { useHistory } from "react-router-dom";
import emptyItem from "../../images/svg/emptyItem.svg";

type ProductsProps = {
  listProduits: [],
  AddProduct: Function,
  product: {},
  index: Int,
  isDisabled: boolean,
  EditProduct: Function,
  RemoveProduct: Function,
  isView: Boolean,
  solution: {},
  idConstructionType: String,
  idUser: String,
};

const Products = ({
  listProduits,
  AddProduct,
  product,
  index,
  isDisabled,
  EditProduct,
  RemoveProduct,
  isView,
  solution,
  idUser,
}: ProductsProps) => {
  const useStyles = makeStyles({
    root: {
      width: "fit-content",
      overflow: "inherit",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: "25px",
      color: "#CECECE",
    },
    newProduct: {
      fontSize: "15px",
      color: "#CECECE",
      textAlign: "center",
    },

    pos: {
      marginBottom: 12,
    },
    stepTitle: {
      color: "#0079C2",
      fontSize: "25px",
    },
    button: {
      backgroundColor: "#0079C2",
      color: "white",
      display: "flex",
      borderRadius: "25px",
      boxShadow: "none",
    },
    media: {
      objectFit: "contain",
    },
  });
  const Color = {
    SelectedBlue: "#1D97EF",
    GrayBackground: "#F9F9F9",
    BlackBlue: "#0079c2",
    selectedRow: "#1D97EF",
  };
  const classes = useStyles();
  let history = useHistory();

  return (
    <Paper
      elevation={3}
      sx={{
        width: { mobile: '70vw', desktop: '180px' },
        padding: "10px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        margin: '16px 8px',
      }}
    >
      {!AddProduct && !isView ? (
        <Grid style={{ width: "inherit" }}>
          <IconButton
            style={{ marginLeft: "75%" }}
            onClick={() => RemoveProduct(index)}
          >
            <CancelIcon htmlColor="red" />
          </IconButton>{" "}
        </Grid>
      ) : null}
      {!AddProduct ? (
        <CardMedia
          className={classes.media}
          style={{
            width: "65%",
            maxHeight: "130px",
            maxWidth: "130px",
            minWidth: "160px",
            minHeight: "130px",
          }}
          component="img"
          image={product.url ? product.url : emptyItem}
        />
      ) : (
        <IconButton size="medium">
          <AddCircleIcon htmlColor={Color.BlackBlue} fontSize="large" />
        </IconButton>
      )}

      <Typography
        color="textSecondary"
        gutterBottom
        className={classes.newProduct}
      ></Typography>
      <p
        style={{
          color: "#0079C2",
          fontSize: "15px",
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "5px",
          minHeight: "15px",
          maxHeight: "15px",
        }}
      >
        {product.name}
      </p>

      {product.isDeleted ? (
        <p
          style={{
            fontSize: "1.6rem",
            marginTop: "0.1rem",
            textAlign: "center",
            color: "red",
          }}
        >
          Rupture de stock{" "}
        </p>
      ) : (
        <div
          style={{
            color: "black",
            fontSize: "1.6rem",
            marginTop: "0.3rem",
            marginBottom: "0.3rem",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: "12px",
              marginBottom: "0",
            }}
          >
            Quantité nécessaire :
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "12px",
              marginTop: "0",
            }}
          >
            <b>{product.besoinChantier} unité(s)</b>
          </p>
        </div>
      )}

      <Grid
        style={{
          display: "flex",
          justifySelf: 'start',
        }}
      >
        <Typography
          //onMouseEnter={() => setButtonHovered(true)}
          //onMouseLeave={() => setButtonHovered(false)}
          style={{
            color: "#0079C2",
            fontFamily: "Montserrat",
            fontSize: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            textTransform: 'uppercase',
            //textAlign: "center",
            fontWeight: 700,
            cursor: "pointer",
            marginTop: '8px'
            //marginBottom: "16px",
            //marginLeft: '12px',
          }}
          onClick={() => {
            history.push({
              pathname: `/produits/${product.id}`,
              state: {
                idUser: idUser,
                solution: solution,
                id: product.id,
                calculateur: product.besoinChantier,
                isFromFavorite: false,
              },
            });
          }}
        >
          {"Plus de détails"}
          <EastIcon color="primary" style={{ marginLeft: '16px', height: '20px' }} />
        </Typography>
      </Grid>


      {/* <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          history.push({
            pathname: `/produits/${product.id}`,
            state: {
              idUser: idUser,
              solution: solution,
              id: product.id,
              calculateur: product.besoinChantier,
              isFromFavorite: false,
            },
          });
        }}
      >
        Plus de détails
      </Button> */}
    </Paper>
  );
};

export default Products;
