import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  AddChantier,
  deleteChantier,
  getChantiersByUserId,
  searchChantierByName,
} from '../../API/APIRequest/Chantiers/Chantiers';
import notifyDelete from '../../Shared/notifyDelete';
import notifyError from '../../Shared/notifyError';
import notifySuccess from '../../Shared/notifySuccess';
import PopUpAddChantier from './../../components/PopUpAddChantier/PopUpAddChantier';
import ChantierToast from './ChantierToast';
import './style.css';
import mesChantiersStyle from './style';
import BackButton from "../../components/BackButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import EmptySearchList from "../../components/EmptySearchList";

const Chantier = ({ appInsights }) => {
  const [isLoading, setIsLoading] = useState('true');

  const onCancelDelete = (itemDeleted) => {
    let data = itemDeleted;

    setIsLoading(true);
    AddChantier(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess('Favori ajouté');
            getChantiersByUserId(idUser, {
              onSuccess: (response) => {
                if (response.data.isSuccess === true) {
                  setFullList(response.data.data);
                  setFilteredChantiers(response.data.data);
                  response.data.data.length < 1
                    ? setIsEmpty(true)
                    : setIsEmpty(false);
                  setIsLoading(false);
                }
              },
              onError: (error) => {
                notifyError(error);
                setIsLoading(false);
              },
            });
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      },
      data
    );
  };

  //Delete Toast
  const DeleteToastMsg = ({ closeToast, itemDeleted }) => {
    //Get Data Delete

    let DataToRePost = itemDeleted;
    if (DataToRePost.product === null) {
      DataToRePost.product = [];
    }
    return (
      <div style={{ width: '2000px' }}>
        L’élément a bien été supprimé
        <Button
          onClick={() => onCancelDelete(DataToRePost)}
          style={{ fontSize: 15, color: '#9ED6FF' }}
        >
          Annuler
        </Button>
      </div>
    );
  };
  //UserManagement
  const [idUser, setIdUser] = useState();

  //PopUp management
  const [openPopUp, setOpenPopUp] = useState(false);
  const handlePopUpClose = () => {
    getChantiersByUserId(idUser, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setFullList(response.data.data);
          setFilteredChantiers(response.data.data);
          response.data.data.length < 1 ? setIsEmpty(true) : setIsEmpty(false);
          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
    setOpenPopUp(false);
  };

  //Chantiers
  const [fullList, setFullList] = useState([]);
  const [chantiers, setFilteredChantiers] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  //FilterManagement
  const [filter, setFilter] = React.useState(1);
  const handleChange = (event) => {
    let chantierToOrder = chantiers;
    setFilter(event.target.value);

    if (event.target.value === 1) {
      //FilterByName
      chantierToOrder.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    if (event.target.value === 2) {
      //filterByStartDate
      chantierToOrder.sort(function (a, b) {
        var aa = a.beginDate.split('/').reverse().join(),
          bb = b.beginDate.split('/').reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
    }
    if (event.target.value === 3) {
      //filterByEndDate
      chantierToOrder.sort(function (a, b) {
        var aa = a.endDate.split('/').reverse().join(),
          bb = b.endDate.split('/').reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
    }
    if (event.target.value === 4) {
      //FilterByName
      chantierToOrder.sort(function (a, b) {
        return a.clientName.localeCompare(b.clientName);
      });
    }
  };

  //SearchManagement
  const [chantierToSearch, setchantierToSearch] = useState('');
  const handleChangeValue = (e) => {
    if (e.key === 'Enter') {
      searchFavByName();
    } else return setchantierToSearch(e.target.value);
  };
  const searchFavByName = () => {
    setIsLoading(true);
    if (chantierToSearch !== '') {
      searchChantierByName(idUser, chantierToSearch, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredChantiers(response.data.data);
            setIsLoading(false);
            response.data.data === [] ? setIsEmpty(false) : setIsEmpty(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    } else {
      getChantiersByUserId(idUser, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredChantiers(response.data.data);
            response.data.data.length < 1
              ? setIsEmpty(true)
              : setIsEmpty(false);
            setIsLoading(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    }
  };

  //removeChantier
  const removeChantier = (data) => {
    setIsLoading(true);

    deleteChantier(data.id, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          notifyDelete(data, DeleteToastMsg);
          getChantiersByUserId(idUser, {
            onSuccess: (response) => {
              if (response.data.isSuccess === true) {
                setFullList(response.data.data);
                setFilteredChantiers(response.data.data);
                response.data.data.length < 1
                  ? setIsEmpty(true)
                  : setIsEmpty(false);

                setIsLoading(false);
              }
            },
            onError: (error) => {
              notifyError(error);
              setIsLoading(false);
            },
          });
        } else {
          notifyError('Erreur lors de la suppression du chantier');
          getChantiersByUserId(idUser, {
            onSuccess: (response) => {
              if (response.data.isSuccess === true) {
                setFullList(response.data.data);
                setFilteredChantiers(response.data.data);
                response.data.data.length < 1
                  ? setIsEmpty(true)
                  : setIsEmpty(false);

                setIsLoading(false);
              }
            },
            onError: (error) => {
              notifyError(error);
              setIsLoading(false);
            },
          });
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  };

  //IsFavoritesolutionAvailable and Navigation
  let history = useHistory();

  const isAvailable = (params) => {
    setIsLoading(true);
    history.push({
      pathname: `/chantiers/${params.id}`,
      state: {
        idUser: idUser,
        chantier: params,
        isFromChantierList: true,
      },
    });
  };

  //Favorite unaivalable popup
  const [openDelMenu, setOpenDelMenu] = useState(false);
  const [selectedFav, setSelectedFav] = useState({});
  const handleClose = () => {
    setOpenDelMenu(false);
  };

  //UseEffect global
  useEffect(() => {
    setIsLoading(true);
    var accountInfo = localStorage.getItem('accountInfo');
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);
    getChantiersByUserId(accountInfoParsed.account.accountIdentifier, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setFullList(response.data.data);
          setFilteredChantiers(response.data.data);
          response.data.data.length < 1 ? setIsEmpty(true) : setIsEmpty(false);
          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
    //Get Data by profile info

    return () => {
    };
  }, []);

  const [sortSelect, setSortSelect] = React.useState('');
  const handleSortSelect = (event) => {
    setSortSelect(event.target.value);
  };


  return (
    <>
      <ToastContainer />

      <Grid
        style={mesChantiersStyle.mainGrid}
      >
        <Grid
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1400px',
            width: '95%',
            color: 'white',
          }}
          marginX={"auto"}>
          <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
            <Breadcrumbs variantColor={'white'} />
            <BackButton />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: 'inherit'
          }}
        >
          <Grid
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}
          >
            <Grid
              container
              justifyContent={'center'}
              alignItems="center"
              direction={'row'}
            >
              <Typography style={{
                fontFamily: 'Montserrat',
                fontSize: '38px',
                fontWeight: 400,
                marginRight: '8px',
                letterSpacing: '0.356px',
              }}>Mes </Typography>
              <Typography style={{
                fontFamily: 'Montserrat',
                fontSize: '38px',
                fontWeight: 900,
                letterSpacing: '0.356px',
              }}>chantiers</Typography>
            </Grid>

          </Grid>

          <Grid
            marginX={"auto"}
            style={{
              display: 'flex',
              // maxWidth: '1400px',
              // width: '95%',
              padding: "0 15px",
              margin: '0 20px',
              justifyContent: 'center',
            }}>
            <Grid style={mesChantiersStyle.form}>
              <TextField
                variant={"standard"}
                InputProps={{ disableUnderline: true }}
                onKeyUp={(e) => handleChangeValue(e)}
                // onChange={(e) => handleChangeValue(e)}
                style={mesChantiersStyle.formField}
                id="chantier-shearch-field"
                placeholder="Filtrer ..."
              />
              <SearchIcon
                htmlColor={"#1D97EF"}
                onClick={() => searchFavByName()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Body */}
      {isLoading ? (
        <Grid
          style={{
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid style={mesChantiersStyle.gridBody}>
          <Grid
            style={{
              maxWidth: '1400px',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              minHeight: '500px',
              paddingBottom: '60px'
            }}
            sx={{
              width: { mobile: 'inherit', desktop: '95%' },
              padding: { mobile: '0 24px', desktop: 0 }
            }}
          >
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              sx={{
                flexDirection: { mobile: 'column', desktop: 'row' },
                marginTop: { mobile: '28px', desktop: '60px' },
                marginBottom: { mobile: '20px', desktop: '40px' },
              }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  padding: "8px 22px",
                  height: '40px'
                }}
                onClick={() => setOpenPopUp(true)}
              >
                <AddIcon style={{ marginRight: '10px' }} />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "19px",
                    textWrap: 'nowrap',
                  }}>Créer un nouveau chantier
                </Typography>
              </Button>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'end'
                }}
                sx={{
                  marginTop: { mobile: '24px', desktop: 0 },
                }}>
                <Typography style={mesChantiersStyle.boldTypo}>
                  Trier par
                </Typography>
                <Select
                  variant={"standard"}
                  id="sort-select"
                  value={filter}
                  disableUnderline={true}
                  onChange={handleChange}
                  sx={{
                    borderRadius: '5px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                  MenuProps={{
                    sx: {
                      "&& .MuiMenuItem-root": {
                        color: '#6B6B6B',
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        fontWeight: 500,
                      }
                    }
                  }}
                >
                  <MenuItem value={1}>Nom du chantier</MenuItem>
                  <MenuItem value={2}>Date de début</MenuItem>
                  <MenuItem value={3}>Date de fin</MenuItem>
                  <MenuItem value={4}>Nom du client</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {chantiers.length !== 0
              ? chantiers.map((item, index) => {
                return (
                  <ChantierToast
                    isAvailable={isAvailable}
                    removeChantier={removeChantier}
                    data={item}
                    key={item.name + index}
                  />
                );
              }) : <EmptySearchList firstLine={'Aucun chantier trouvé'} />
            }
          </Grid>
          {/*)}*/}
          <Dialog
            open={openPopUp}
            //maxWidth="xl"
            onClose={handlePopUpClose}
            sx={{
              width: '100%',
            }}
          >
            <DialogContent
              sx={{
                overflowX: 'hidden',
              }}
            >
              <PopUpAddChantier
                close={handlePopUpClose}
                idUser={idUser}
                Refresh={getChantiersByUserId}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </>
  );
};

export default Chantier;

