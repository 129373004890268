import React from "react";
import { Grid, Typography } from "@mui/material";
import styles from "./styles";

const PolicyPage = () => {
  return (
    <Grid style={styles.handler}>
      <Typography style={styles.mainTitle}>
        Politique de traitement des données personnelles
      </Typography>
      <Typography style={styles.text}>
        L’objectif de cette politique de confidentialité est de décrire comment
        ce site web est administré et comment les informations personnelles des
        utilisateurs et/ou des visiteurs du site susmentionné sont traitées.
        Cette politique de confidentialité est également rédigée à des fins
        d’information, conformément aux articles 13 et 14 du règlement (UE)
        2016/679 (ci-après, le <b>«RGPD»</b>).
      </Typography>
      <Typography style={styles.text}>
        Cette politique s'applique exclusivement au site internet
        www.monchantieravecmapei.fr et l’application mobile « Mon Chantier avec
        Mapei »(ci-après, le <b>« Site »</b>), et non à d'autres sites internet
        auxquels les utilisateurs peuvent accéder via des liens.
      </Typography>
      <Typography style={styles.text}>
        MAPEI France S.A., ayant son siège sis au Z.I. du terroir – 29, Avenue
        Léon Jouhaux – 31140 SAINT-ALBAN, FRANCE (ci-après, la <b>«Société»</b>)
        est le responsable du traitement des données personnelles collectées via
        le Site, conformément aux articles 4-7 et 24 du RGPD.
      </Typography>
      <Typography style={styles.text}>
        Le traitement des données personnelles désigne toute opération ou tout
        ensemble d'opérations effectuées ou non à l'aide de procédés automatisés
        et appliquées à des données ou des ensembles de données à caractère
        personnel, telles que la collecte, l'enregistrement, l'organisation, la
        structuration, la conservation, l'adaptation ou la modification,
        l'extraction, la consultation, l'utilisation, la communication par
        transmission, la diffusion ou toute autre forme de mise à disposition,
        le rapprochement ou l'interconnexion, la limitation, l'effacement ou la
        destruction (art. 4-2 RGPD) ;
      </Typography>
      <Typography style={styles.text}>
        En conséquence, la Société vous informe, en tant que personne concernée
        au sens du règlement européen, que conformément aux articles 13 et 14 du
        RGPD, ces données seront traitées aux fins indiquées ci-dessous,
        manuellement et/ou avec l'aide de moyens informatiques ou télématiques.
      </Typography>
      <Typography style={styles.title}>
        1. OBJECTIF DU TRAITEMENT DE DONNEES
      </Typography>
      <Typography style={styles.text}>
        Les données sont traitées conformément aux dispositions du RGPD, aux
        fins suivantes :
      </Typography>
      <br />
      <Typography style={styles.text}>
        - INFORMATION RECUEILLIE PENDANT LA NAVIGATION : Pendant que les
        systèmes informatiques et les procédures logicielles exécutent leurs
        tâches et fonctions de routine sur le Site, certaines informations
        personnelles sont obtenues puis transmises implicitement lors de
        l'utilisation de protocoles de communication Internet. Les informations
        qui entrent dans cette catégorie sont : les adresses IP, le type de
        navigateur utilisé, le système d'exploitation, le domaine et les
        adresses de sites web utilisés pour accéder ou quitter le Site, les
        informations sur les pages du site internet visité par l'utilisateur, le
        moment où l'utilisateur a accédé au Site, la durée pendant laquelle
        l'utilisateur reste sur chaque page, une analyse d'itinéraire interne et
        d'autres paramètres concernant le système d'exploitation et
        l'environnement informatique de l'utilisateur. Ces informations
        techniques et/ou informatiques sont collectées, consolidées et utilisées
        de manière totalement anonyme. Ces données sont traitées dans le but de
        permettre et de contrôler une utilisation correcte du Site, ainsi que de
        recueillir des informations statistiques anonymes sur son utilisation,
        et seront annulées immédiatement après le traitement.
      </Typography>
      <Typography style={styles.text}>
        - INFORMATION FOURNIE VOLONTAIREMENT PAR L'UTILISATEUR : Les données qui
        peuvent être fournies volontairement par l'utilisateur sont traitées
        uniquement dans le cas où l'utilisateur consent au traitement et pour
        les fins suivantes :
      </Typography>
      <Typography style={styles.text}>
        - PRESTATION DE SERVICES : Pour accéder aux services offerts sur le Site
        à savoir la simulation de la nature et de la quantité de produits MAPEI
        nécessaires à la réalisation d’un chantier, la sauvegarde de ces
        simulations de chantiers et la demande de renseignements
        complémentaires, le visiteur sera invité à fournir volontairement
        certaines informations personnelles en complétant un formulaire
        d’inscription . Ce formulaire rendra obligatoire la communication du
        prénom, nom, adresse e-mail, numéro de téléphone, département et métier
        afin de pouvoir apporter une solution personnalisée dans le cadre des
        prestations de services offertes par le site à des fins commerciales
        et/ou pour répondre aux questions techniques. Le refus de transmettre
        ces informations rendra impossible l’inscription et ainsi MAPEI France
        incapable de fournir le service requis.
      </Typography>
      <Typography style={styles.text}>
        Le visiteur pourra également fournir toutes autres données personnelles
        qu’il a librement souhaité transmettre dans l’espace libre «
        Contactez-nous »
      </Typography>
      <Typography style={styles.text}>
        Aucune autre donnée personnelle ne sera collectée hormis les données
        recueillis pendant la navigation tel qu’énoncés préalablement.
      </Typography>
      <Typography style={styles.text}>
        Enfin, l'envoi volontaire d'e-mails par le visiteur aux adresses mails
        indiquées sur le Site conduira à l'acquisition des informations
        personnelles de l'expéditeur (exemple : prénom, nom et adresse mail ou
        toute autres données personnelles transmises volontairement à cette
        occasion). Ces informations personnelles fournies volontairement par le
        visiteur n’auront pour finalité que de répondre à la demande/message
        formulé par le visiteur.
      </Typography>
      <Typography style={styles.text}>
        -MARKETING : Par son consentement le visiteur peut accepter que ses
        données soit utilisées pour envoyer notre newsletter par e-mail ou tout
        autre matériel informatif (Catalogue, prospectus, e-mail,…) et à des
        fins promotionnelles et/ou de marketing.
      </Typography>
      <Typography style={styles.text}>
        - PROFILAGE : À des fins de profilage corrélé aux études de marché et à
        l'analyse statistique. Cette catégorie comprend également les activités
        connectées ou corrélées au profilage des utilisateurs pour identifier
        leurs goûts, préférences, habitudes, besoins et choix afin d'améliorer
        la qualité des services fournis par le Groupe MAPEI.
      </Typography>
      <Typography style={styles.title}>
        2. COMMUNICATION DES DONNÉES
      </Typography>
      <Typography style={styles.text}>
        Les données peuvent être traitées par des salariés et des collaborateurs
        de MAPEI France S.A. ou des sociétés du Groupe MAPEI en tant que
        «personnes chargées du traitement» (c'est-à-dire des personnes qui,
        placées sous l'autorité directe du Responsable du traitement ou du
        Sous-Traitant, sont autorisées à traiter les données à caractère
        personnel, conformément aux articles 4-10 et 29 du RGPD) ainsi que par
        les Sous-Traitants. Les données peuvent également être traitées par des
        sociétés de confiance fournissant, pour le compte de MAPEI France ou des
        sociétés du Groupe MAPEI, des services techniques et organisationnels.
        Ces sociétés sont des collaborateurs directs de MAPEI France ou des
        sociétés du Groupe MAPEI et sont nommés Sous-traitants. Leur liste est
        constamment mise à jour et est disponible, sur demande écrite adressée à
        l'adresse mentionnée ci-dessus ou un email à privacy@mapei.fr
      </Typography>
      <Typography style={styles.title}>
        3. NATURE DE LA FOURNITURE DE DONNEES ET CONSEQUENCES DU REFUS
      </Typography>
      <Typography style={styles.text}>
        DONNEES TRAITEES POUR LES PRESTATIONS DE SERVICES OFFERTES SUR LE SITE
      </Typography>
      <Typography style={styles.text}>
        Pour accéder à certains services offerts par le Site (comme le calcul de
        produits ou encore les demandes de renseignements), la fourniture de
        données personnelles est nécessaire. Le refus de transmettre ces
        informations rendra MAPEI France incapable de fournir le service requis.
      </Typography>
      <Typography style={styles.text}>
        DONNEES TRAITEES A DES FINS MARKETING
      </Typography>
      <Typography style={styles.text}>
        Pour effectuer le traitement des données à des fins marketing, MAPEI
        France obtient un consentement spécifique, distinct, express, documenté,
        préalable et facultatif.
      </Typography>
      <Typography style={styles.text}>
        En acceptant le traitement des données à des fins marketing, la personne
        concernée reconnaît spécifiquement les objectifs promotionnels,
        commerciaux et marketing (y compris les activités administratives et de
        gestion) et autorise expressément le traitement (les moyens utilisés
        pour le traitement peuvent être: appel par l’opérateur ou par d'autres
        moyens non électroniques, non télématiques ou non supportés par des
        mécanismes et/ou des procédures automatiques, électroniques ou
        télématiques et où les moyens utilisés pour le traitement sont l’email,
        le fax, les sms, mms, systèmes automatiques sans opérateur et
        similaires, y compris plates-formes électroniques et autres moyens
        électroniques) selon l'article 6-1-a du RGPD.
      </Typography>
      <Typography style={styles.text}>
        Un refus de fournir son consentement au traitement des données à des
        fins marketing n’engendrera aucune conséquence sur l’exécution d’un
        contrat, d’une relation d’affaires ou d’une quelconque autre relation
        avec l’utilisateur.
      </Typography>
      <Typography style={styles.text}>
        La personne concernée est libre de donner son consentement pour d’autres
        communications à des tiers qui procéderaient au traitement des données à
        des fins marketing. En cas de refus de donner son consentement pour des
        communications futures à des tiers qui procéderaient au traitement à des
        fins marketing, cela rendrait impossible toute communication et les
        données seraient traitées uniquement et exclusivement par la Société,
        dès lors que la personne concernée a donné son consentement au
        traitement à des fins marketing.
      </Typography>
      <Typography style={styles.text}>
        DONNEES TRAITEES A DES FINS DE PROFILAGE
      </Typography>
      <Typography style={styles.text}>
        La Société peut également être amenée à traiter les données à des fins
        de profilage . Pour un tel traitement, et aux fins d’information
        complète, il est fait référence à l’article 4-1-4 du RGPD, qui définit
        le “profilage” comme “toute forme de traitement automatisé de données à
        caractère personnel consistant à utiliser ces données à caractère
        personnel pour évaluer certains aspects personnels relatifs à une
        personne physique”.
      </Typography>
      <Typography style={styles.text}>
        La personne concernée a le droit de s’opposer à tout moment au
        traitement de ses données à caractère personnel à des fins de «
        profilage ». Dans ce cas, les données personnelles ne seront plus
        traitées pour cette finalité.
      </Typography>
      <Typography style={styles.text}>
        En cas de refus pour les activités de profilage, il sera impossible pour
        la Société de procéder au traitement susmentionné. La personne concernée
        est libre de donner son consentement à des activités de profilage et/ou
        à d’autres communications à des tiers qui procéderaient à des activités
        de profilage. En cas de refus de consentir aux activités de profilage
        et/ou de communication à des tiers qui procéderaient à des activités de
        profilage, il en résultera qu’il n’y aura pas d’activités de profilage
        de la part de la Société ni de communication à des tiers. Les données
        seront traitées uniquement et exclusivement par la Société, lorsque la
        personne concernée aura donné son consentement pour le traitement à des
        fins marketing. Les données qui font l’objet d’activités de profilage ne
        feront l’objet d’aucune divulgation.
      </Typography>
      <Typography style={styles.text}>DISPOSITIONS GENERALES </Typography>
      <Typography style={styles.text}>
        Dans tous les cas, même si la personne concernée a donné son
        consentement pour autoriser la Société à poursuivre tous les objectifs
        susmentionnés, elle restera libre de se rétracter à tout moment.
        Conformément à l’article 21 du RGPD, la personne concernée a le droit de
        s’opposer à tout moment au traitement de ses données à caractère
        personnel aux fins susmentionnées. Dans ce cas, les données personnelles
        ne seront plus traitées à ces fins.
      </Typography>
      <Typography style={styles.title}>
        4. TRANFERT DES DONNEES PERSONNELLES HORS DE L’ESPACE ECONOMIQUE
        EUROPEEN (EEE)
      </Typography>
      <Typography style={styles.text}>
        Les données collectées et traitées peuvent être transférées aux fins
        susmentionnées en dehors de l’Espace Economique Européen (à savoir les
        Etats membres de l’Union Européenne (UE), ainsi que la Norvège,
        l’Islande et le Liechtenstein) : - A des tiers fournisseurs de services
        informatiques et/ou de stockage de données, également hébergées sur le
        cloud. - Aux sociétés du Groupe MAPEI.
      </Typography>
      <Typography style={styles.text}>
        Les données sont transférées uniquement en conformité avec les lois de
        protection des données et lorsque les moyens de transfert fournissent
        des garanties adéquates en ce qui concerne les données, par exemple :
      </Typography>
      <Typography style={styles.text}>
        - Par un accord de transfert de données intégrant les clauses
        contractuelles standards en vigueur adoptées par la Commission
        Européenne pour le transfert de données personnelles par les
        Responsables de traitement dans l’EEE aux Responsables du traitement et
        aux Sous-Traitants dans les juridictions dépourvues de lois adéquates
        sur la protection des données ; ou
        <br />- En s’inscrivant dans le cadre du Privacy Shield Framework
        EU-U.S. (Bouclier de Protection des Données UE-Etats-Unis) pour le
        transfert de données personnelles d’entités de l’UE à des entités des
        Etats-Unis ou de tout autre accord équivalent relatif aux autres
        juridictions ; ou <br />
        - En transférant les données dans un pays où la Commission Européenne a
        constaté que les niveaux de protection des données de ce pays étaient
        adéquats au regard de sa législation ; ou <br />
        - Lorsque cela est nécessaire à la conclusion ou à l’exécution d’un
        contrat entre nous et un tiers et que le transfert est dans l’intérêt de
        la personne concernées aux fins de ce contrat ; ou <br />- Lorsque la
        personne concernée a consenti au transfert de données.
      </Typography>
      <Typography style={styles.title}>
        5. DUREE DU TRAITEMENT DES DONNEES
      </Typography>
      <Typography style={styles.text}>
        Toutes les données personnelles sont collectées et enregistrées de façon
        légale et équitable aux fins décrites ci-dessus. Les données sont
        également traitées avec l'aide des Systèmes d'Information et de bases de
        données en accord avec lesdites finalités afin d’assurer leur sécurité
        et leur confidentialité. Les données personnelles seront stockées
        conformément au principe de proportionnalité de façon à permettent
        l'identification de la personne concernée et ce, jusqu'à ce que les
        objectifs du traitement aient été atteints.
      </Typography>
      <Typography style={styles.title}>
        6. RESPONSABLE DE TRAITEMENT ET SOUS-TRAITANT
      </Typography>
      <Typography style={styles.text}>
        Le Responsable de Traitement est : <br />
        Mapei France S.A.
        <br />
        29 Avenue Léon Jouhaux
        <br />
        CS40021 <br />
        31141 ST ALBAN CEDEX
        <br />
        France
      </Typography>
      <Typography style={styles.text}>
        Comme mentionné ci-dessus, les données peuvent également être traitées
        par des sociétés de confiance fournissant, pour le compte de MAPEI
        France ou des sociétés du Groupe MAPEI, des services techniques et
        organisationnels. Ces sociétés sont des collaborateurs directs de MAPEI
        France ou des sociétés du Groupe MAPEI et sont nommés Sous-traitants.
        Leur liste est constamment mise à jour et est disponible, sur demande
        écrite adressée à l'adresse mentionnée ci-dessus ou un email à
        <a
          style={{ marginLeft: "10px" }}
          href="mailto:privacy@mapei.fr"
          alt="mail mapei"
        >
          privacy@mapei.fr
        </a>
      </Typography>
      <Typography style={styles.title}>
        7. DROITS DE LA PERSONNE CONCERNEE
      </Typography>
      <Typography style={styles.text}>
        Conformément aux articles 13–2–b, 13–2-d, 14-2-c, 14-2-e, 15, 18, 19 et
        21 du RGPD, la personne concernée : a) a le droit de demander à la
        Société l'accès à ses données personnelles, à leur rectification ou
        effacement, à la limitation du traitement, ou de s’opposer à leur
        traitement ; b) a le droit à la portabilité des données conformément à
        l'article 20 du RGPD; c) a le droit de déposer une requête auprès de la
        CNIL, conformément aux procédures et aux indications disponibles sur son
        site web officiel : www.cnil.fr Toute rectification ou tout effacement
        ou limitation du traitement faite à la demande de la personne concernée
        - à moins que cela soit impossible ou exige des efforts disproportionnés
        - sera notifié par la Société à chaque destinataire auquel les données à
        caractère personnel ont été communiquées. La Société peut communiquer
        leurs noms à la personne concernée, si la personne concernée le demande.
        L'exercice de ces droits n'est soumis à aucune forme de contrainte et
        est gratuit. Pour exercer ces droits, la personne concernée peut
        contacter le Responsable du traitement en envoyant une demande à
        l’adresse mentionnée ci-dessus ou par email à{" "}
        <a href="mailto:privacy@mapei.fr" alt="mail mapei">
          privacy@mapei.fr
        </a>
      </Typography>
      <Typography style={styles.title}>
        8. LIENS VERS DES SITES WEB DE TIERS
      </Typography>
      <Typography style={styles.text}>
        A partir de ce Site, il est possible de se connecter directement à
        d'autres sites web tiers à travers des liens dédiés. Le propriétaire de
        ce Site décline toute responsabilité sur la façon dont les informations
        personnelles sont administrées et gérées par les sites web tiers ainsi
        que quant à la gestion des informations d'authentification fournies par
        des tiers.
      </Typography>
      <Typography style={styles.title}>9. COOKIES</Typography>
      <Typography style={styles.text}>
        Les cookies sont des lots d'information envoyés par un serveur Web (par
        exemple le Site) au navigateur Internet de l’utilisateur. Ils sont
        automatiquement stockés sur l'ordinateur de l'utilisateur et sont
        renvoyés automatiquement au serveur à chaque fois que le Site est
        consulté. Le réglage par défaut de la plupart des navigateurs Internet
        permet d’accepter automatiquement les cookies. Ce Site n'utilise pas de
        cookies pour transmettre des informations à caractère personnel.
        L'utilisation des cookies de session (qui ne sont pas stockés de façon
        permanente sur l'ordinateur de l'utilisateur et qui sont effacés à
        chaque fois que le navigateur Internet est fermé) est strictement
        limitée à la transmission des identités de session nécessaires pour
        permettre une utilisation sûre et efficace du Site. Les cookies de
        session utilisés sur ce Site évitent l'utilisation d'autres techniques
        informatiques, qui pourraient être potentiellement préjudiciables à la
        vie privée de l'utilisateur pendant la navigation, et qui ne permettent
        pas l'acquisition d’informations personnelles sur l'utilisateur. Des
        cookies permanents sont aussi utilisés par ce Site, c'est-à-dire les
        cookies qui restent stockés sur le disque dur de l'ordinateur jusqu'à ce
        qu'ils expirent ou qu’ils soient annulés par l'utilisateur ou le
        visiteur. En utilisant des cookies permanents, les visiteurs du Site (ou
        d’autres utilisateurs utilisant le même ordinateur) sont automatiquement
        reconnus chaque fois qu’ils visitent le Site. Les visiteurs peuvent
        paramétrer leur navigateur pour qu'il accepte ou refuse tous les cookies
        ou qu’un message apparaisse à chaque fois qu’un cookie est suggéré afin
        qu’ils puissent décider de l’accepter ou non. À tout moment
        l'utilisateur peut modifier le réglage par défaut et désactiver tous les
        cookies (c'est-à-dire les bloquer complètement) en choisissant le niveau
        de protection le plus élevé. Pour toutes les autres informations
        relatives aux caractéristiques, aux types, aux méthodes et aux façons
        d'éliminer, d’annuler ou de désactiver les cookies présents sur le Site,
        merci de vous référer à la Politique d’Utilisation des Cookies.
      </Typography>
    </Grid>
  );
};

export default PolicyPage;
