import React from "react";
import {Button, Dialog, DialogActions, DialogContent, Grid, Typography} from "@mui/material";

const ConfirmDeleteDialog = (props) => {
  const {open, close, removeFav, data, message = 'cette solution'} = props;


  return (<Dialog
    open={open}
    onClose={close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    // PaperProps={{ sx: { bgcolor: "pink", maxWidth: "revert", width: 1200 } }}
    sx={{'& .MuiPaper-root': {borderRadius: '20px'}}}
  >
    <DialogContent
      style={{borderRadius: '20px'}}
      sx={{padding: {xs: '40px 56px 24px 56px', sm: '40px 24px 24px 24px'}}}
    >
      <Typography style={{
        color: "#000000", fontWeight: "400", fontFamily: "Montserrat",
      }}>Êtes-vous sûr(e) de vouloir supprimer {message} ?
      </Typography>
      <Typography style={{
        textDecoration: "underline", color: "#000000", fontWeight: "500", fontFamily: "Montserrat",
      }}>Cette action est irréversible.</Typography>
    </DialogContent>
    <DialogActions style={{justifyContent: 'center', paddingBottom: "40px"}}>
      <Grid sx={{display: "flex", flexDirection: {xs: 'column', sm: 'row'}}}>
        <Button
          onClick={() => removeFav(data)}
          color="primary"
          variant="contained"
          style={{
            borderRadius: "30px",
            width: "12em",
            backgroundColor: "#F50157",
            color: "white",
            fontFamily: "Montserrat",
            fontSize: "13px",
          }}
          sx={{marginBottom: {xs: '40px', sm: '0'}, marginRight: {xs: '0', sm: '40px'}}}
        >
          Oui
        </Button>
        <Button
          onClick={close}
          color="secondary"
          variant="contained"
          style={{
            borderRadius: "30px",
            width: "12em",
            backgroundColor: "#0079C2",
            color: "white",
            fontFamily: "Montserrat",
            fontSize: "13px",
          }}
        >
          Non
        </Button>
      </Grid>
    </DialogActions>
  </Dialog>);
};

export default ConfirmDeleteDialog;
