import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {
  getFavoritesProductsByUserId,
  removeFavoriteProducts,
  searchProductsFavoriteByName,
} from "../../API/APIRequest/Favorites/Favorites";
import notifyError from "../../Shared/notifyError";
import notifySuccess from "../../Shared/notifySuccess";
import "./style.css";
import ProductFavoritesStyles from "./style";
import FavoritesProductsCard from "./FavoritesProductsCard";
import {Box, CircularProgress, Grid, MenuItem, Select, TextField, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import favoritesSolutionStyle from "../FavoritesSolutions/style";

const FavoritesProducts = ({appInsights}) => {
  const [isLoading, setIsLoading] = useState("true");

  //UserManagement
  const [idUser, setIdUser] = useState();

  //Favorites
  let FavFullList = [];
  const [fullList, setFullList] = useState([]);
  const [favorites, setFilteredFavorites] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  //FilterManagement
  const [filter, setFilter] = React.useState(0);

  const handleChange = (event) => {
    let favoritesToOrder = favorites;
    setFilter(event.target.value);
    if (event.target.value === 1) {
      //FilterByName
      favoritesToOrder.sort(function (a, b) {
        return a.productName.localeCompare(b.productName);
      });
    } else {
      // FilterByDate
      favoritesToOrder.sort(function (b, a) {
        var dateA = new Date(a.creationdate);
        var dateB = new Date(b.creationdate);

        return dateA - dateB;
        // return a.creationDate.localeCompare(b.creationDate);
      });
      return setFilteredFavorites(favoritesToOrder);
    }
  };

  //SearchManagement
  const [favToSearch, setFavToSearch] = useState("");
  const handleChangeValue = (e) => {
    if (e.key === "Enter") {
      searchFavByName();
    } else return setFavToSearch(e.target.value);
  };
  const searchFavByName = () => {
    setIsLoading(true);
    if (favToSearch !== "") {
      searchProductsFavoriteByName(idUser, favToSearch, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredFavorites(response.data.data);
            setIsLoading(false);
            response.data.data === [] ? setIsEmpty(false) : setIsEmpty(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    } else {
      getFavoritesProductsByUserId(idUser, {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setFullList(response.data.data);
            setFilteredFavorites(response.data.data);
            setIsEmpty(false);
            setIsLoading(false);
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);
        },
      });
    }
  };

  //RemoveFavorite
  const removeFav = (data) => {
    setIsLoading(true);
    const params = {
      idUser: idUser,
      idProduct: data.idProduct,
      creationDate: data.creationdate,
    };
    removeFavoriteProducts(params, {
      onSuccess: (response) => {
        notifySuccess("Favori supprimé avec succès");
        if (response.data.isSuccess === true) {
          getFavoritesProductsByUserId(idUser, {
            onSuccess: (response) => {
              if (response.data.isSuccess === true) {
                setFullList(response.data.data);
                setFilteredFavorites(response.data.data);
                setIsEmpty(false);
                setIsLoading(false);
              }
            },
            onError: (error) => {
              notifyError(error);
              setIsLoading(false);
            },
          });
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  };

  //IsFavoritesolutionAvailable and Navigation
  let history = useHistory();

  const favIsAvailable = (params) => {
    history.push({
      pathname: `/produits/${params.idProduct}`,
      state: {
        idUser: idUser,
        id: params.idProduct,
        isFromFavorite: true,
      },
    });
    setIsLoading(true);
  };

  //Favorite unaivalable popup
  const [openDelMenu, setOpenDelMenu] = useState(false);
  const [selectedFav, setSelectedFav] = useState({});
  const handleClose = () => {
    setOpenDelMenu(false);
  };

  //UseEffect global
  useEffect(() => {
    setIsLoading(true);
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);
    getFavoritesProductsByUserId(accountInfoParsed.account.accountIdentifier, {
      onSuccess: (response) => {
        setIsLoading(false);

        if (response.data.isSuccess === true) {
          setFullList(response.data.data);
          setFilteredFavorites(response.data.data);
          setIsEmpty(false);
          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
    //Get Data by profile info

    return () => {
    };
  }, []);
  return (
    <>
      <ToastContainer/>
      <Grid sx={{}}>
        <Grid style={ProductFavoritesStyles.mainGrid}>
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}>
            <Box sx={{display: {mobile: 'none !important', desktop: 'flex !important'}}}>
              <Breadcrumbs variantColor={'white'}/>
              <BackButton/>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: 'inherit'
            }}
          >
            <Grid
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
              }}
              marginX={"auto"}
            >
              <Grid
                container
                justifyContent={'center'}
                alignItems="center"
                direction={'row'}
              >
                <Typography style={{
                  fontFamily: 'Montserrat',
                  fontSize: '38px',
                  fontWeight: 400,
                  marginRight: '8px',
                  letterSpacing: '0.356px',
                  textAlign: 'center'
                }}>Mes produits et outils favoris</Typography>
              </Grid>
            </Grid>
            <Grid
              marginX={"auto"}
              style={{
                display: 'flex',
                padding: "0 15px",
                margin: '0 20px',
                justifyContent: 'center',
              }}>
              <Grid style={ProductFavoritesStyles.form}>
                <TextField
                  variant={"standard"}
                  InputProps={{disableUnderline: true}}
                  onKeyUp={(e) => handleChangeValue(e)}
                  style={ProductFavoritesStyles.formField}
                  id="chantier-shearch-field"
                  placeholder="Filtrer ..."
                />
                <SearchIcon
                  htmlColor={"#1D97EF"}
                  onClick={() => searchFavByName()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid
            style={{
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress/>
          </Grid>
        ) : (
          <Grid style={ProductFavoritesStyles.gridBody}>
            {isEmpty === true || favorites.length < 1 ? (
              <Typography style={ProductFavoritesStyles.emptyTypo}>
                Aucun favori trouvé
              </Typography>
            ) : (
              <Grid style={ProductFavoritesStyles.gridContainerCard}>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: "end",
                    marginRight: '4%',
                    marginTop: '32px',
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  sx={{
                    marginTop: {mobile: '24px', desktop: 0},
                  }}>
                  <Typography style={favoritesSolutionStyle.boldTypo}>
                    Trier par
                  </Typography>
                  <Select
                    variant={"standard"}
                    id="sort-select"
                    value={filter}
                    disableUnderline={true}
                    onChange={handleChange}
                    sx={{
                      borderRadius: '5px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                    MenuProps={{
                      sx: {
                        "&& .MuiMenuItem-root": {
                          color: '#6B6B6B',
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 500,
                        }
                      }
                    }}
                  >
                    <MenuItem value={0}>Dernière modification</MenuItem>
                    <MenuItem value={1}>Nom</MenuItem>
                  </Select>
                </Grid>

                {/*<Grid style={ProductFavoritesStyles.gridFavHolder}>*/}
                <Grid
                  sx={{
                    display: 'grid',
                    gap: 0,
                    gridTemplateColumns: {mobile: '1', desktop: 'repeat(3, 1fr)'},
                    justifyItems: 'center',
                    marginBottom: '48px',
                  }}
                >
                  {favorites
                    ? favorites.map((item, index) => {
                      return (
                        <FavoritesProductsCard
                          favIsAvailable={favIsAvailable}
                          removeFav={removeFav}
                          data={item}
                          key={item.productName + index}
                        />
                      );
                    })
                    : null}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FavoritesProducts;
