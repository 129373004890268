import Accueil from "../../images/svg/Accueil.svg";

const Style = {
  mainContainer: {
    minHeight: "90vh",
    backgroundImage: `url(${Accueil})`,
    padding: "8vh",
    paddingTop: "14vh",
    paddingBottom: "14vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    right: "0",
    paddingLeft: "auto",
    paddingRight: "auto",
  },

  secondContainer: {
    width: "1035px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "space-around",
  },

  leftGrid: {
    color: "White",
    paddingTop: "190px",
    maxWidth: "500px",
  },

  rightGrid: {
    height: "fit-content",
    backgroundColor: "white",
    marginLeft: "5vh",
    borderRadius: "5px",
    maxWidth: "385px",
    minWidth: "460px",
    padding: "20px",
  },

  leftContainer: {
    maxWidth: "500px",
    paddingLeft: "4vh",
    paddingRight: "4vh",
    paddingTop: "1vh",
    alignItems: "center",
  },

  rightContainer: {
    width: "100%",
    display: "flex",
  },

  firstP: {
    maxWidth: "60vh",
  },

  secondP: {
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: "bold",
  },

  title: {
    fontSize: "20px",
    color: "#0079C2",
    marginLeft: "auto",
    marginRight: "auto",
    width: "310px",
    fontFamily: "Montserrat",
  },

  Icon: {
    width: "375px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "12px",
    boxShadow:
      "rgba(0, 0, 1, 0.15) 0px 4px 8px 0px, rgba(0, 0, 0, 0.05) 0px 6px 20px 0px",
  },

  pIcon: {
    position: "absolute",
    bottom: "0",
    backgroundColor: "White",
    width: "375px",
    height: "36px",
    marginBottom: "6px",
    color: "#0079C2",
    borderBottomRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    fontSize: "17px",
    paddingTop: "11px",
    fontFamily: "Montserrat",
  },

  button: {
    marginTop: "2vh",
    borderRadius: "25px",
    fontSize: "large",
    width: "12em",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "2vh",
    backgroundColor: "#0079C2",
    color: "white",
    display: "block",
  },
  categories: {
    marginBottom: "1vh",
    maxWidth: "280px",
    marginLeft: "12px",
  },
};

export default Style;
