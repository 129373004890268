import axios from "axios";

let UrlFromFetch;

async function APICALL() {
  if (UrlFromFetch !== undefined) {
    return UrlFromFetch;
  } else {
    const response = await fetch("/config.json");
    const text = await response.json();
    UrlFromFetch = text;

    return text;
  }
}

const SelectAPI = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const config = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  APICALL().then((response) => {
    return axios
      .post(response.url + "solution/filteritem", JSON.stringify(params), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.jwtIdToken}`,
        },
      })
      .then((result) => {
        callback.onSuccess(result);
      })
      .catch((error) => {
        callback.onError(error);
      });
  });
};

export { SelectAPI };
