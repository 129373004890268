import {Button, Grid, Paper, Typography} from "@mui/material";
import React, {useState} from "react";
import emptyItem from "../../images/svg/emptyItem.svg";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from '@mui/icons-material/East';
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog/ConfirmDeleteDialog";

const FavoritesProductsCard = (props) => {
  const {data, editFavs, removeFav, favIsAvailable} = props;
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [buttonIsHovered, setButtonHovered] = useState(false);
  const handleClose = () => {
    setOpenEditMenu(false);
  };

  const parsedDate = () => {
    let value = new Date(data.creationdate);
    let yyyy = value.getFullYear();
    let dd = String(value.getDate());
    let mm = String(value.getMonth() + 1);
    let hours = String(value.getHours());
    let minutesParsed = String(value.getMinutes());
    let minutes = minutesParsed < 10 ? '0' + minutesParsed : minutesParsed;
    let result = dd + "/" + mm + "/" + yyyy + " à " + hours + ":" + minutes;
    return result;
  };
  return (
    <>
      <Paper
        elevation={buttonIsHovered ? 10 : 3}
        style={{
          minWidth: "353px",
          maxWidth: "353px",
          display: "flex",
          marginTop: "2vh",
          marginBottom: "2vh",
          flexDirection: "column",
          borderRadius: "11.1px",
          fontFamily: "Montserrat",
          fontSize: "15px",
        }}
      >
        <Grid style={{
          display: "flex",
          justifyContent: "end",
        }}> <Button
          fullWidth={true}
          style={{
            backgroundColor: '#F50157',
            minWidth: '30px',
            maxWidth: '30px',
            minHeight: '30px',
            maxHeight: '30px',
            borderRadius: '0px 11.1px 0px 0px',
          }}
          onClick={() => setOpenConfirmDelete(true)}
        >
          <CloseIcon
            htmlColor="white "
            size="small"
            style={{width: '15px'}}
          />
        </Button></Grid>
        <ConfirmDeleteDialog
          open={openConfirmDelete}
          removeFav={removeFav}
          message={'ce produit'}
          data={data}
          close={() => setOpenConfirmDelete(false)}
        />
        <Grid style={{display: "flex", justifyContent: "center"}}>
          <img
            style={{
              height: "196px",
            }}
            src={data.productImage !== null ? data.productImage : emptyItem}
            alt="product"
            onClick={() => favIsAvailable(data)}
          />
        </Grid>
        <Grid style={{
          padding: "0px 20px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          justifyContent: "space-evenly",
          lineHeight: "22.5px",
        }}>
          <Typography
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            style={{
              color: "#0079C2",
              fontFamily: "Montserrat",
              fontSize: "15px",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={() => favIsAvailable(data)}
          >
            {data.productName}
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "#000000",
              justifyContent: "center",
              textAlign: "center",
              fontFamily: "Montserrat",
              marginTop: '8px',
              marginBottom: "28px",
            }}
            onClick={() => favIsAvailable(data)}
          >
            Ajouté le {parsedDate()}
          </Typography>

          <Grid
            style={{
              display: "flex",
            }}
          >
            <Typography
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              style={{
                color: "#0079C2",
                fontFamily: "Montserrat",
                fontSize: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                textTransform: 'uppercase',
                textAlign: "center",
                fontWeight: 700,
                cursor: "pointer",
                marginBottom: "16px",
                marginLeft: '12px',
              }}
              onClick={() => favIsAvailable(data)}
            >
              {"Plus de détails"}
              <EastIcon color="primary" style={{marginLeft: '16px', height: '20px'}}/>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default FavoritesProductsCard;
