import {Paper, Typography} from "@mui/material";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

const ProductCard = (props) => {
  const {item, index, idUser} = props;

  const [buttonIsHovered, setButtonHovered] = useState(false);
  let history = useHistory();

  return (
    <>
      <Paper
        key={index + item.name}
        elevation={buttonIsHovered ? 10 : 3}
        style={{
          minHeight: "50px",
          maxHeight: "50px",
          minWidth: "40%",
          width: '100%',
          maxWidth: "768px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "row",
          fontFamily: "Montserrat",
          marginTop: "20px",
        }}
      >
        <Typography
          style={{
            width: "100%",
            textAlign: "center",
          }}
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
          onClick={() =>
            history.push({
              pathname: `/produits/${item.id}`,
              state: {
                idUser: idUser,
                id: item.id,
                isFromProduct: true,
                isFromFavorite: true,
              },
            })
          }
        >
          {item.name}
        </Typography>
      </Paper>{" "}
    </>
  );
};

export default ProductCard;
