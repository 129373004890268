import React from "react";
import Facebook from "./../../images/svg/Facebook.svg";
import Instagram from "./../../images/svg/Instagram.svg";
import Linkedin from "./../../images/svg/Linkedin.svg";
import MapeiLogo from "./../../images/svg/MapeiLogo.svg";
//import Twitter from "./../../images/svg/Twitter.svg";
import Xicon from "./../../images/svg/Xicon.svg"
import Youtube from "./../../images/svg/Youtube.svg";
import { Divider, Grid, IconButton, Link, Typography } from "@mui/material";


const FooterStyle = {
  LinkHref: {
    color: '#1679c2',
    textDecoration: 'none',
  },
  container: {
    backgroundColor: "#F0EFEF",
    // // position: "absolute",
    // justifyContent: "space-between",
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '19.2px',
    display: 'flex',
  },
  mapeiLogo: {
    marginLeft: '4px',
    marginBottom: '4px',
    height: '30px'
  },
  socialMedia: {
    marginBottom: '8px'
  },
  link: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '19.2px',
    color: 'black',
    textDecoration: 'none',
    marginBottom: '15px'
  },
};

const parseToTelHref = (telString) => {
  return 'tel:+33' + telString.substring(1).replace(/ /g, "")
}

const Footer = () => {
  const ButtonNetwork = (props) => {
    const { text, image, url } = props;
    return (
      <Link
        target="_blank"
        href={url}
        rel="noopener noreferrer"
      >
        <IconButton>
          <img alt={text} src={image} />
        </IconButton>
      </Link>
    );
  };

  return (
    <Grid container style={FooterStyle.container} sx={{
      flexDirection: { mobile: 'column', desktop: 'row' },
      padding: { mobile: '36px 28px 16px', desktop: '16px 80px 0px' },
    }}>
      <Grid container direction={'column'} mobile={12} desktop={6}>
        <Grid container direction={'column'} sx={{ alignItems: { mobile: 'center', desktop: 'start' } }}>
          <Grid item style={FooterStyle.mapeiLogo}>
            <img
              alt="Mapei logo"
              src={MapeiLogo}
            />
          </Grid>
          <Grid item style={FooterStyle.socialMedia}>
            <ButtonNetwork
              text="Facebook logo"
              image={Facebook}
              url={`https://www.facebook.com/mapei.france/`}
            />
            {/* <ButtonNetwork
              text="Twitter logo"
              image={Twitter}
              url={`https://twitter.com/MapeiFrance`}
            /> */}
            <ButtonNetwork
              text="X-social logo"
              image={Xicon}
              url={`https://twitter.com/MapeiFrance`}
            />
            <ButtonNetwork
              text="Linkedin logo"
              image={Linkedin}
              url={`https://www.linkedin.com/organization-guest/company/mapeifrance?challengeId=AQFPbJCRSBouxQAAAXMtWlWzUmiJ_ZWO05lofXMN1IA5NRcZ1dfTbymJS95CAJDVinI7FtI0kIjXMUjcQeCH_vTDIRYkEDnOGA&submissionId=5f4ac398-c7b6-1f16-ed6a-0aa502475d4f`}
            />
            <ButtonNetwork
              text="Instagram logo"
              image={Instagram}
              url={`https://www.instagram.com/mapeifrance/`}
            />
            <ButtonNetwork
              text="Youtube logo"
              image={Youtube}
              url={`https://www.youtube.com/user/MapeiFrance`}
            />
          </Grid>


          <Grid container sx={{ flexDirection: { mobile: "column", desktop: "row" } }}>
            <Link
              href="/utilisation-des-cookies"
              style={FooterStyle.link}
              mobile={'auto'}>
              Politique d’utilisation des Cookies
            </Link>
            <Typography
              style={FooterStyle.link}
              sx={{ display: { mobile: "none", desktop: "flex" } }}>
              &nbsp;-&nbsp;
            </Typography>
            <Link
              href="/informations-personnelles"
              style={FooterStyle.link}
              mobile={'auto'}>
              Politique de traitement des données personnelles
            </Link>
          </Grid>
          <Divider variant="middle" style={{ width: '100%', margin: '5px 0' }}
            sx={{ display: { mobile: 'flex', desktop: 'none' } }} />
        </Grid>
      </Grid>

      <Grid item mobile={12} desktop={6} style={{ margin: '15px 0' }}>
        <Grid mobile={12}>
          <Typography style={FooterStyle.typography} sx={{ justifyContent: { mobile: 'start', desktop: 'end' } }}>
            Copyright © 2021 MAPEI France S.A. Tous droits réservés
          </Typography>
          <Typography style={FooterStyle.typography} sx={{ justifyContent: { mobile: 'start', desktop: 'end' } }}>
            MAPEI France, 29 av Léon Jouhaux, CS40021,
          </Typography>
          <Typography style={FooterStyle.typography} sx={{ justifyContent: { mobile: 'start', desktop: 'end' } }}>
            31141 Saint-Alban Cedex - Téléphone :&nbsp;<a style={FooterStyle.LinkHref} href={parseToTelHref('0561357305')}>05 61 35 73 05</a>
          </Typography>
          <Typography style={FooterStyle.typography} sx={{ justifyContent: { mobile: 'start', desktop: 'end' } }}>
            <a style={FooterStyle.LinkHref}
              href={"mailto:mapei@mapei.fr?subject=MAPEI MonChantier - contact mapei"}>
              mapei@mapei.fr
            </a>
            &nbsp;-&nbsp;
            <Link href="http://www.mapei.fr" style={FooterStyle.LinkHref}>
              www.mapei.fr
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
