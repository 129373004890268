import React from "react";
import {Button, Grid, IconButton, Typography} from "@mui/material";
import Close from "./../../images/svg/Close.svg";
import PopUpStyle from "./PopUp.style";
import frame from "./Frame.png";

const PopUpDelFavorite = (props) => {
  const classes = PopUpStyle;
  const {close, deleteFavs, selectedFav} = props;

  const method = () => {
    close();
    deleteFavs(selectedFav);
    //EDIT FAVORITE NAME
  };

  return (
    <Grid container style={classes.mainContainer}>
      <Grid container style={classes.topContainer}>
        <Grid item xs={6} style={classes.gridTitle}>
          <h2>Ooops ! Aucun produit trouvé</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => close()}>
            <img style={classes.icon} alt="close button" src={Close}/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container style={classes.addContainer}>
        <Grid item xs={4} style={classes.gridNameContainer}>
          <img alt="not found image" src={frame}/>
        </Grid>
        <Grid item xs={8}>
          <Typography
            style={{
              width: "100%",
              height: "52px",
              paddingLeft: "18px",
              fontSize: "15px",
              fontFamily: "Montserrat",
            }}
          >
            Il semblerait qu’il n’existe plus de produit pour votre solution{" "}
            <b> {selectedFav.name}</b>
          </Typography>
          <Typography
            style={{
              width: "100%",
              height: "52px",
              paddingLeft: "18px",
              fontSize: "15px",
              fontFamily: "Montserrat",
            }}
          >
            Souhaitez-vous supprimer le favori associé ?
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={classes.buttonsContainer}>
        <Button
          color="default"
          variant="contained"
          style={classes.cancelLarge}
          onClick={() => close()}
        >
          Annuler
        </Button>

        <Button
          color="primary"
          variant="contained"
          style={classes.deleteLarge}
          onClick={() => method()}
        >
          Oui, Supprimer le favori
        </Button>
      </Grid>
    </Grid>
  );
};

export default PopUpDelFavorite;
