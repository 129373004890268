import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import WestIcon from "@mui/icons-material/West";

type BackButtonProps = {
  variantColor?: 'white' | 'blue',
  displayInHeader?: boolean,
  redirectMethod?: () => {},
};
const BackButton = ({ variantColor = "white", displayInHeader = false, redirectMethod }: BackButtonProps) => {
  const history = useHistory();

  const navMethod = () => {
    if (redirectMethod instanceof Function) {
      redirectMethod()
    } else {
      history.goBack()
    }
  };
  return (
    <IconButton
      onClick={navMethod}
      className="back-button"
      aria-label="back"
      style={{
        position: displayInHeader ? 'relative' : "absolute",
        zIndex: 10,
        left: 0,
        top: displayInHeader ? 0 : '45px',
        border: "none",
        borderRadius: "100%",
        //padding: "10px",
        cursor: "pointer",
        color: variantColor === 'white' ? '#fff' : '#0079C2',
        direction: 'row',
      }}
      sx={{
        // display: {xs: 'none !important', lg: 'flex !important'},
        position: { mobile: 'relative', desktop: "absolute" },
        top: { mobile: 0, desktop: '45px' }
      }}
    >
      <WestIcon
        sx={{
          paddingLeft: 0,
        }}
      />
      <Typography
        sx={{
          paddingLeft: { mobile: '8px', desktop: '16px' },
          textTransform: 'uppercase',
          fontWeight: 'lighter',
          fontSize: { mobile: '', desktop: 'inherit' }
        }}
      >
        retour
      </Typography>
    </IconButton>
  );
};

export default BackButton;
