import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { signInAuthProvider } from "../../authProvider";
import jsonConfig from "../../configFile.json";
import logoV2 from "../../images/logov2.png";
import NavbarStyle from "./Navbar.style";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  SwipeableDrawer,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider
} from "@mui/material";
import BackButton from "../BackButton";

import Event from "../../EventEmitter";



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DrawerStyle = {
  item: {
    color: '#686868',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  itemBlue: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  userInfo: {
    fontFamily: 'Montserrat',
    fontSize: '13px',
  }
}


const Navbar = () => {
  let history = useHistory();
  const classes = NavbarStyle();
  const [anchorElFav, setAnchorElFav] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);


  const location = useLocation();
  const currentPath = location.pathname.split("/")[1].toString() || '/';


  const handleFavClick = (event) => {
    setAnchorElFav(event.currentTarget);
  };

  const handleProfileClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElFav(null);
    setAnchorElProfile(null);
  };

  let reactRedirectUri = jsonConfig.urlRedirection;
  const navigation = (params) => {
    setValue('favoris')
    if (params === "solutions") {
      history.push({ pathname: "/mes-solutions" });
    } else {
      history.push({ pathname: "/mes-produits" });
    }
    handleClose();
  };

  var accountInfo = localStorage.getItem("accountInfo");
  var accountInfoParsed = JSON.parse(accountInfo);
  const userName = accountInfoParsed.account.idToken.family_name;
  const userLastName = accountInfoParsed.account.idToken.given_name;

  const editMyProfile = (e) => {
    localStorage.setItem("shouldLogout", "true");
    e.preventDefault();
    window.location.href =
      jsonConfig.editionProfileUrlFO +
      reactRedirectUri +
      jsonConfig.redirectProfileUrlFO;
  };

  const goToDeleteUserDataPage = (e) => {
    e.preventDefault();
    history.push("/compte/supprimer");
    handleClose();
  };

  const logoutUser = (e) => {
    e.preventDefault();
    signInAuthProvider.logout();
    handleClose();
  };


  const [value, setValue] = React.useState(currentPath !== '' ? currentPath : '/');

  useEffect(() => {
    if (currentPath !== 'mes-solutions' && currentPath !== 'mes-produits') {
      setValue((currentPath !== '' && currentPath !== 'recherche') ? currentPath : '/')
    }
  }, [location])
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // setValue(newValue);
    setValue(currentPath !== '' ? currentPath : '/')
  };


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (isDrawerOpen) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    // return;
    //}
    setIsDrawerOpen(isDrawerOpen);
  };

  // state for searchData when user whant go back from product details
  const [searchData, setSearchData] = useState(null);
  useEffect(() => {
    Event.addListener("backToSearch", searchData => {
      setSearchData(searchData)
    });
    return () => {
      Event.removeListener("update");
    };
  }, []);

  const backToSearchRedirect = () => {
    console.log('it was called ?')
    if(searchData !==null){
      return history.push({
        pathname: "/recherche",
        state: searchData
      });
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#0079C2',
        maxHeight: '90px',
        fontFamily: 'Montserrat',
        zIndex: 20,
        top: 0,
      }}
    >
      <Toolbar
        sx={{
          //justifyContent: { mobile: 'space-between', desktop: 'inherit' },
          justifyContent: { mobile: 'center', desktop: 'inherit' },
        }}>
        <Grid
          item
          sx={{
            position: 'absolute',
            left: 0,
            paddingLeft: '8px',
            display: { mobile: 'flex', desktop: 'none' },
          }}>
          {
          currentPath !== '/' && (
            currentPath === 'produits' ?
              <BackButton displayInHeader redirectMethod={(history?.location?.state?.isFromFavorite || isNaN(history?.location?.state?.solution?.surfaceChantier)) ? null : backToSearchRedirect}/>
              : 
              <BackButton displayInHeader />
            )
          }
        </Grid>
        <NavLink
          to="/"
          sx={{
            marginLeft: { mobile: 0, desktop: '55px' },
            left: 50,
            right: 50,
          }}
        >
          <Button
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <img
              alt="Mapei logo admin"
              src={logoV2}
              style={{ maxHeight: "36px", marginTop: "3%" }}
            />
          </Button>
        </NavLink>

        <Box
          style={{ flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}
          sx={{ display: { mobile: 'none', desktop: 'flex' }, width: '100%' }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: '#ffffff',
                height: '4px',
              },
            }}
            style={{ justifyContent: 'space-evenly' }}
            aria-label="secondary tabs example"
          >
            <Tab
              value="/"
              label="Accueil"
              onClick={() => history.push({ pathname: "/" })}
              color="inherit"
              style={{ marginRight: '20px' }}
            />
            <Tab
              value="produits"
              label="Produits"
              onClick={() => history.push({ pathname: "/produits" })}
              color="inherit"

              style={{ marginRight: '20px' }}
            />
            <Tab
              style={{ flexDirection: "row-reverse", position: 'relative', marginRight: '20px' }}
              value="favoris"
              label="Mes Favoris"
              aria-controls="simple-menu"
              aria-haspopup="true"
              icon={<ArrowDropDownIcon />}
              onClick={handleFavClick}
              color="inherit"
            />
            <Tab
              value="chantiers"
              label="Mes Chantiers"
              onClick={() => history.push({ pathname: "/chantiers" })}
              color="inherit"
              style={{ marginRight: '20px' }}
            />
            <Tab
              value="contact"
              label="Contact"
              onClick={() => history.push({ pathname: "/contact" })}
              color="inherit"
            />
          </Tabs>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            style={{
              border: Boolean(anchorElProfile) ? '3px solid white' : 'none',
              backgroundColor: '#2596be',
              color: 'white',
              fontFamily: 'Montserrat',
              borderRadius: '50%',
              minHeight: '40px',
              maxHeight: '40px',
              minWidth: '40px',
              maxWidth: '40px',
              paddingLeft: '8px',
              marginLeft: '80px',
              marginRight: '120px',
            }}
            onClick={handleProfileClick}
          >
            {userName[0]}
            {userLastName[0]}
          </Button>
        </Box>
        {/*<Button*/}
        {/*  className={classes.navButton}*/}
        {/*  color="inherit"*/}
        {/*  onClick={() => history.push({pathname: "/"})}*/}
        {/*>*/}
        {/*  ACCUEIL*/}
        {/*</Button>{" "}*/}
        {/*<Button*/}
        {/*  className={classes.navButton}*/}
        {/*  color="inherit"*/}
        {/*  onClick={() => history.push({pathname: "/listeProduits"})}*/}
        {/*>*/}
        {/*  PRODUITS*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  className={classes.navButton}*/}
        {/*  aria-controls="simple-menu"*/}
        {/*  aria-haspopup="true"*/}
        {/*  onClick={handleFavClick}*/}
        {/*  color="inherit"*/}
        {/*>*/}
        {/*  MES FAVORIS*/}
        {/*  <ArrowDropDownIcon/>*/}
        {/*</Button>*/}


        <Grid
          item
          sx={{
            display: { mobile: 'flex', desktop: 'none' },
            position: 'absolute',
            right: 0,
            paddingRight: '16px',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(!isDrawerOpen)}
            // sx={{mr: 2, display: {xs: 'block', sm: 'none',},}}
            sx={{ display: 'flex', width: '40px' }}
          >
            {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          {/* The outside of the drawer */}
          <Drawer
            anchor="top" //from which side the drawer slides in
            variant="temporary" //if and how easily the drawer can be closed
            open={isDrawerOpen} //if open is true, drawer is shown
            onClick={toggleDrawer(!isDrawerOpen)}
            onClose={toggleDrawer(false)} //function that is called when the drawer should close
            onOpen={toggleDrawer(true)} //function that is called when the drawer should open
            //onChange={setIsDrawerOpen(!isDrawerOpen)}
            sx={{
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                marginTop: '60px',
              }}
            >
              {/* The inside of the drawer */}
              <List
                sx={{
                  padding: 0
                }}
              >
                <ListItem key={'user-item'}
                  disablePadding
                  sx={{
                    justifyContent: 'end',
                  }}
                >
                  <Typography
                    color={'primary'}
                    style={DrawerStyle.userInfo}
                  >
                    {userName + ' ' + userLastName}
                  </Typography>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{
                      border: 'none',
                      backgroundColor: '#2596be',
                      color: 'white',
                      fontFamily: 'Montserrat',
                      borderRadius: '50%',
                      minHeight: '40px',
                      maxHeight: '40px',
                      minWidth: '40px',
                      maxWidth: '40px',
                      margin: '8px 16px',
                    }}
                  >
                    {userName[0]}
                    {userLastName[0]}
                  </Button>
                </ListItem>
                <Divider />

                <ListItem key={'accueil-item'} disablePadding>
                  <ListItemButton
                    //onClick={() => currentPath !== "/" && history.push({ pathname: "/" })}
                    //onClick={burgerNavigateTo('/')}
                    onClick={() => history.push({ pathname: "/" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'ACCUEIL'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'produit-item'} disablePadding>
                  <ListItemButton
                    onClick={() => history.push({ pathname: "/produits" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'PRODUITS'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'favori-item'}
                  disablePadding
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'start',
                  }}
                >
                  <Typography
                    sx={{
                      padding: '8px 16px',
                      marginRight: 'auto',
                      color: '#686868',
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      textTransform: 'uppercase',

                    }}
                  >MES FAVORIS</Typography>

                  <ListItemButton
                    sx={{
                      backgroundColor: '#EFEFEF',
                      width: '100%',
                      paddingLeft: '32px',
                    }}
                    onClick={() => history.push({ pathname: "/mes-solutions" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'MES SOLUTIONS'} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      backgroundColor: '#EFEFEF',
                      width: '100%',
                      paddingLeft: '32px',
                    }}
                    onClick={() => history.push({ pathname: "/mes-produits" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'MES PRODUITS ET OUTILS'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'chantier-item'} disablePadding>
                  <ListItemButton
                    onClick={() => history.push({ pathname: "/chantiers" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'MES CHANTIERS'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'contact-item'} disablePadding>
                  <ListItemButton
                    onClick={() => history.push({ pathname: "/contact" })}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'CONTACT'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <Box sx={{
                  height: '32px',
                }} />
                <Divider />

                <ListItem key={'compte-edit-item'} disablePadding>
                  <ListItemButton
                    onClick={editMyProfile}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'ÉDITER MON PROFIL'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'compte-delete-item'} disablePadding>
                  <ListItemButton
                    onClick={goToDeleteUserDataPage}
                  >
                    <ListItemText
                      sx={{
                        color: '#686868',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'SUPPRIMER MON PROFIL'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem key={'compte-logout-item'} disablePadding>
                  <ListItemButton
                    sx={{
                      backgroundColor: '#005284'
                    }}
                    onClick={logoutUser}
                  >
                    <ListItemText
                      sx={{
                        color: 'white',
                        '& span': {
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }
                      }}
                      primary={'DÉCONNEXION'} />
                  </ListItemButton>
                </ListItem>
                <Divider />

              </List>
            </Box>
          </Drawer>
        </Grid>


        <Popover
          id="simple-menu"
          anchorEl={anchorElFav}
          keepMounted
          open={Boolean(anchorElFav)}
          onClose={handleClose}
          // getContentAnchorEl={null}
          anchorPosition={{ left: 0 }}
          anchorOrigin={{ vertical: "bottom", horizontal: 'center' }}
          transformOrigin={{ vertical: "top", horizontal: 'center' }}

          sx={{
            "&& .MuiPaper-root": {
              marginTop: '-4px',
              borderRadius: '0 0 4px 4px',
            },
            "&& .MuiMenuItem-root": {
              color: '#6B6B6B',
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontWeight: 500,
              marginX: '19px'
            }
          }}
        >
          <MenuItem
            // style={{fontFamily: "Montserrat"}}
            onClick={() => navigation("solutions")}
          >
            Mes Solutions
          </MenuItem>
          <MenuItem
            // style={{fontFamily: "Montserrat"}}
            onClick={() => navigation("produits")}
          >
            Mes Produits
          </MenuItem>
        </Popover>


        <Popover
          id="simple-profile-menu"
          anchorEl={anchorElProfile}
          keepMounted
          open={Boolean(anchorElProfile)}
          onClose={handleClose}
          // getContentAnchorEl={null}
          anchorPosition={{ left: 0 }}
          anchorOrigin={{ vertical: "bottom", horizontal: 'right' }}
          transformOrigin={{ vertical: "top", horizontal: 'right' }}

          sx={{
            "&& .MuiPaper-root": {
              marginTop: '4px',
              // borderRadius: '0 0 4px 4px',
            },
            "&& .MuiMenuItem-root": {
              color: '#6B6B6B',
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontWeight: 500,
              marginX: '19px'
            }
          }}
        >
          <MenuItem onClick={editMyProfile}>Editer mon profil</MenuItem>
          <MenuItem onClick={goToDeleteUserDataPage}>
            Supprimer mon compte
          </MenuItem>
          <MenuItem onClick={logoutUser}>Déconnexion</MenuItem>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
