import React, {useState} from "react";
import emptyItem from "../../images/svg/emptyItem.svg";
import styles from "./productCard.style";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";

const ProductCard = (props) => {
  const [buttonIsHovered, setButtonHovered] = useState(false);
  const {data, productIsAvailable, removeProduct, index} = props;
  return (
    <Paper elevation={buttonIsHovered ? 10 : 3} sx={{
      minWidth: "300px",
      maxWidth: {mobile: '100%', desktop: "300px"},
      minHeight: "260px",
      maxHeight: "260px",
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between",
      flexDirection: "column",
      borderRadius: "3%",
      fontFamily: "Montserrat",
      marginTop: "20px",
      marginBottom: "15px",
    }}>
      <Grid style={styles.mainGrid}>
        <Grid style={{width: "inherit"}}>
          <Button
            fullWidth={true}
            style={styles.buttonBack}
            onClick={() => removeProduct(data, index)}
          >
            <CloseIcon
              htmlColor="white "
              size="small"
              style={styles.closeIcon}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid style={styles.imageGrid}>
        <img
          style={styles.image}
          src={data.productImage !== null ? data.productImage : emptyItem}
          alt="product image"
          onClick={() => productIsAvailable(data)}
        />
      </Grid>
      <Typography
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={styles.nameType}
        onClick={() => productIsAvailable(data)}
      >
        {data.productName}
      </Typography>
      <Typography
        style={styles.emptytype}
        onClick={() => console.log(data)}
      ></Typography>
    </Paper>
  );
};

export default ProductCard;