import { FormControl, Grid, IconButton, Typography, Box, Button } from "@mui/material";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Close from "./../../images/svg/Close.svg";
import PopUpStyle from "./PopUpEditChantier.style";

const PopUpEditChantier = (props) => {
  const classes = PopUpStyle();
  const {
    close,
    idUser,
    getChantiersByUserId,
    editMonChantier,
    chantierInfos,
  } = props;

  //FORM MANAGEMENT
  const { handleSubmit } = useForm();
  const [isClicked, setIsClicked] = useState(false);

  const date = Moment(new Date()).format("YYYY-MM-DD");
  const [minEndDate, setMinEndDate] = useState(
    chantierInfos.beginDate.split("-").reverse().join("-")
  );

  const [EndDate, setEndDate] = useState(
    chantierInfos.endDate.split("-").reverse().join("-")
  );

  const [chantier, setChantier] = useState(chantierInfos);
  const onSubmit = () => {
    const params = {
      name: chantier.name,
      clientName: chantier.clientName,
      location: chantier.location,
      beginDate:
        chantier.beginDate === ""
          ? Moment(minEndDate).format("DD-MM-YYYY")
          : chantier.beginDate,
      endDate:
        EndDate === ""
          ? Moment(minEndDate).format("DD-MM-YYYY")
          : Moment(EndDate).format("DD-MM-YYYY"),
      description: chantier.description,
      idUser: idUser,
      product: chantierInfos.product,
    };

    setIsClicked(true);

    editMonChantier(params);
    close();
  };
  //ACCOUNT INFO

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val =
      nam === "beginDate"
        ? Moment(event.target.value).format("DD-MM-YYYY")
        : event.target.value;

    if (nam === "beginDate") {
      setMinEndDate(event.target.value);
    } else if (nam === "endDate") {
      setMinEndDate(event.target.value);
      setEndDate(event.target.value);
    }
    setChantier((chantier) => ({
      ...chantier,
      [nam]: val,
    }));
  };

  useEffect(() => {
  }, []);

  const autoGrow = (e) => {
    e.target.style.height = "5px";
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  return (
    <FormControl
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        position: 'relative'
      }}
    >
      <Grid
        container
        style={{
          //position: "static",
          width: "auto",
          fontWeight: "bold",
          color: "black",
        }}
      >
        {/* TITLE */}
        <Grid container >

          <Typography
            sx={{
              maxWidth: "510px",
              width: '70%',

              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "bold",
              //marginTop: "35px",
              textWrap: 'wrap',
            }}
          >Modification du chantier</Typography>

        </Grid>

        {/* BODY */}
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', desktop: 'row' }
          }}
        >
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            //xs={2}
            >
              <label>
                Nom&nbsp;<span className={classes.colorRed}>*</span>
              </label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="149"
                name="name"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
                required
                defaultValue={chantierInfos.name}
              ></input>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              item
              className={classes.label}
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            //xs={2}
            >
              <label>Descriptif</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            //xs={8}
            >
              <textarea
                onInput={(e) => autoGrow(e)}
                maxlength="255"
                className={classes.textareaInput}
                name="description"
                onChange={myChangeHandler}
                defaultValue={chantierInfos.description}
              ></textarea>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            //xs={2}
            >
              <label>Nom du client</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            // xs={3}
            >
              <input
                maxlength="149"
                name="clientName"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
                defaultValue={chantierInfos.clientName}
              ></input>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            // xs={2}
            >
              <label>
                Dates&nbsp;<span className={classes.colorRed}>*</span>
              </label>
            </Grid>
            <Grid
              container
              //  xs={8}
              sx={{
                display: "flex",
                flexDirection: { mobile: 'column', desktop: 'row' }
              }}
            >
              <Grid
                container
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span
                  className={classes.dateSpan}
                >du</span>
                <input
                  className={classes.dateInput}
                  type="date"
                  name="beginDate"
                  onChange={myChangeHandler}
                  required
                  defaultValue={chantierInfos.beginDate
                    .split("-")
                    .reverse()
                    .join("-")}
                ></input>
              </Grid>
              <Grid
                container
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span
                  className={classes.dateSpan}
                >au</span>
                <input
                  className={classes.dateInput}
                  type="date"
                  name="endDate"
                  min={minEndDate}
                  onChange={myChangeHandler}
                  required
                  defaultValue={EndDate}
                ></input>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Lieu</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            //xs={3}
            >
              <input
                maxlength="149"
                defaultValue={chantierInfos.location}
                name="location"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
              ></input>
            </Grid>
          </Grid>
        </Grid>

        {/* BUTTONS */}
        <Grid
          container
          sx={{
            flexDirection: { mobile: 'column', desktop: "row" },
            justifyContent: { mobile: 'center', desktop: "center" },
            //marginBottom: "1vh",
            marginTop: "2vh",
          }}
        >
          <Button
            rounded
            color={'inherit'}
            variant={'contained'}
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              //marginTop: "30px",
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginBottom: { mobile: '16px', desktop: 0 },
            }}
            onClick={() => close()}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Annuler
            </Typography>
          </Button>

          <Button
            rounded
            disabled={isClicked}
            variant={'contained'}
            type="submit"
            sx={{
              alignSelf: { mobile: 'center', desktop: 'end' },
              //marginTop: "30px",
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              //marginBottom: { mobile: 0, desktop: '80px' },
              marginLeft: { mobile: 0, desktop: '32px' },
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Enregister
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          right: '-24px',
        }}
      >
        <IconButton onClick={() => close()}
        >
          <img
            className={classes.icon}
            alt="close button"
            src={Close}
          />
        </IconButton>
      </Box>
    </FormControl >
  );
};

export default PopUpEditChantier;
