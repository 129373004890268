import { makeStyles } from "@mui/styles";

const PopUpStyle = makeStyles((theme) => ({
  container: {
    marginTop: "2vh",
    marginBottom: "12px",
  },
  label: {
    marginTop: "16px",
    marginBottom: '8px'
  },
  colorRed: {
    color: "red",
  },
  textInput: {
    '-webkit-appearance': 'none',
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    border: "none",
    maxWidth: "445px",
    width: '100%',
    fontSize: "15px",
    padding: "12px 20px",
    //marginLeft: "41px",
    fontFamily: "Montserrat",
    height: "30px",
    marginTop: '8px',
  },
  textareaInput: {
    '-webkit-appearance': 'none',
    maxWidth: "487px",
    width: '100%',
    minHeight: "50px",
    padding: "12px 20px",
    boxSizing: "border-box",

    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    border: "none",
    fontSize: "15px",
    //marginLeft: "41px",
    fontFamily: "Montserrat",
    marginTop: '8px',
    resize: "vertical",
  },
  dateInput: {
    fontSize: "15px",
    padding: "12px 20px",
    //marginLeft: "41px",
    fontFamily: "Montserrat",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    border: "none",
    maxWidth: "254px",
    flexGrow: 1,
    marginTop: '8px',
  },
  dateSpan: {
    fontSize: "15px",
    padding: "12px 20px",
    fontStyle: 'italic',
  },

}));

export default PopUpStyle;
