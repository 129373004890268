import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Mail } from "../../API/APIRequest/Categories";
import configFile from "../../configFile.json";
import mail from "../../images/mail.png";
import phone from "../../images/phone.png";
import photoContactClient from "../../images/svg/photoContactClient.svg";
import photoContactTechnique from "../../images/svg/photoContactTechnique.svg";
import notifyError from "../../Shared/notifyError";
import notifySuccess from "../../Shared/notifySuccess";
import "./Contact.css";
import ContactStyle from "./Contact.style";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import { Box, Button, Divider, FormControl, Grid, Typography, Select, MenuItem } from "@mui/material";

const Contact = ({ appInsights }) => {
  let history = useHistory();
  const classes = ContactStyle();
  const { handleSubmit } = useForm();

  const [account, setAccount] = useState("");
  useEffect(() => {
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setAccount(accountInfoParsed.account.idToken);
  }, []);
  const onSubmit = (event) => {
    setIsClicked(true);
    Mail(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess("Mail envoyé avec succès");
            setTimeout(() => {
              history.push({ pathname: "/" });
            }, 4000);
          } else {
            notifyError(response.data.error.message);
          }
        },
        onError: (error) => console.log("error"),
      },
      Client
    );
  };

  let Client = {
    type: "Pose",
    departement: account.extension_Departement,
    lastName: account.family_name,
    firstName: account.given_name,
    phoneNumber: account.extension_Phone,
    mailAdress: new String(account.emails),
    description: "",
    destinataire: configFile.mailContact,
    isCheckedNewsletter: account.extension_AcceptReciveMapeiNew
      ? Boolean(account.extension_AcceptReciveMapeiNew)
      : false,
  };

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    Client[nam] = val;
  };

  const newsLettersHandler = () => {
    Client["isCheckedNewsletter"] = !Client.isCheckedNewsletter;
  };

  const [isClicked, setIsClicked] = useState(false);

  return (
    <Grid
      container
      justifyContent={"center"}
      style={{
        display: 'flex',
        position: 'relative',
        minHeight: '80vh'
      }}>
      <ToastContainer />
      <Grid
        container
      >
        {/* TOP CONTAINER */}

        <Grid
          container
          className={classes.topContainer}
        >
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}>
            <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
              <Breadcrumbs variantColor={'white'} />
              <BackButton />
            </Box>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: 'inherit',
              padding: '0 24px',
            }}
          >
            <Grid
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
              }}
              marginX={"auto"}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '32px',
                    fontWeight: 700,
                    letterSpacing: '0.356px',
                  }}
                >Contactez-nous</Typography>
                <Divider
                  variant="middle"
                  component={'li'}
                  sx={{
                    display: { mobile: 'flex', desktop: 'flex' },
                    margin: '36px 0',
                    height: '1px',
                    backgroundColor: 'white'
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { mobile: 'column', desktop: 'row' },
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                  }}
                >
                  Une question ?
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                  }}
                >
                  Un besoin d’accompagnement supplémentaire pour mener à bien votre chantier ?
                </Typography>
              </Grid>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                }}
              >
                N'hésitez pas à nous contacter.
              </Typography>
            </Grid>
          </Grid>
        </Grid>


        {/* BOTTOM CONTAINER  */}
        <Grid
          container
          sx={{
            width: '100vw',
          }}
        >
          <Grid
            container
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "1400px",
              justifyContent: "center",
              padding: { mobile: '0 24px', desktop: 0 },
            }}
          >
            {/* Form Grid */}
            <Grid
              item
              mobile={12}
              desktop={6}
              sx={{
                paddingTop: "50px",
                display: 'flex',
              }}
            >
              <FormControl
                variant="standard"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignSelf: 'start',
                    alignItems: "center",
                    justifyContent: 'start',
                    paddingLeft: '2.5%',
                  }}
                >
                  <img
                    src={mail}
                    alt='icone'
                    style={{
                      width: "60px",
                      height: "60px"
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#0079C2",
                      fontFamily: 'Montserrat',
                      fontSize: '24px',
                      fontWeight: 700,
                    }}
                  >Ecrivez-nous</Typography>
                </Grid>
                <p
                  className={classes.formPar}
                >
                  Décrivez-nous votre demande, un responsable de secteur vous
                  contacte au plus vite.
                </p>
                <input
                  name="departement"
                  className={classes.input}
                  onChange={myChangeHandler}
                  placeholder={'* n° - département *'}
                  defaultValue={account.extension_Departement}
                // disabled
                ></input>
                <input
                  className={classes.input}
                  onChange={myChangeHandler}
                  type="text"
                  name="lastName"
                  placeholder={'* nom de famille *'}
                  defaultValue={account.family_name}
                // disabled
                ></input>
                <input
                  className={classes.input}
                  onChange={myChangeHandler}
                  type="text"
                  name="firstName"
                  placeholder={'* prénom *'}
                  defaultValue={account.given_name}
                // disabled
                ></input>
                <input
                  className={classes.input}
                  onChange={myChangeHandler}
                  type="tel"
                  name="phoneNumber"
                  placeholder={'* n° de téléphone *'}
                  defaultValue={account.extension_Phone}
                // disabled
                ></input>
                <input
                  className={classes.input}
                  onChange={myChangeHandler}
                  type="email"
                  name="mailAdress"
                  placeholder={'* email *'}
                  defaultValue={account.emails}
                // disabled
                ></input>




                <Select
                  //rounded
                  //required
                  defaultValue={0}
                  required
                  name="type"
                  //className={classes.select}
                  onChange={myChangeHandler}
                  sx={{
                    //width: { mobile: '100%', desktop: '90%' },

                    maxWidth: "453px",

                    width: '95%',
                    marginBottom: "55px",
                    border: "0",
                    outline: "0",
                    // background: "transparent",
                    borderBottom: "1px solid #0079C2",
                    fontSize: "large",
                    color: "#0079C2",
                    fontFamily: "Montserrat",
                    padding: "10px",
                    background: "#F8F8F8",
                    height: '44px',
                    fontSize: 'large',
                    color: '#0079C2 !important',
                    fontFamily: 'Montserrat',
                    border: 'none',
                    borderRadius: 0,
                    //borderColor: '#0079C2 !important',
                    //fontWeight: 'bold',
                    //borderRadius: '50px !important',
                    //padding: '8px 22px',
                    //maxHeight: '40px',
                    '& svg': {
                      fill: "#0079C2",
                    },
                    '& .MuiMenuItem-root': {
                      whiteSpace: 'normal',
                      textWrap: 'balance',
                    },
                  }}
                >

                  <MenuItem value={0}>
                    Pose
                  </MenuItem>
                  <MenuItem value={1}>
                    Réparation
                  </MenuItem>
                  <MenuItem value={2} >
                    Autres
                  </MenuItem>
                </Select>


                {/* <select
                  className={classes.select}
                  defaultValue="Type de la demande"
                  required
                  name="type"
                  onChange={myChangeHandler}
                >
                  <option>Pose</option>
                  <option>Réparation</option>
                  <option>Autres</option>
                </select> */}






                <Grid
                  sx={{
                    maxWidth: "450px",
                    width: '95%',
                  }}
                >
                  <textarea
                    style={{
                      // maxWidth: "450px",
                      width: '95%',
                      marginBottom: "24px",
                      border: "0",
                      outline: "0",
                      borderBottom: "1px solid #0079C2",
                      fontSize: "18px",
                      // minHeight: "100px",
                      height: "100px",
                      resize: "vertical",
                      color: "#0079C2",
                      fontFamily: "Montserrat",
                      padding: "10px",
                      background: "#F8F8F8",
                    }}
                    placeholder="Description de la demande"
                    onChange={myChangeHandler}
                    type="text"
                    name="description"
                    required
                    multiline="true"
                  />
                </Grid>
                <Button
                  rounded
                  disabled={isClicked}
                  variant={'contained'}
                  type="submit"
                  sx={{
                    alignSelf: 'end',
                    marginTop: "30px",
                    borderRadius: "25px",
                    width: "223px",
                    height: "40px",
                    marginBottom: { mobile: 0, desktop: '80px' },
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "19px",
                    }}
                  >
                    Envoyer
                  </Typography>
                </Button>
              </FormControl>
            </Grid>


            {/* Contact Grid */}
            <Grid
              item
              mobile={12}
              desktop={6}
              sx={{
                padding: { mobile: '2.5% 2.5% 50px 2.5%', desktop: '50px 0 0 40px' },
                alignSelf: 'auto',
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <img
                  src={phone}
                  style={{
                    width: "60px",
                    height: "60px"
                  }}
                />

                <Typography
                  sx={{
                    color: "#0079C2",
                    fontFamily: 'Montserrat',
                    fontSize: '24px',
                    fontWeight: 700,
                  }}
                >Appelez-nous</Typography>
              </Grid>
              <p
                className={classes.contactPar}
              >
                A votre écoute au téléphone pour toutes vos demandes.
              </p>

              <Grid
                container
                sx={{
                  marginBottom: "30px",
                  flexDirection: 'row',
                  '& img': {
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }
                }}
              >
                <Grid
                  mobile={4}
                >
                  <img
                    alt="smiling man"
                    src={photoContactTechnique}
                  ></img>
                </Grid>
                <Grid
                  mobile={6}
                  className={classes.rightGridContact}
                >
                  <p
                    className={classes.nomPar}
                  >SERVICE TECHNIQUE</p>
                  <p>05.61.35.48.59</p>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  marginBottom: "30px",
                  '& img': {
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }
                }}
              >
                <Grid mobile={4}>
                  <img
                    width={'auto'}
                    alt="smiling man"
                    src={photoContactClient}></img>
                </Grid>
                <Grid
                  mobile={6}
                  className={classes.rightGridContact}
                >
                  <p
                    className={classes.nomPar}
                  >SERVICE CLIENT</p>
                  <p>05.61.35.85.85</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
