import React, { useEffect, useState } from "react";
import { Grid, IconButton, Button, Box, Typography, FormControl } from "@mui/material";
import PopUpStyle from "./PopUpMailContact.style";
import { useForm } from "react-hook-form";
import Close from "./../../images/svg/Close.svg";
import { MailSolution } from "./../../API/APIRequest/Categories";
import mailIcon from "./../../images/svg/iconMail.png";
import notifySuccess from "./../../Shared/notifySuccess";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const PopUpMailContact = (props) => {
  const classes = PopUpStyle();
  const { close, destinataire, isTechnique } = props;

  //FORM MANAGEMENT
  const { handleSubmit } = useForm();
  const [isClicked, setIsClicked] = useState(false);

  const onSubmit = () => {
    setIsClicked(true);
    const params = {
      name: name,
      email: account.emails[0],
      phoneNumber: phoneNumber,
      solution: solution,
      message: mail.mailObject.message,
      destinataire: destinataire,
      department: account.extension_Departement,
      job: job,
      isCheckedNewsletter: account.extension_AcceptReciveMapeiNew
        ? Boolean(account.extension_AcceptReciveMapeiNew)
        : false,
    };

    MailSolution(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess("Mail envoyé avec succès");
            setTimeout(() => {
              close();
            }, 4000);
          } else {
            console.log(response.data.error.message);
          }
        },
        onError: (error) => console.log("error"),
      },
      params
    );
  };

  //ACCOUNT INFO
  const [account, setAccount] = useState([]);
  const [solution, setSolution] = useState();
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [job, setJob] = useState();
  const [mail, setMail] = useState({
    mailObject: {
      phoneNumber: "08 36 65 65 65",
      solution: "",
      message: "",
    },
  });

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    setMail((mail) => ({
      mailObject: {
        ...mail.mailObject,
        [nam]: val,
      },
    }));
  };

  useEffect(() => {
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    console.log(accountInfoParsed);
    setAccount(accountInfoParsed.account.idToken);
    setName(
      accountInfoParsed.account.idToken.given_name +
      " " +
      accountInfoParsed.account.idToken.family_name
    );
    setPhoneNumber(accountInfoParsed.account.idTokenClaims.extension_Phone);
    setJob(accountInfoParsed.account.idTokenClaims.extension_Job);

    var solutionInfo = localStorage.getItem("solution");
    var solutionInfoParsed = JSON.parse(solutionInfo);
    console.log("account dans la pop up", solutionInfoParsed);
    setSolution(solutionInfoParsed);
  }, []);

  const autoGrow = (e) => {
    e.target.style.height = "5px";
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  return (
    <FormControl
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        position: 'relative'
      }}
    >
      <Grid
        container
        style={{
          //position: "static",
          width: "auto",
          fontWeight: "bold",
          color: "black",
        }}
      >
        {/* TITLE */}
        <Grid container >

          <Typography
            sx={{
              maxWidth: "510px",
              width: '70%',

              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "bold",
              //marginTop: "35px",
              textWrap: 'wrap',
            }}
          >
            {isTechnique ? ('Contact : Technique') : ('Contact : Commercial')}
          </Typography>

        </Grid>

        {/* BODY */}
        <Grid
          container
          //className={classes.bodyContainer}
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', desktop: 'row' }
          }}
        >
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              item
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>
                Nom
              </label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                maxlength="149"
                name="name"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
                defaultValue={name}
                required
              ></input>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            //xs={2}
            >
              <label>Téléphone</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            // xs={3}
            >
              <input
                name="phoneNumber"
                type="text"
                className={classes.textInput}
                defaultValue={phoneNumber}
                required
              ></input>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              className={classes.label}
              items
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Email</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <input
                name="mail"
                type="text"
                className={classes.textInput}
                onChange={myChangeHandler}
                defaultValue={account.emails}
                required
              ></input>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              item
              className={classes.label}
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Solution</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <textarea
                maxlength="255"
                className={classes.textareaInput}
                style={{ height: 'content' }}
                onChange={myChangeHandler}
                name="solution"
                defaultValue={solution}
                disabled
              ></textarea>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.container}
            sx={{
              display: 'flex',
              flexDirection: { mobile: 'column', desktop: 'row' }
            }}
          >
            <Grid
              item
              className={classes.label}
              sx={{
                marginLeft: { mobile: 0, desktop: '70px' }
              }}
            >
              <label>Message</label>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: 'center'
              }}
            >
              <textarea
                onInput={(e) => autoGrow(e)}
                maxlength="255"
                className={classes.textareaInput}
                name="message"
                onChange={myChangeHandler}
                required
              ></textarea>
            </Grid>
          </Grid>



        </Grid>

        {/* BUTTONS */}
        <Grid
          container
          sx={{
            flexDirection: "row",
            justifyContent: { mobile: 'center', desktop: "flex-end" },
            //marginBottom: "1vh",
            marginTop: "2vh",
          }}
        >
          <Button
            rounded
            color={'inherit'}
            variant={'contained'}
            sx={{
              alignSelf: 'end',
              //marginTop: "30px",
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              //marginBottom: { mobile: 0, desktop: '80px' },
            }}
            onClick={() => close()}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Annuler
            </Typography>
          </Button>

          <Button
            rounded
            disabled={isClicked}
            variant={'contained'}
            type="submit"
            sx={{
              alignSelf: 'end',
              borderRadius: "25px",
              maxWidth: "223px",
              width: '40%',
              height: "40px",
              marginLeft: '32px',
              '& .MuiButton-startIcon': {
                display: {
                  mobile: 'none', desktop: 'flex',
                }
              },
            }}
            onClick={handleSubmit(onSubmit)}

            startIcon={<MailOutlineIcon />}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "19px",
              }}
            >
              Envoyer
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          right: '-24px',
        }}
      >
        <IconButton onClick={() => close()}
        >
          <img
            className={classes.icon}
            alt="close button"
            src={Close}
          />
        </IconButton>
      </Box>
    </FormControl >
  );
};

export default PopUpMailContact;
