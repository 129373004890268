import { Box, Button, CircularProgress, FormControl, Grid, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styles from './DeleteUserDataContainer.styles'
import config from '../../configFile.json'
import notifyError from '../../Shared/notifyError'
import notifySuccess from '../../Shared/notifySuccess'
import axios from 'axios'
import { signInAuthProvider } from '../../authProvider'


const DeleteUserDataContainer = () => {
  const history = useHistory();
  const url = config.webapiUrl;
  const [isLoading, setIsLoading] = useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [count, setCount] = useState(5);
  const countRef = useRef(count);
  countRef.current = count;

  const { handleSubmit } = useForm();
  const onSubmit = (event) => {
    setIsLoading(true)
    var accountInfo = localStorage.getItem('accountInfo')
    var tokeninfo = JSON.parse(accountInfo)
    const token = tokeninfo

    const user = {
      id: tokeninfo.account.idToken.oid,
      mail: tokeninfo.account.idToken.emails[0],
      isCheckedNewsletter: tokeninfo.account.idToken.extension_AcceptReciveMapeiNew
        ? Boolean(tokeninfo.account.idToken.extension_AcceptReciveMapeiNew)
        : false,
      destinataire: config.mailContact,
    }

    axios
      .delete(url + 'user/data', {
        data: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.jwtIdToken}`,
        },
      })
      .then((result) => {
        onSuccess(result);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const onSuccess = (response) => {
    if (response.data.isSuccess === true) {
      setIsLoading(false);
      notifySuccess('Votre requête a été prise en compte!');
      setDisplaySuccessMessage(true);
    } else {
      onError(response.data.error);
    }
  };
  const onError = (error) => {
    console.log(error);
    setIsLoading(false);
    notifyError(error.message);
  };

  const redirectToHomePage = () => {
    history.push('/');
  };

  const DeleteUserDataFrom = () => {
    return (
      <FormControl
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '1400px',
          margin: 'auto'
        }}
        id='deleteUserForm'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '32px',
              fontWeight: 600,
            }}
          >Suppression de vos données</Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              marginTop: '16px',
              '& u': {
                fontFamily: 'Montserrat',
                fontSize: '16px',
              }
            }}
          >
            Êtes-vous sûr(e) de vouloir supprimer vos données ?&nbsp;
            <u>Cette action est irréversible.</u>
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              marginTop: '16px',
            }}
          >
            En cliquant sur Oui, vous confirmez la suppression de
            votre compte sur ce site ainsi que vos données de
            contacts, vos chantiers, vos solutions et produits
            favoris.
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: 'row',
            marginTop: '48px'
          }}
        >
          <Button
            type={'submit'}
            color="primary"
            variant="contained"
            sx={{
              borderRadius: "30px",
              width: { mobile: "35vw", desktop: '12em' },
              backgroundColor: "#F50157",
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "16px",
            }}
          >
            Oui
          </Button>
          <Button
            onClick={() => redirectToHomePage()}
            color="secondary"
            variant="contained"
            fullWidth
            sx={{
              marginLeft: '24px',
              borderRadius: "30px",
              width: { mobile: "35vw", desktop: '12em' },
              backgroundColor: "#0079C2",
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "16px",
            }}
          >
            Non
          </Button>
        </Grid>
      </FormControl>
    );
  };

  const DisplaySucessMessageAndClearCache = () => {
    if (countRef.current > 0) {
      setTimeout(() => {
        setCount(countRef.current - 1);
      }, 1000);
    }

    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie =
        'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      signInAuthProvider.logout();
    }, count * 1000);

    return (
      <Grid item style={styles.successMessage}>
        <p>Votre demande a bien été prise en compte!</p>
        <p>
          Votre cache va être effacé et vous serez redirigé vers la
          page de connexion dans {count}
        </p>
      </Grid>
    );
  };

  return (
    <Box mt={10} mb={10}>
      <Grid container spacing={2} justify='center' alignItems='center'>
        <Grid item>
          {isLoading ? (
            <CircularProgress />
          ) : displaySuccessMessage ? (
            <DisplaySucessMessageAndClearCache />
          ) : (
            <DeleteUserDataFrom />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeleteUserDataContainer;
