import React, { useState } from "react";
//AddProductManagement
import { ToastContainer } from "react-toastify";
import { addProductToChantier, getChantiersByUserId, } from "./../../API/APIRequest/Chantiers/Chantiers";
import ChantierIconAdd from "./../../images/svg/ChantierIconAdd.svg";
import notifyError from "./../../Shared/notifyError";
import notifySuccess from "./../../Shared/notifySuccess";
import "./dropDown.css";
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "31px",
    fontFamily: "Montserrat",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  button: {
    color: "white",
    fontFamily: "Montserrat",
  },
  listeItem: {
    width: "330px",
  },
  itemSpan: {
    color: "#0079C2",
  },
}));

const DropDownChantierInProductDetail = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [listOfChantier, setListOfChantier] = useState([]);
  const [idUser, setIdUser] = useState("");

  const { setOpenPopUp, productInfos } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleOpenPopUp = () => {
    setOpen(false);
    setOpenPopUp(true);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const addProductToChantierFunc = (data) => {
    let params = [
      {
        idChantier: data.id,
        idProduct: productInfos.id,
        productName: productInfos.name,
        productImage: productInfos.url || '',
      },
    ];
    addProductToChantier(params, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          notifySuccess("Produits ajoutés à votre chantier");
        } else {
          notifyError("Erreur lors de l'ajouts du produit à votre chantier");
        }
      },
      onError: (error) => {
        notifyError("Erreur lors de l'ajouts des produits");
      },
    });
    handleToggle();
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);
    getChantiersByUserId(accountInfoParsed.account.accountIdentifier, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setListOfChantier(response.data.data);
        }
      },
      onError: (error) => {
        notifyError("Erreur lors de l'affectation d'un produit au chantier");
      },
    });

  }, [open]);

  return (
    <Box>
      <ToastContainer />
      <Box>
        <Button
          variant="outlined"
          color={"primary"}
          style={{
            borderRadius: "25px",
            fontFamily: "Montserrat",
            fontSize: "19px",
            fontWeight: "bold",
            padding: "8px 16px 8px 22px",
            height: '40px',
            display: "flex",
            width: 'fit-content',
          }}
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
        >
          <img alt="add chantier button" style={{ marginRight: '10px' }} src={ChantierIconAdd} />
          <Typography
            style={{ fontFamily: "Montserrat" }}
          >
            Ajouter à un chantier
          </Typography>
          <ExpandMoreIcon large style={{
            marginLeft: '30px',
          }} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 10 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              //anchorOrigin={{ vertical: "bottom", horizontal: 'center' }}
              //transformOrigin={{ vertical: "top", horizontal: 'center' }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper
                className={classes.listeItem}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleOpenPopUp}>
                      <span className={classes.itemSpan}>&nbsp;&nbsp;+&nbsp;</span>
                      Créer un nouveau chantier
                    </MenuItem>
                    {listOfChantier.map((item, index) => {
                      return (
                        <MenuItem
                          key={item.id + index}
                          onClick={() => addProductToChantierFunc(item)}
                        >
                          &nbsp;&nbsp;{item.name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

export default DropDownChantierInProductDetail;
