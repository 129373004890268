//GET USER DEPARTMENT AND FORMAT IT
const GetFormattedDepartment = () => {
  //GET ACCOUNT INFO AND PARSE IT IN ORDER TO BE READ AS A JSON
  const accountInfo = localStorage.getItem("accountInfo");
  const accountInfoParsed = JSON.parse(accountInfo);

  //GET THE DEPARTMENT INFORMATION
  const departmentInfo =
    accountInfoParsed.account.idToken.extension_Departement;

  //KEEP ONLY NUMBER FROM STRING...
  const formattedDepartment = departmentInfo.replace(/\D/g, "");

  //... AND PARSE STRING TO INT
  const department = parseInt(formattedDepartment);

  return department;
};

export { GetFormattedDepartment };
