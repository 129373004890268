const styles = {
  upperGrid: {
    position: 'relative',
    backgroundColor: "#F9F9F9",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  gridMarged: {
    marginBottom: "60px",
  },
  retourButton: {
    fontSize: "medium",
  },
  gridName: {
    color: "#0079C2",
    fontSize: "38px",
    fontFamily: "Montserrat",
    marginBottom: "18px",
    fontWeight: "bold",
  },

  textfield: {
    minHeight: "-webkit-fill-available",
    // width: "474px",
    fontSize: "16px",
    border: "none",
    fontFamily: "Montserrat",
    marginTop: "74px",
    color: "black",
    fontWeight: "bold",
    lineHeight: "26px",
    backgroundColor: "#F9F9F9",
    margin: "0",
    resize: "none",
  },
  gridButtonHolder: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    // width: "329px",
    // marginLeft: "68px",
    // marginTop: "150px",
  },
  button: {
    backgroundColor: "#0079C2",
    color: "white",
    height: "43px",
    marginBottom: "42px",
    display: "flex",
    fontFamily: "Montserrat",

    fontSize: "18px",
    fontWeight: "bold",
  },
  typoTitle: {
    marginBottom: "30px",
    fontsize: "15px",
    fontFamily: "Montserrat",

    display: "flex",
    justifyContent: "flex-start",
  },
  typoData: {
    color: "#4A4A4A",
    fontsize: "15px",
    fontFamily: "Montserrat",
    marginLeft: "33px",
  },

};

export default styles;
