import { Button, Dialog, DialogContent, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import PopUpEditFavorite from "../../pages/FavoritesSolutions/popUpEditFavorite";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog/ConfirmDeleteDialog";
import CloseIcon from "@mui/icons-material/Close";

const FavoritesSolutionsCard = (props) => {
  const { data, editFavs, removeFav, favIsAvailable } = props;
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [buttonIsHovered, setButtonHovered] = useState(false);
  const handleClose = () => {
    setOpenEditMenu(false);
  };
  return (
    <>
      <Paper
        elevation={buttonIsHovered ? 10 : 3}
        style={{
          minWidth: "353px",
          maxWidth: "353px",
          minHeight: "420px",
          maxHeight: "420px",
          display: "flex",
          marginTop: "2vh",
          marginBottom: "2vh",
          flexDirection: "column",
          borderRadius: "11.1px",
          fontFamily: "Montserrat",
          fontSize: "15px",
        }}
      >
        <Grid style={{
          display: "flex",
          justifyContent: "end",
        }}> <Button
          fullWidth={true}
          style={{
            backgroundColor: '#F50157',
            minWidth: '30px',
            maxWidth: '30px',
            minHeight: '30px',
            maxHeight: '30px',
            borderRadius: '0px 11.1px 0px 0px',
          }}
          onClick={() => setOpenConfirmDelete(true)}
        >
            <CloseIcon
              htmlColor="white "
              size="small"
              style={{ width: '15px' }}
            />
          </Button></Grid>


        <Grid style={{
          padding: "32px 20px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          justifyContent: "space-between",
          lineHeight: "22.5px",
        }}>


          <Grid>

            <Typography
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              style={{
                color: "#0079C2",
                fontFamily: "Montserrat",
                fontSize: "15px",
                justifyContent: "center",
                textAlign: "center",
                fontWeight: 700,
                cursor: "pointer",
                marginBottom: "28px",
              }}
              onClick={() => favIsAvailable(data)}
            >
              {data.name}
            </Typography>
            {data.constructionTypeName === "Pose" ? (
              <Grid style={{
                marginRight: "10px",
              }}>
                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    Je pose
                  </Typography>

                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.coatingName}
                  </Typography>
                </Grid>


                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    sur
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.locationName}
                  </Typography>
                </Grid>

                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    dans
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.surfaceName}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    sur
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.number}m²
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    Je répare
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.coatingName}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    sur
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.locationName}
                  </Typography>
                </Grid>

                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    par
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.surfaceName}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      marginRight: "10px",
                    }}
                  >
                    sur
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {data.number}m²
                  </Typography>
                </Grid>
              </Grid>
            )}

          </Grid>

          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "5px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              style={{
                borderRadius: "30px",
                width: "12em",
                backgroundColor: "#0079C2",
                color: "white",
                fontFamily: "Montserrat",
                fontSize: "13px",
              }}
              onClick={() => setOpenEditMenu(true)}
            >
              Renommer
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <ConfirmDeleteDialog
        open={openConfirmDelete}
        removeFav={removeFav}
        data={data}
        close={() => setOpenConfirmDelete(false)}
      />
      <Dialog open={openEditMenu} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <PopUpEditFavorite
            placeHolder={data.name}
            data={data}
            editFavs={editFavs}
            close={() => setOpenEditMenu(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FavoritesSolutionsCard;
