const styles = {
  handler: {margin: "5%"},
  mainTitle: {
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "50px",
  },
  title: {fontWeight: "bold"},
  text: {marginBottom: "30px"},
  link: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    display: "flex",
  },
};

export default styles;
