import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { addProductToChantier, getChantiersByUserId, } from "./../../API/APIRequest/Chantiers/Chantiers";
import ChantierIconAdd from "./../../images/svg/ChantierIconAdd.svg";
import notifyError from "./../../Shared/notifyError";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import notifySuccess from "../../Shared/notifySuccess";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "31px",
    fontFamily: "Montserrat",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  button: {
    color: "white",
    fontFamily: "Montserrat",
  },
  listeItem: {
    width: "330px",
  },
  itemSpan: {
    color: "#0079C2",
  },
}));

const DropDownChantierInRecherche = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    setOpenPopUp,
    chantierIsClicked,
    setChantierIsClicked,
    produitsList,
  } = props;
  const [listOfChantier, setListOfChantier] = useState([]);
  const [idUser, setIdUser] = useState("");
  const [listOfProd, setListOfProd] = useState(produitsList);
  const [productToSend, setProductToSend] = useState([]);

  const initProduct = () => {
    let fullProduct = [];
    for (let i = 0; i < listOfProd.length; i++) {
      listOfProd[i].products.forEach((element) => {
        fullProduct.push({
          idChantier: "",
          idProduct: element.id,
          productName: element.name,
          productImage: element.url,
        });
      });
    }
    return setProductToSend(fullProduct);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOpenPopUp = () => {
    setOpen(false);
    setOpenPopUp(true);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const addProductToChantierFunc = (data) => {
    for (let i = 0; i < productToSend.length; i++) {
      productToSend[i].idChantier = data.id;
    }

    addProductToChantier(productToSend, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          notifySuccess("Produits ajoutés à votre chantier");
        }
      },

      onError: (error) => {
        notifyError("Erreur lors de l'ajouts des produits");
      },
    });
    handleToggle();
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);
    getChantiersByUserId(accountInfoParsed.account.accountIdentifier, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setListOfChantier(response.data.data);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
    initProduct();
    //Mapping Produit dans steps
  }, [open]);

  return (

    <Box>
      <ToastContainer />
      <Box>
        <Button
          variant="outlined"
          color={"primary"}
          style={{
            borderRadius: "25px",
            fontFamily: "Montserrat",
            fontSize: "19px",
            fontWeight: "bold",
            padding: "8px 16px 8px 22px",
            height: '40px',
            display: "flex",
            width: 'fit-content',
          }}
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
        >
          <img alt="add chantier button" style={{ marginRight: '10px' }} src={ChantierIconAdd} />
          <Typography
            style={{ fontFamily: "Montserrat" }}
          >
            Ajouter à un chantier
          </Typography>
          <ExpandMoreIcon large style={{
            marginLeft: '30px',
          }} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          //disablePortal
          style={{ zIndex: 10 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.listeItem}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleOpenPopUp}>
                      <span className={classes.itemSpan}>&nbsp;&nbsp;+&nbsp;</span>
                      Créer un nouveau chantier
                    </MenuItem>
                    {listOfChantier.map((item, index) => {
                      return (
                        <MenuItem
                          key={item.id + index}
                          onClick={() => addProductToChantierFunc(item)}
                        >
                          &nbsp;&nbsp;{item.name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

export default DropDownChantierInRecherche;
