import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  AddChantier,
  deleteChantier,
  editMyChantier,
  getChantierDetailsById,
} from "../../API/APIRequest/Chantiers/Chantiers";
import notifyDelete from "../../Shared/notifyDelete";
import notifyError from "../../Shared/notifyError";
import notifySuccess from "../../Shared/notifySuccess";
import styles from "./style";
import PopUpEditChantier from "./PopupEditChantier";
import ProductCard from "./ProductCard";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const DetailChantier = ({ appInsights }) => {
  let history = useHistory();
  const [detailChantier, setDetailChantier] = useState({});
  const [idUser, setIdUser] = useState();
  const [isLoading, setIsLoading] = useState("true");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [listOfProduct, setListOfProduct] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const handleClose = () => {
    setOpenPopUp(false);
  };
  //Delete Toast
  const DeleteToastMsg = ({ closeToast, itemDeleted }) => {
    //Get Data Delete
    let DataToRePost = itemDeleted;
    if (DataToRePost.chantierProduct === null) {
      DataToRePost.chantierProduct = [];
    }
    setTimeout(
      () =>
        history.push({
          pathname: `/chantiers/`,
        }),
      3000
    );
    return (
      <div style={{ width: "2000px" }}>
        L’élément a bien été supprimé
        <Button
          onClick={() => onCancelDelete(DataToRePost)}
          style={{ fontSize: 15, color: "#9ED6FF" }}
        >
          Annuler
        </Button>
      </div>
    );
  };
  const onCancelDelete = (itemDeleted) => {
    let data = itemDeleted;
    setIsLoading(true);
    setShouldRedirect(false);
    AddChantier(
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            notifySuccess("Suppression annulé");
          }
        },
        onError: (error) => {
          notifyError(error);
        },
      },
      data
    );
  };
  const SupprimerChantier = (data) => {
    setIsLoading(true);
    deleteChantier(data.id, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setShouldRedirect(true);

          notifyDelete(data, DeleteToastMsg);
        } else {
          notifyError("Erreur lors de la supprésion du favori");
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  };

  const editMonChantierFunc = (data, listProd) => {
    setIsLoading(true);
    let params = {
      id: detailChantier.id,
      name: data.name,
      description: data.description,
      clientName: data.clientName,
      beginDate: data.beginDate,
      endDate: data.endDate,
      location: data.location,
      idUser: idUser,
      chantierProduct: listProd ? listProd : listOfProduct,
    };
    editMyChantier(params, {
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          notifySuccess("Chantier modifié");
          setIsLoading(false);
          setDetailChantier(response.data.data);
        } else {
          notifyError("Erreur lors de la modification du chantier");
          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });

    setIsLoading(false);
  };
  const removeProduct = (data, index) => {
    let list = [...listOfProduct];
    list.splice(index, 1);
    setListOfProduct(list);
    return editMonChantierFunc(detailChantier, list);
  };
  useEffect(() => {
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);
    setDetailChantier(history.location.state.chantier);
    getChantierDetailsById(
      {
        user: history.location.state.idUser,
        chantier: history.location.state.chantier.id,
      },
      {
        onSuccess: (response) => {
          if (response.data.isSuccess === true) {
            setIsLoading(false);
            setDetailChantier(response.data.data);
            setListOfProduct(response.data.data.chantierProduct);
          } else {
            notifyError("Erreur lors du chargement du chantier");
            setIsLoading(false);
            history.push({
              pathname: `/chantiers`,
            });
          }
        },
        onError: (error) => {
          notifyError(error);
          setIsLoading(false);

          history.push({
            pathname: `/chantiers`,
          });
        },
      }
    );
    setIsLoading(false);
  }, []);
  //CORRIGER URL EN DUR METTRE CELLE DES PRODUITS
  const productIsAvailable = (params) => {
    history.push({
      pathname: `/produits/${params.idProduct}`,
      state: {
        idUser: history.location.state.idUser,
        id: params.idProduct,
        isFromFavorite: false,
        isFromDetailChantuer: true,
        detailChantier: detailChantier,
      },
    });
  };
  return (
    <Grid
      container
      justifyContent={"center"}
      style={{
        display: 'flex',
        position: 'relative',
        minHeight: '100vh'
      }}>
      <ToastContainer />
      {isLoading ? (
        <Grid
          style={{
            // height: "800px",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container style={{
            backgroundColor: "#F9F9F9",
            display: "flex",
            justifyContent: "center",
            height: 'fit-content'
          }}>
            <Grid
              marginX={"auto"}
              sx={{
                position: 'relative',
                justifyContent: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
                height: '100px',
                display: { mobile: 'none', desktop: 'flex' }
              }}
            >
              <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
                <Breadcrumbs productName={detailChantier.name} variantColor={'blue'} />
                <BackButton variantColor={'blue'} />
              </Box>
            </Grid>
            {/* UpperGrid */}
            <Grid container style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: '1400px'
            }}>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { mobile: 'column', desktop: "row" },
                  maxWidth: '1400px',
                  margin: { mobile: '60px auto 34px', desktop: '0 auto' },
                  padding: '0 24px',
                }}>
                <Grid
                  mobile={12}
                  desktop={6}
                >
                  <Grid style={styles.gridName} sx={{ textAlign: { mobile: 'center', desktop: 'start' } }}>
                    {detailChantier.name}
                  </Grid>
                  {detailChantier.description &&
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        wordBreak: 'break-word'
                      }}
                    >
                      <Grid
                        sx={{
                          marginBottom: "30px",
                          fontWeight: "600",
                          maxWidth: '433px'
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#4A4A4A",
                            fontsize: "15px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {detailChantier.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  <Grid
                    sx={{
                      marginBottom: '35px'
                    }}>
                    <Grid style={styles.typoTitle}>
                      <Typography
                        style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                      >
                        Nom du client
                      </Typography>
                      <Typography style={styles.typoData}>
                        {detailChantier.clientName}
                      </Typography>
                    </Grid>
                    <Grid style={styles.typoTitle}>
                      <Typography
                        style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                      >
                        Dates
                      </Typography>
                      <Typography style={styles.typoData}>
                        Du {detailChantier.beginDate} au {detailChantier.endDate}
                      </Typography>
                    </Grid>
                    <Grid style={styles.typoTitle}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Lieu
                      </Typography>
                      <Typography style={styles.typoData}>
                        {detailChantier.location}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  mobile={12}
                  desktop={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: { mobile: 0, desktop: '60px' },
                  }}
                >
                  <Button
                    variant="contained"
                    color={"primary"}
                    style={{
                      borderRadius: "25px",
                      fontFamily: "Montserrat",
                      fontSize: "19px",
                      fontWeight: "bold",
                      padding: "8px 22px",
                      height: '40px',
                      color: "white",
                      marginBottom: "35px",
                      display: "flex",
                      width: 'fit-content',
                    }}
                    onClick={() => setOpenPopUp(true)}
                  >
                    <CreateOutlinedIcon style={{ marginRight: '10px' }} />
                    <Typography
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Modifier les informations
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={{
                      borderRadius: "25px",
                      fontFamily: "Montserrat",
                      fontSize: "19px",
                      fontWeight: "bold",
                      padding: "8px 22px",
                      height: '40px',
                      color: "white",
                      display: "flex",
                      width: 'fit-content',
                    }}
                    onClick={() => SupprimerChantier(detailChantier)}
                  >
                    <CloseOutlinedIcon style={{ marginRight: '10px' }} />
                    <Typography style={{ fontFamily: "Montserrat" }}>
                      Supprimer le chantier
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* BottomGrid */}
            <Dialog open={openPopUp} maxWidth="xl" onClose={handleClose}>
              <DialogContent
                sx={{
                  maxWidth: "760px",
                }}
              >
                <PopUpEditChantier
                  chantierInfos={detailChantier}
                  editMonChantier={editMonChantierFunc}
                  close={handleClose}
                  idUser={history.location.state.idUser}
                />
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              background: 'white'
            }}
          >
            {/* MAPPING PRODUIT */}
            <Grid sx={{
              width: { mobile: '80%', desktop: "60%" },
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "60px",
              justifyContent: "space-evenly",
              marginTop: "36px",
            }}>
              {listOfProduct.length > 0 ? (
                listOfProduct.map((item, index) => {
                  return (
                    <ProductCard
                      data={item}
                      key={item.productName + index}
                      productIsAvailable={productIsAvailable}
                      removeProduct={removeProduct}
                      index={index}
                    />
                  );
                })
              ) : (
                <Typography>Aucun produit associé à ce chantier</Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}


    </Grid>
  );
};

export default DetailChantier;
