import React from "react";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notifyDelete = (itemDeleted, Component) => {
  toast.warning(<Component itemDeleted={itemDeleted}/>, {
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    autoClose: 3000,
  });
};
export default notifyDelete;
