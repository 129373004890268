import { makeStyles } from "@mui/styles";

const ContactInformationStyle = makeStyles(() => ({

  LinkHref: {
    color: '#0079C2 !important',
    textDecoration: 'none !important',
  },
  // Old
  Title: {
    color: "#0079C2",
    fontWeight: "100",
    fontSize: "28px",
  },
  FirstP: {
    fontSize: "15px",
  },
  BoldP: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "47px",
  },
  detailPhoto: {
    height: "118px",
    width: "118px",
    borderRadius: "100%",
    objectFit: "cover",
    marginTop: "10px",
    marginLeft: '22px',
  },

  FirstParRightGrid: {
    marginTop: "12px",
    marginBottom: "0px",
    fontWeight: "bold",
  },
  SecondParRightGrid: {
    marginTop: "4px",
    marginBottom: "0px",
  },
  ThirdParRightGrid: {
    marginTop: "4px",
    marginBottom: "0px",
  },
  FourthParRightGrid: {
    marginTop: "11px",
    marginBottom: "0px",
  },
  fifthParRightGrid: {
    marginTop: "5px",
    marginBottom: "0px",
    color: "#0079C2",
    wordBreak: "break-word",
  },
  ItalicFirstPar: {
    fontStyle: "italic",
    fontSize: "15px",
    marginTop: "28px",
  },
  FirstButton: {
    cursor: "pointer",
    width: "230px",
    height: "48px",
    marginLeft: "44px",
    marginTop: "27px",
    backgroundColor: "#0079C2",
    color: "white",
    fontSize: "18px",
    fontFamily: "Montserrat",
    border: "none",
    borderRadius: "24px",
    outline: "none",
  },
  SecondButton: {
    cursor: "pointer",
    width: "230px",
    height: "48px",
    marginLeft: "44px",
    marginTop: "27px",
    backgroundColor: "#0079C2",
    color: "white",
    fontSize: "18px",
    fontFamily: "Montserrat",
    border: "none",
    borderRadius: "24px",
    outline: "none",
    marginBottom: "78px",
  },

  MailIcon: {
    marginRight: "10px",
    marginBottom: "-2px",
    filter: "invert(1)",
    width: "27px",
  },
  FirstParSecondGrid: {
    fontWeight: "500",
    marginTop: "35px",
    marginBottom: "0px",
  },
  SecondParSecondGrid: {
    marginTop: "20px",
    marginBottom: "0px",
  },
  ThirdParSecondGrid: {
    color: "#0079C2",
    marginTop: "5px",
  },
}));

export default ContactInformationStyle;
