import image83 from './image83.png';

const favoritesSolutionStyle = {

  selectedTabs: {
    color: '#0079C2',
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontSize: '25px',
    marginRight: '2vw',
    marginLeft: '2vw',
    fontFamily: 'Montserrat',
    cursor: 'pointer',
  },
  unSelectedTabs: {
    color: '#7E7E7E',
    fontSize: '25px',
    marginRight: '2vw',
    marginLeft: '2vw',
    fontFamily: 'Montserrat',
    cursor: 'pointer',
  },
  mainGrid: {
    height: '255px',
    backgroundImage: `url(${image83})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
  },
  gridTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1400px',
    width: '95%',
    color: 'white',
  },
  typoSize: {
    fontSize: '38px',
    fontFamily: 'Montserrat',
  },
  typoBold: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  gridSearch: {
    fontFamily: 'Montserrat',

    justifyContent: 'center',
    display: 'flex',
  },
  form: {
    fontFamily: 'Montserrat',
    fontWeight: '900',
    width: '550px',
    height: '50px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '40px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  formField: {
    width: "100%",
    marginLeft: "0px",
    padding: "0px 15px",
  },
  gridBody: {
    fontFamily: 'Montserrat',
    display: 'flex',
    flexDirection: 'column',
  },
  gridTypoContainer: {
    fontFamily: 'Montserrat',

    display: 'flex',
    justifyContent: 'center',
    marginTop: '5vh',
    marginBottom: '5vh',
  },
  gridContainerCard: {
    maxWidth: '1400px',
    // width: '95%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignSelf: 'center',
    minHeight: '500px',
  },
  gridMainCardContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  typoCard: {
    fontFamily: 'Montserrat',

    fontWeight: 'bold',
    marginRight: '10px',
  },
  boldTypo: {
    fontWeight: 'bold',
    marginRight: '10px',
    fontFamily: 'Montserrat',
  },
  gridFavHolder: {
    // display: 'flex',
    // flexDirection: 'column',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // marginBottom: '60px',
  },
  emptyTypo: {
    fontFamily: 'Montserrat',
    marginBottom: '1rem',
  },
  emptyChantierContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '600px',
    marginTop: '200px',
  },


};

export default favoritesSolutionStyle;

