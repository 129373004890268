import React, { useState, useEffect } from "react";
import { CardContent, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@mui/styles";
import Products from "./Products";

export const useMedia = (query: any) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) setMatches(media.matches);
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};



type SolutionStepProps = {
  addStep: Function,
  index: Int,
  step: {},
  EditStep: Function,
  removeStep: Function,
  listProduits: [],
  myDto: {},
  isView: Boolean,
  surface: Number,
  idUser: String,
};


const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        display: {
          mobile: 'block', desktop: 'none'
        },
        color: '#0079C2',
      }}
    >
      <ExpandMoreIcon />
    </Box>
  )
}

const SolutionStep = (
  {
    addStep,
    index,
    step,
    listProduits,
    myDto,
    isView,
    idConstructionType,
    surface,
    idUser,
  }: SolutionStepProps
) => {

  const useStyles = makeStyles({
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: "25px",
      color: "#CECECE",
    },
    newProduct: {
      fontSize: "15px",
      color: "#CECECE",
      textAlign: "center",
    },

    pos: {
      marginBottom: 12,
    },
    stepTitle: {
      color: "#0079C2",
      fontSize: "55px",
    },
  });

  const filterSolution = {
    idConstructionType: myDto.idConstructionType,
    idSurface: myDto.idSurface,
    idCoating: myDto.idCoating,
    idLocation: myDto.idLocation,
    surfaceChantier: parseInt(surface),
  };

  const classes = useStyles();

  const [currentStep] = useState(step);
  const [stepInfos] = useState(myDto["steps"][index]);


  const [expanded, setExpanded] = React.useState(false);

  const handleChange =
    (panel) => (isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };


  const [collapse, setCollapse] = useState(true);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };


  const isMobileScreen = useMedia('(max-width: 1150px)');

  useEffect(() => {
    if (isMobileScreen === true) {
      setCollapse(false)
    }
    else {
      setCollapse(true)
    }
  }, [isMobileScreen])

  return (
    <>
      <Grid
        sx={{
          //margin: "1%",
          //width: '95%'
          alignItems: 'start',
          placeContent: 'starts'
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'start',
            width: "fit-content",
            '& .Mui-disabled': {
              backgroundColor: '#fff !important',
              opacity: '1 !important',
            }
          }}
        >
          <Accordion
            expanded={collapse}
            onChange={() => handleCollapse()}
            disabled={!isMobileScreen}
            sx={{
              border: 0,
              boxShadow: 'none',
              justifyContent: 'center'
            }}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "solid",
                  borderColor: "#0079C2",
                  maxWidth: "100%",
                  //marginBottom: "4vh",
                  width: '95%',
                }}
              >
                <Typography
                  style={{
                    fontSize: "1.7rem",
                    color: "#0079C2",
                    marginRight: "2vh",
                  }}
                >
                  {index + 1}
                </Typography>
                <Typography
                  style={{
                    fontSize: "1.7rem",
                    color: "#0079C2",
                    marginRight: "2vh",
                    minWidth: "max-content",
                    fontFamily: "Montserrat",
                  }}
                >
                  {step.name}
                </Typography>
              </Grid>
            </AccordionSummary>

            {/* SECTION CONTENT */}
            <AccordionDetails
              sx={{
                height: '100%',
                margin: 0,
              }}
            >
              <Grid
                style={
                  stepInfos.products.length === 2
                    ? {
                      display: "flex",
                      maidth: "100%",
                      justifyContent: "space-between",
                    }
                    : {
                      display: "flex",
                      flexWrap: "wrap",
                      placeContent: "start",
                      justifyContent: "space-between",
                    }
                }
                sx={{
                  flexDirection: { mobile: 'column', desktop: 'row' }
                }}
              >
                {currentStep["products"] && !addStep
                  ? stepInfos.products.map((item, index) => (
                    <Products
                      isView={isView}
                      key={item + index}
                      AddProduct={false}
                      index={index}
                      product={item}
                      listProduits={listProduits}
                      isDisabled={Boolean(addStep)}
                      idUser={idUser}
                      solution={filterSolution}
                      idConstructionType={idConstructionType}
                      surface={surface}
                    />
                  ))
                  : null}
                {!isView ? (
                  <Products
                    listProduits={listProduits}
                    AddProduct={true}
                    isDisabled={Boolean(addStep)}
                  />
                ) : null}
              </Grid>
            </AccordionDetails>

          </Accordion >
        </CardContent >
      </Grid >
    </>
  );
};

export default SolutionStep;



