import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import axios from 'axios'
import React from 'react'
import { AuthenticationState, AzureAD } from 'react-aad-msal'
import ReactDOM from 'react-dom'
import App from './App'
import { resetPasswordAuthProvider, signInAuthProvider } from './authProvider'
import configuration from './configFile.json'
import './index.css'
import * as serviceWorker from './serviceWorker'

const addNewUser = (infos) => {
	var accountBeforeEdit = localStorage.getItem("accountInfo");
	var accountInfoParsed = JSON.parse(accountBeforeEdit);

	//Account data was modified ?
	if( accountInfoParsed != undefined && accountInfoParsed.account.idToken.extension_AcceptReciveMapeiNew != infos.account.idToken.extension_AcceptReciveMapeiNew) {

		const mailInfo = {
			destinataire : configuration.mailContact,
			mailAdress :  infos.account.idToken.emails,
			isCheckedNewsletter : infos.account.idToken.extension_AcceptReciveMapeiNew ? Boolean(infos.account.idToken.extension_AcceptReciveMapeiNew) : false
		}

		//Send a mail if user policy has been edited
		axios.post(
			configuration.webapiUrl + 'mail/edit',
			JSON.stringify(mailInfo),
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${infos.jwtIdToken}`,
				},
			},
		)
	}
	localStorage.setItem('accountInfo', JSON.stringify(infos))
	if (infos.account.idToken.newUsers !== null) {
		const user = {
			id: infos.account.idToken.oid,
			mail: infos.account.idToken.emails[0],
		}

		const token = infos
		return axios.post(
			configuration.webapiUrl + 'user',
			JSON.stringify(user),
			{
				// data: user,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			},
		)
	}
}

ReactDOM.render(
	<AzureAD provider={signInAuthProvider} forceLogin={true}>
		{({ login, logout, authenticationState, error, accountInfo }) => {
			switch (authenticationState) {
				case AuthenticationState.Authenticated:
					if (localStorage.getItem('shouldLogout') == 'true') {
						localStorage.setItem('shouldLogout', 'false')
						login()
					}
					addNewUser(accountInfo)
					return <App />

				case AuthenticationState.Unauthenticated:
					if (
						error &&
						error.errorMessage.toString().includes('AADB2C90118')
					) {
						return (
							<AzureAD
								provider={resetPasswordAuthProvider}
								forceLogin={true}>
								<React.StrictMode>
									<App />
								</React.StrictMode>
							</AzureAD>
						)
					} else if (
						error &&
						error.errorMessage.toString().includes('AADB2C90091')
					) {
						return login()
					} else if (error) {
						console.log(error)
						return login()
					}
				case AuthenticationState.InProgress:
					return <p>Authenticating...</p>
			}
		}}
	</AzureAD>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
