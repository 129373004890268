import React from 'react'
import {ToastContainer} from 'react-toastify'
import {Box, Grid} from "@mui/material";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import DeleteUserDataContainer from "../../containers/DeleteUserDataContainer";

const DeleteUserData = () => {
  return (
    <>
      <ToastContainer/>
      <Grid
        sx={{
          height: '80vh'
        }}
      >
        <Grid
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1400px',
            width: '95%',
            color: 'white',
          }}
          marginX={"auto"}>
          <Box sx={{display: {mobile: 'none !important', desktop: 'flex !important'}}}>
            <Breadcrumbs variantColor={'blue'}/>
            <BackButton variantColor={'blue'}/>
          </Box>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '1400px',
            margin: 'auto',
            padding: {mobile: '32px', desktop: 0}
          }}
        >
          <DeleteUserDataContainer/>
        </Grid>
      </Grid>
    </>
  )
}

export default DeleteUserData
