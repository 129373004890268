import { makeStyles } from "@mui/styles";

const PopUpStyle = makeStyles((theme) => ({
  container: {
    marginTop: "2vh",
    marginBottom: "12px",
  },
  label: {
    marginTop: "16px",
    marginBottom: '8px'
  },
  colorRed: {
    color: "red",
  },
  textInput: {
    '-webkit-appearance': 'none',
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    borderRadius: '1px',
    border: "0px",
    maxWidth: "445px",
    width: '100%',
    fontSize: "15px",
    padding: "12px 20px",
    fontFamily: "Montserrat",
    height: "30px",
    marginTop: '8px',
  },
  textareaInput: {
    '-webkit-appearance': 'none',
    maxWidth: "487px",
    width: '100%',
    minHeight: "50px",
    height: 'fit-content',
    padding: "12px 20px",
    boxSizing: "border-box",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    border: "none",
    fontSize: "15px",
    fontFamily: "Montserrat",
    marginTop: '8px',
    //resize: "vertical",
    resize: 'none'
  },
  dateInput: {
    fontSize: "15px",
    padding: "12px 20px",
    fontFamily: "Montserrat",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
    border: "none",
    maxWidth: "254px",
    flexGrow: 1,
    marginTop: '8px',
  },
  dateSpan: {
    fontSize: "15px",
    padding: "12px 20px",
    fontStyle: 'italic',
  },
  headContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bodyContainer: {
    marginTop: "1vh",
    marginLeft: "50px",
    marginBottom: "1vh",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "1vh",
    marginTop: "2vh",
  },
  buttonsContainerContact: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "1vh",
    marginTop: "2vh",
    paddingRight: "15px",
  },
}));

export default PopUpStyle;
