import axios from "axios";
import config from "../../../configFile.json";

let url = config.webapiUrl;
//FAVORITES SOLUTIONS

const getFavoritesByUserId = (id, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "favoriteSolution/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const searchFavoriteByName = (id, name, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "favoriteSolution/filterByName/" + name + "/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const removeFavorite = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .delete(
      url + "favoriteSolution",
      {
        data: params,
        headers: { Authorization: `Bearer ${token.jwtIdToken}` },
      },
      configToken
    )
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const updateFavoriteName = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .put(url + "favoritesolution", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.jwtIdToken}`,
      },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

const solutionFromFavorite = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .post(url + "solution/filter", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.jwtIdToken}`,
      },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

//FAVORITES PRODUCTS

const getFavoritesProductsByUserId = (id, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "favoriteProduct/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const searchProductsFavoriteByName = (id, name, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "favoriteProduct/filterByName/" + name + "/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const removeFavoriteProducts = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .delete(url + "favoriteProduct", {
      data: params,
      headers: { Authorization: `Bearer ${token.jwtIdToken}` },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

export {
  getFavoritesByUserId,
  searchFavoriteByName,
  removeFavorite,
  updateFavoriteName,
  solutionFromFavorite,
  getFavoritesProductsByUserId,
  searchProductsFavoriteByName,
  removeFavoriteProducts,
};
