import React, { useState } from "react";
import { Button, Grid, Box, IconButton, Typography } from "@mui/material";
import PopUpStyle from "./PopUp.style";

import Close from "./../../images/svg/Close.svg";

const PopUpEditFavorite = (props) => {
  const classes = PopUpStyle;
  const { close, editFavs, placeHolder, data } = props;

  const [name, setName] = useState(placeHolder);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleNameChange = (e) => {
    setName(e);
  };

  const method = () => {
    close();
    editFavs(name, data);
    //EDIT FAVORITE NAME
  };
  const isSubmitable = () => {
    if (name !== "") {
      return false;
    } else return true;
  };
  return (
    <Grid container style={classes.mainContainer}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton onClick={() => close()}
        >
          <img
            className={classes.icon}
            alt="close button"
            src={Close}
          />
        </IconButton>
      </Box>
      <Grid
        container
      // style={classes.topContainer}
      >
        <Grid container >

          <Typography
            sx={{
              maxWidth: "510px",
              width: '70%',

              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "bold",
              //marginTop: "35px",
              textWrap: 'wrap',
              marginBottom: '24px'
            }}
          >Modifier la solution favorite</Typography>

        </Grid>
      </Grid>
      <Grid
        container
        //style={classes.addContainer}
        sx={{
          flexDirection: { mobile: 'column', desktop: 'row' }
        }}
      >
        <Grid
          className={classes.label}
          item
          sx={{
            marginLeft: { mobile: 0, desktop: '70px' }
          }}
        //xs={2}
        >
          <label
          //</Grid>style={classes.labelName}
          > Nom </label>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: 'center'
          }}
        >
          <input
            value={name}
            placeholder={placeHolder}
            type="text"
            style={{
              '-webkit-appearance': 'none',
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
              border: "none",
              maxWidth: "445px",
              width: '100%',
              fontSize: "15px",
              padding: "12px 20px",
              //marginLeft: "41px",
              fontFamily: "Montserrat",
              height: "30px",
              marginTop: '8px',
            }}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          flexDirection: { mobile: 'column', desktop: "row" },
          justifyContent: { mobile: 'center', desktop: "center" },
          //marginBottom: "1vh",
          marginTop: "32px",
        }}
      >
        <Button
          rounded
          color={'inherit'}
          variant={'contained'}
          sx={{
            alignSelf: { mobile: 'center', desktop: 'end' },
            //marginTop: "30px",
            borderRadius: "25px",
            maxWidth: "223px",
            width: '40%',
            height: "40px",
            marginBottom: { mobile: '16px', desktop: 0 },
          }}
          onClick={() => close()}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "19px",
            }}
          >
            Annuler
          </Typography>
        </Button>

        <Button
          rounded
          disabled={isSubmitable()}
          variant={'contained'}
          type="submit"
          sx={{
            alignSelf: { mobile: 'center', desktop: 'end' },
            borderRadius: "25px",
            maxWidth: "223px",
            width: '40%',
            height: "40px",
            //marginBottom: { mobile: 0, desktop: '80px' },
            marginLeft: { mobile: 0, desktop: '32px' },
          }}
          onClick={() => method()}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "19px",
            }}
          >
            Enregister
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default PopUpEditFavorite;
