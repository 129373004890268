import { LoginType, MsalAuthProvider } from 'react-aad-msal'
import jsonConfig from './configFile.json'

const tenant = jsonConfig.tenantFO
const signInPolicy = jsonConfig.signInPolicyFO
const resetPasswordPolicy = jsonConfig.resetPasswordPolicyFO
const editProfilePolicy = jsonConfig.editProfilePolicyFO
const applicationID = jsonConfig.applicationIDFO

let reactRedirectUri = jsonConfig.urlRedirection
let scopeDevOPS = jsonConfig.scopeDevOPS

const tenantSubdomain = tenant.split('.')[0]
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`
const signInAuthority = `${instance}${tenant}/${signInPolicy}`
const resetPasswordAuthority = `${instance}${tenant}/${resetPasswordPolicy}`
const editProfileAuthority = `${instance}${tenant}/${editProfilePolicy}`
// Msal Configurations
const signInConfig = {
	auth: {
		authority: signInAuthority,
		clientId: applicationID,
		redirectUri: reactRedirectUri,
		validateAuthority: false,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
}
const resetPasswordConfig = {
	auth: {
		authority: resetPasswordAuthority,
		clientId: applicationID,
		redirectUri: reactRedirectUri,
		validateAuthority: false,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
}
const editProfileConfig = {
	auth: {
		authority: editProfileAuthority,
		clientId: applicationID,
		redirectUri: reactRedirectUri,
		validateAuthority: false,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
}
// Authentication Parameters
const authenticationParameters = {
	scopes: ['https://graph.microsoft.com/Directory.Read.All', scopeDevOPS],
}
// Options
const options = {
	loginType: LoginType.Redirect,
	tokenRefreshUri: window.location.origin + '/auth.html',
}
export const signInAuthProvider = new MsalAuthProvider(
	signInConfig,
	authenticationParameters,
	options,
)
export const resetPasswordAuthProvider = new MsalAuthProvider(
	resetPasswordConfig,
	authenticationParameters,
	options,
)
export const editProfileAuthProvider = new MsalAuthProvider(
	editProfileConfig,
	authenticationParameters,
	options,
)
