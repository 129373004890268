const DetailStyle = {
  mainContainer: {
    // minHeight: "85vh",
  },
  topContainer: {
    // position: 'relative',
    backgroundColor: "#F9F9F9",
    // paddingTop: "10px",
    // paddingTop: 0,
    // marginTop: 0,
    paddingBottom: "20px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  gridProduct: {
    marginRight: "auto",
    marginLeft: "auto",
    // width: "950px"
  },
  bottomContainer: {
    // paddingTop: "5vh",
    marginRight: "auto",
    marginLeft: "auto",
    // width: "950px",
  },
  PdfTitle: {
    fontSize: "20px",
    marginBottom: "10px",
    marginTop: "30px",
    fontFamily: "Montserrat",
  },
  PdfLink: {
    fontSize: "16px",
    fontFamily: "Montserrat",
  },
  textAreaDescription: {
    minHeight: "-webkit-fill-available",
    width: "416px",
    fontSize: "16px",
    border: "none",
    fontFamily: "Montserrat",
    marginTop: "74px",
    color: "black",
    fontWeight: "bold",
    lineHeight: "26px",
    backgroundColor: "#F9F9F9",
    margin: "0",
    resize: "none",
  },
  textAreaCaracteristique: {
    marginTop: "50px",
    fontSize: "15px",
    backgroundColor: "white",
    width: "880px",
  },
  Image: {
    width: "340px",
    justifySelf: 'center',
    objectFit: "contain",
    height: 'fit-content',
  },
  TitleTopGrid: {
    color: "#0079C2",
    marginTop: "16px",
    //fontSize: "38px",
    marginBottom: "0",
  },
  TitleQttGrid: {
    color: "#0079C2",
    marginTop: "9px",
    marginBottom: "40px",
  },
  CaractéristiquesButton: {
    color: "#0079C2",
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "25px",
    marginLeft: "127px",
    fontFamily: "Montserrat",
  },
  DocumentationButton: {
    color: "#0079C2",
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "25px",
    marginLeft: "100px",
    fontFamily: "Montserrat",
  },

  gridFavHolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
};

export default DetailStyle;
