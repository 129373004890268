import { toast } from 'react-toastify'

const notifyError = (mess) => {
	toast.error(mess, {
		position: toast.POSITION.BOTTOM_LEFT,
		hideProgressBar: true,
		autoClose: 3000,
	})
}

export default notifyError
