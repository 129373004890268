import React, { useEffect, useState } from "react";
import { getAllProducts } from "../../API/APIRequest/Products/ProductsAPI";
import notifyError from "../../Shared/notifyError";
import ProductsListStyles from "./style";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BackButton from "../../components/BackButton";
import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ProductCard from "./ProductCard";

const ProductsList = ({ appInsights }) => {
  const [productsList, setProductsList] = useState([]);
  const [productsListSearched, setProductsListSearched] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [isLoading, setIsLoading] = useState("true");
  const [idUser, setIdUser] = useState();

  useEffect(() => {
    setIsLoading(true);
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);

    getAllProducts({
      onSuccess: (response) => {
        if (response.data.isSuccess === true) {
          setProductsList(response.data.data);
          setIsLoading(false);
        }
      },
      onError: (error) => {
        notifyError(error);
        setIsLoading(false);
      },
    });
  }, []);

  //SearchManagement
  const handleChangeValue = (e) => {
    if (e.key === "Enter") {
      searchProdByName();
    } else return setSearchedValue(e.target.value.toUpperCase());
  };

  const searchProdByName = () => {
    setIsLoading(true);
    if (searchedValue !== "") {
      let newList = productsList.filter((x) => x.name.includes(searchedValue));
      setProductsListSearched(newList);
      setIsLoading(false);
    } else {
      setProductsListSearched([]);

      setIsLoading(false);
    }
  };

  return (
    <Grid>
      <Grid sx={{}}>
        <Grid style={ProductsListStyles.mainGrid}>
          <Grid
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1400px',
              width: '95%',
              color: 'white',
            }}
            marginX={"auto"}>
            <Box sx={{ display: { mobile: 'none !important', desktop: 'flex !important' } }}>
              <Breadcrumbs variantColor={'white'} />
              <BackButton />
            </Box>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: 'inherit'
            }}
          >
            <Grid
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '1400px',
                width: '95%',
                color: 'white',
              }}
              marginX={"auto"}
            >
              <Grid style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}>
                <Typography style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "38px",
                  margin: 'auto'
                }}>
                  PRODUITS
                </Typography>
                <Grid container sx={{ justifyContent: 'center' }}>
                  <Typography style={{
                    fontSize: "26px",
                    fontFamily: "Montserrat",
                  }}>
                    Recherchez un
                  </Typography>
                  <Typography style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    fontSize: "26px",
                    marginLeft: "10px",
                  }}>
                    produit MAPEI{" "}
                  </Typography>
                </Grid>
              </Grid>


            </Grid>
            <Grid
              marginX={"auto"}
              style={{
                display: 'flex',
                padding: "0 15px",
                margin: '0 20px',
                justifyContent: 'center',
              }}>
              <Grid style={ProductsListStyles.form}>
                <TextField
                  variant={"standard"}
                  InputProps={{ disableUnderline: true }}
                  onKeyUp={(e) => handleChangeValue(e)}
                  style={ProductsListStyles.formField}
                  id="chantier-shearch-field"
                  placeholder="Filtrer ..."
                />
                <SearchIcon
                  htmlColor={"#1D97EF"}
                  onClick={() => searchProdByName()}
                />
              </Grid>
            </Grid>
          </Grid>


        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: { mobile: 0, desktop: "50px" },
            padding: { mobile: '0 24px', desktop: 0 },
            marginBottom: "50px",
            minHeight: '400px'
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : productsListSearched.length > 0 ? (
            productsListSearched.map((item, index) => {
              return (
                <ProductCard
                  key={item.name + index}
                  idUser={idUser}
                  item={item}
                  index={index}
                />
              );
            })
          ) : (
            productsList.map((item, index) => {
              return (
                <ProductCard
                  key={item.name + index}
                  idUser={idUser}
                  item={item}
                  index={index}
                />
              );
            })
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductsList;
