import { removeFavoriteProducts } from '../../API/APIRequest/Favorites/Favorites'

const styles = {
	buttonPrimary: {
		backgroundColor: '#0079C2',
		color: 'white',
	},
	successMessage: {
		border: '1px solid green',
		borderRadius: '15px',
		padding: '10px',
	},
}

export default styles
