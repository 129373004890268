import axios from 'axios'

let UrlFromFetch

async function APICALL() {
	if (UrlFromFetch !== undefined) {
		return UrlFromFetch
	} else {
		const response = await fetch('/config.json')
		const text = response.json()
		UrlFromFetch = text
		return text
	}
}

const Location = (callback) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'Location', config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const LocationById = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'Location/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const Coating = (callback) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'coating', config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const CoatingById = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'coating/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const Surface = (callback) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'surface', config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const SurfaceById = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'surface/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

const Produit = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'product/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}
const ProduitByID = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	APICALL()
		.then((response) => {
			return axios
				.post(response.url + 'solution/filter/' + id, null, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token.jwtIdToken}`,
					},
				})
				.then((result) => {
					callback.onSuccess(result)
				})
				.catch((error) => {
					callback.onError(error)
				})
		})
		.catch((error) => {
			return console.log(error)
		})
}

const ConstructionType = (callback) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'constructionType', config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

const ConstructionTypeById = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'constructionType/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

const Mail = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(response.url + 'mail', JSON.stringify(params), {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

const MailSolution = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(response.url + 'mail/solution', JSON.stringify(params), {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//SOLUTION GET BY ID
const Solution = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'solution/' + id, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//SOLUTION GET BY CATEGORIES
const SolutionByCategories = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(response.url + 'solution/filter', JSON.stringify(params), {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//FAVORITESOLUTION POST
const PostFavoriteSolution = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(response.url + 'favoritesolution', JSON.stringify(params), {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//CHECK FAVORITESOLUTION
const CheckFavoriteSolution = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(
				response.url + 'favoritesolution/check',
				JSON.stringify(params),
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token.jwtIdToken}`,
					},
				},
			)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//DELETE FAVORITE SOLUTIOB
const DeleteFavoriteSolution = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.delete(response.url + 'favoritesolution', {
				data: params,
				headers: { Authorization: `Bearer ${token.jwtIdToken}` },
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

const GetIncrementNumber = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.post(
				response.url + 'favoritesolution/incrementnumber',
				JSON.stringify(params),
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token.jwtIdToken}`,
					},
				},
			)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

const getContactByDepartment = (callback, department) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo
	const config = {
		headers: { Authorization: `Bearer ${token.jwtIdToken}` },
	}
	APICALL().then((response) => {
		return axios
			.get(response.url + 'contact/department/' + department, config)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}

//FAVORITE PRODUCT POST
const PostFavoritProduct = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(response.url + 'favoriteProduct', JSON.stringify(params), {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.jwtIdToken}`,
				},
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}
//FAVORITE PRODUCT DELETE

const RemoveFavoriteProduct = (callback, id) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.delete(response.url + 'favoriteProduct', {
				data: id,
				headers: { Authorization: `Bearer ${token.jwtIdToken}` },
			})
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				callback.onError(error)
			})
	})
}

//CHECK FAVORITE PRODUCT
const CheckFavoriteProduct = (callback, params) => {
	var accountInfo = localStorage.getItem('accountInfo')
	var tokeninfo = JSON.parse(accountInfo)
	const token = tokeninfo

	APICALL().then((response) => {
		return axios
			.post(
				response.url + 'favoriteProduct/check',
				JSON.stringify(params),
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token.jwtIdToken}`,
					},
				},
			)
			.then((result) => {
				callback.onSuccess(result)
			})
			.catch((error) => {
				console.log(error)
			})
	})
}
export {
	GetIncrementNumber,
	Location,
	LocationById,
	Coating,
	CoatingById,
	Surface,
	SurfaceById,
	Produit,
	Solution,
	SolutionByCategories,
	ConstructionType,
	ConstructionTypeById,
	Mail,
	ProduitByID,
	APICALL,
	PostFavoriteSolution,
	DeleteFavoriteSolution,
	getContactByDepartment,
	CheckFavoriteSolution,
	PostFavoritProduct,
	RemoveFavoriteProduct,
	CheckFavoriteProduct,
	MailSolution,
}
