import React from "react";
import { Grid, Typography } from "@mui/material";

type EmptySearchListProps = {
  firstLine: string,
  secondLine?: string,
  variantColor?: 'white' | 'blue',
};
const EmptySearchList = ({ variantColor = "white", firstLine, secondLine }: EmptySearchListProps) => {
  let color = variantColor === "white" ? "#F0EFEF" : "#0079C2"
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      margin={'auto'}
    // sx={{display: {mobile: 'none', desktop: 'flex'}}}
    >
      <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M100.491 90.6791L138.066 128.185C139.383 129.491 140.124 131.27 140.124 133.125C140.124 134.98 139.383 136.759 138.066 138.065C136.759 139.383 134.98 140.124 133.125 140.124C131.27 140.124 129.491 139.383 128.185 138.065L90.6793 100.49C80.9547 108.124 68.946 112.265 56.5834 112.25C25.8396 112.25 0.916748 87.3271 0.916748 56.5833C0.916748 25.8394 25.8396 0.916626 56.5834 0.916626C87.3273 0.916626 112.25 25.8394 112.25 56.5833C112.265 68.9459 108.124 80.9545 100.491 90.6791ZM56.5834 14.8333C33.5255 14.8333 14.8334 33.5254 14.8334 56.5833C14.8334 79.6412 33.5255 98.3333 56.5834 98.3333C79.6413 98.3333 98.3334 79.6412 98.3334 56.5833C98.3334 33.5254 79.6413 14.8333 56.5834 14.8333Z"
          fill={color}
        />
      </svg>
      <Grid sx={{
        textAlign: 'center',
        marginLeft: { mobile: 0, desktop: '32px' }
      }}>
        <Typography sx={{
          color: color,
          fontFamily: 'Montserrat',
          fontSize: '36px',
          fontWeight: '800',
          lineHeight: 'normal',

        }}>
          {firstLine}
        </Typography>
        <Typography sx={{
          color: color,
          fontFamily: 'Montserrat',
          fontSize: '36px',
          fontWeight: '800',
          lineHeight: 'normal',
        }}>
          {secondLine}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptySearchList;
