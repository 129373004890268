import axios from "axios";
import config from "../../../configFile.json";

let url = config.webapiUrl;
//Chantiers

const getChantiersByUserId = (id, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "chantier/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};
const deleteChantier = (id, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .delete(url + "chantier/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const AddChantier = (callback, params) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .post(url + "chantier", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.jwtIdToken}`,
      },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      console.log(error);
    });
};
const searchChantierByName = (id, name, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "chantier/filterByName/" + name + "/" + id, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};
const editMyChantier = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .post(url + "chantier/put", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.jwtIdToken}`,
      },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};
const getChantierDetailsById = (id, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .get(url + "chantier/detail/" + id.user + "/" + id.chantier, configToken)
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};

const addProductToChantier = (params, callback) => {
  var accountInfo = localStorage.getItem("accountInfo");
  var tokeninfo = JSON.parse(accountInfo);
  const token = tokeninfo;
  const configToken = {
    headers: { Authorization: `Bearer ${token.jwtIdToken}` },
  };
  return axios
    .post(url + "chantier/chantierproduct", JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.jwtIdToken}`,
      },
    })
    .then((result) => {
      callback.onSuccess(result);
    })
    .catch((error) => {
      callback.onError(error);
    });
};
export {
  getChantiersByUserId,
  deleteChantier,
  AddChantier,
  searchChantierByName,
  editMyChantier,
  getChantierDetailsById,
  addProductToChantier,
};
