import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import logoV2 from "../../images/logov2.png";
import SolMurButton from "../../images/pose_2.jpg";
import ReparationButton from "../../images/reparation_2.jpg";
import Style from "./style";
import { useHistory } from "react-router-dom";
import { ConstructionType } from "../../API/APIRequest/Categories";
import Accueil from "../../images/svg/Accueil.svg";




const Home = ({ appInsights }) => {
  let history = useHistory();

  const RedirectToRecherche = (props) => {
    if (props === "construction") {
      history.push({
        pathname: "/recherche",
        state: {
          idConstructionType: "1fe9685a-fe00-4dd7-b138-0d8d55067424",
          idUser: idUser,
        },
      });
    } else {
      history.push({
        pathname: "/recherche",
        state: {
          idConstructionType: "b7d24b84-7a98-40ea-941e-648726dd6147",
          idUser: idUser,
        },
      });
    }
  };

  const classes = Style;
  const [, setConstructionTypes] = useState([]);
  const [idUser, setIdUser] = useState();

  useEffect(() => {
    var accountInfo = localStorage.getItem("accountInfo");
    var accountInfoParsed = JSON.parse(accountInfo);
    setIdUser(accountInfoParsed.account.accountIdentifier);

    ConstructionType({
      onSuccess: (response) => setConstructionTypes(response.data),
      onError: (error) => console.log(error),
    });
  }, []);

  return (
    <>
      <Grid
        container
        mobile={12}
        sx={{
          minHeight: "90vh",
          backgroundImage: `url(${Accueil})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          right: "0",
        }}

      >
        {/** LEFT CONTAINER */}
        <Grid container mobile={12} desktop={6} sx={{
          display: 'flex',
          //alignItems: 'center',
          margin: { mobile: 'auto', desktop: '20vh 2vw auto auto' },
          maxWidth: { mobile: 'auto', desktop: "30vw" },
        }}>

          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: { mobile: 'auto', desktop: 'auto 50px auto auto' },
              maxWidth: { mobile: 'auto', desktop: "536px" },
              justifyContent: { mobile: 'center', desktop: "end" },
              padding: { mobile: '40px', desktop: "0" },
            }}
          >
            <Grid
              container
              mobile={12}
              sx={{
                justifyContent: 'center',
                maxWidth: { mobile: '250px', desktop: '100%' },
                marginBottom: { mobile: '40px', desktop: '4vw' },
                '& img': {
                  maxWidth: { mobile: '100%', desktop: '30vw' }
                  ,
                }
              }}
            >
              <img
                style={{
                  maxHeight: "169px",
                  padding: 'auto',
                }}
                src={logoV2}
                alt="Logo Mappei home"
              />
            </Grid>
            <Grid
              container
              mobile={12}
              sx={{
                paddingLeft: { mobile: 0, desktop: '2vw' }
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Montserrat',
                    fontSize: { mobile: '20px', desktop: '1.5vw' },
                  }}
                >
                  <strong>"Mon Chantier avec MAPEI"</strong>
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontFamily: 'Montserrat',
                    fontSize: { mobile: '20px', desktop: '1.5vw' },
                  }}
                >
                  vous apporte la <strong>solution personnalisée</strong> à la mise en œuvre de tous vos chantiers.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        {/** RIGHT CONTAINER */}
        <Grid
          item
          mobile={12}
          desktop={6}
          sx={{
            backgroundColor: "white",
            padding: { mobile: '20px', desktop: "1%" },
            margin: { mobile: '0 0 60px 0', desktop: '10vh auto 10vh 5vw' },
            borderRadius: { mobile: 0, desktop: "5px" },
            maxWidth: { mobile: 'auto', desktop: "25vw" },
          }}
        >
          <Grid
            container
            sx={{
              width: { mobile: "100%", desktop: '100%' },
              height: '100%',
              display: "flex",
              padding: '1%'
            }}
          >
            <Grid item mobile={12}>
              <Typography
                sx={{
                  fontSize: { mobile: '20px', desktop: "1.2vw" },
                  color: "#0079C2",
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  textAlign: 'center',
                }}>QUEL EST VOTRE CHANTIER ?</Typography>
            </Grid>
            <Grid item mobile={12}>
              <Button
                sx={{
                  marginTop: '5%',
                  padding: 0,
                  minWidth: '100% !important'
                }}
                onClick={() => RedirectToRecherche("construction")}>
                <img
                  style={{
                    // flexShrink: '0',
                    width: '100%',
                    height: '100%',
                    maxWidth: '400px',
                    maxHeight: '45%',
                    borderRadius: "12px",
                    boxShadow: "rgba(0, 0, 1, 0.15) 0px 4px 8px 0px, rgba(0, 0, 0, 0.05) 0px 6px 20px 0px",
                  }}
                  alt="Button constructionType 'sol et mur' "
                  src={SolMurButton}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    backgroundColor: "White",
                    width: '100%',
                    color: "#0079C2",
                    borderRadius: "0 0 12px 12px",
                    fontSize: { mobile: '16px', desktop: "1vw" },
                    fontWeight: "500",
                    padding: '12px 0',
                    fontFamily: "Montserrat",
                  }}>Pose de revêtements sols et murs</Typography>
              </Button>
            </Grid>
            <Grid item mobile={12}>
              <Button
                sx={{
                  marginTop: '5%',
                  padding: 0,
                  minWidth: '100% !important'
                }}
                onClick={() => RedirectToRecherche("réparation")}>
                <img
                  style={{
                    // flexShrink: '0',
                    width: '100%',
                    height: '100%',
                    maxWidth: '400px',
                    maxHeight: '45%',
                    borderRadius: "12px",
                    boxShadow: "rgba(0, 0, 1, 0.15) 0px 4px 8px 0px, rgba(0, 0, 0, 0.05) 0px 6px 20px 0px",
                  }}
                  alt="Button constructionType 'réparation' "
                  src={ReparationButton}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    backgroundColor: "White",
                    width: '100%',
                    color: "#0079C2",
                    borderRadius: "0 0 12px 12px",
                    fontSize: { mobile: '16px', desktop: "1vw" },
                    fontWeight: "500",
                    padding: '12px 0',
                    fontFamily: "Montserrat",
                  }}>Réparation</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/*</Grid>*/}
      </Grid>
    </>
  );
};

export default Home;
