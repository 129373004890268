const styles = {

  mainGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonBack: {
    backgroundColor: "#EB5757",
    minWidth: "30px",
    maxWidth: "30px",
    minHeight: "30px",
    maxHeight: "30px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopLeftRadius: "0px",
  },
  closeIcon: {
    width: "15px",
  },
  imageGrid: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    height: "150px",
    width: "150px",
  },
  nameType: {
    fontSize: "15px",
    color: "#0079C2",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily: "Montserrat",
    cursor: "pointer",
  },
  emptytype: {
    fontSize: "15px",
    fontStyle: "italic",
    color: "#4A4A4A",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
};
export default styles;
