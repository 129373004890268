import { Link } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import "./fonts/Montserrat/Montserrat-Regular.otf";
import Contact from "./pages/Contact";
import CookiesPage from "./pages/CookiesPage";
import DeleteUserData from "./pages/DeleteUserData";
import DetailChantier from "./pages/DetailChantier";
import DetailProduct from "./pages/DetailProduct";
import FavoritesProducts from "./pages/FavoritesProducts";
import FavoritesSolutions from "./pages/FavoritesSolutions";
import Home from "./pages/Home";
import Chantier from "./pages/Chantier";
import PolicyPage from "./pages/PolicyPage";
import ProductsList from "./pages/ProductsList";
import RechercheSolution from "./pages/RechercheSolution";
import Footer from "./components/Footer";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import config from "./configFile.json";

const defaultTheme = createTheme();
const theme = createTheme(
  {
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1147,
      },
    },
    palette: {
      mode: 'light',
      common: {
        white: '#ffffff',
      },
      primary: {
        light: '#1380C28F',
        main: '#0079C2',
      },
      secondary: {
        main: '#F50157',
      },
    },
    Components: {
      // Name of the component
      MuiDialog: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: '20px',
          },
          paper: {
            borderRadius: '20px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: '10px !important',
          }
        }
      },
      // MuiMenuItem: {
      //   styleOverrides: {
      //     root: {
      //       color: blue
      //     }
      //
      //   }
      // },
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //
      //     }
      //   }
      // },
    },
  }
);

// const useStyles = makeStyles((theme) => {
//   {
//     // some CSS that accesses the theme
//   }
// });

const CookieBanner = () => {
  const CookieSentance1 =
    "Ce site utilise des cookies de fonctionnement nécessaires à la navigation sur ce site. Pour plus d’informations, veuillez consulter notre";
  const CookieSentance2 =
    `En poursuivant sur ce site, vous acceptez l’utilisation de cookies `;

  return (
    <CookieConsent
      buttonText="X"
      style={{
        background: "#0079c2",
        textAlign: "center",
        alignItems: 'unset',
        justifyContent: 'start',
      }}
      buttonStyle={{
        background: "#0079c2",
        color: "White",
        fontSize: "23px",
      }}
    >
      {CookieSentance1}&nbsp;
      <Link
        href="/utilisation-des-cookies"
        style={{ color: "white", textDecoration: "underline" }}
      >
        Politique d’utilisation Des Cookies
      </Link>
      &nbsp;{CookieSentance2}
    </CookieConsent>
  );
};
const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.appInsight,

    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

const ScrollResetToTopWrapper = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>
};


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollResetToTopWrapper>
          <Switch>
            <>
              <Navbar />
              <Route exact path="/">
                <Home appInsights={appInsights} />
              </Route>
              <Route exact path="/recherche">
                <RechercheSolution appInsights={appInsights} />
              </Route>
              <Route exact path="/produits">
                <ProductsList appInsights={appInsights} />
              </Route>
              <Route exact path="/produits/:name">
                <DetailProduct appInsights={appInsights} />
              </Route>
              <Route exact path="/contact">
                <Contact appInsights={appInsights} />
              </Route>
              <Route exact path="/mes-solutions">
                <FavoritesSolutions appInsights={appInsights} />
              </Route>
              <Route exact path="/mes-produits">
                <FavoritesProducts appInsights={appInsights} />
              </Route>
              <Route exact path="/chantiers">
                <Chantier appInsights={appInsights} />
              </Route>
              <Route exact path="/chantiers/:name">
                <DetailChantier appInsights={appInsights} />
              </Route>
              <Route exact path="/utilisation-des-cookies">
                <CookiesPage />
              </Route>
              <Route exact path="/informations-personnelles">
                <PolicyPage />
              </Route>
              <Route exact path="/compte/supprimer">
                <DeleteUserData />
              </Route>
              <Footer />
              <CookieBanner />
            </>
          </Switch>
        </ScrollResetToTopWrapper>
      </Router>
    </ThemeProvider>
  )
};

export default withAITracking(reactPlugin, App);
