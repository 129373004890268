import {Button, Grid, Paper, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {useState} from 'react';

const ChantierToast = (props) => {
  const {data, editFavs, removeChantier, isAvailable} = props;
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const [buttonIsHovered, setButtonHovered] = useState(false);
  const handleClose = () => {
    setOpenEditMenu(false);
  };

  const parsedDate = () => {
    let value = new Date(data.beginDate);
    let yyyy = value.getFullYear();
    let dd = String(value.getDate());
    let mm = String(value.getMonth() + 1);

    let valueFin = new Date(data.endDate);
    let yyyyfin = value.getFullYear();
    let ddFin = String(value.getDate());
    let mmFin = String(value.getMonth() + 1);
    let result =
      'Du' +
      ' ' +
      dd +
      '/' +
      mm +
      '/' +
      yyyy +
      ' au ' +
      ddFin +
      '/' +
      mmFin +
      '/' +
      yyyyfin;

    return result;
  };
  return (
    <Paper
      elevation={buttonIsHovered ? 10 : 3}
      style={{
        minHeight: '78px',
        maxHeight: '124px',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        fontFamily: 'Montserrat',
        marginTop: '20px',
      }}
    >
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // width: '95%',
          flexGrow: 1,
          marginLeft: '37px',
          marginRight: '5%',
          cursor: 'pointer',
        }}
        sx={{
          flexDirection: {mobile: 'column', desktop: 'row'},
          alignItems: {mobile: 'start', desktop: 'center'},
          padding: {mobile: '16px 0', desktop: 0},
        }}
        onClick={() => isAvailable(data)}
      >
        <Grid
          sx={{
            width: {mobile: '100%', desktop: '20%'},
            // overflow: 'overlay'
          }}
        >
          <Typography
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            style={{
              fontSize: '15px',
              color: '#0079C2',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
              // cursor: 'pointer',
            }}
          >
            {data.name}
          </Typography>
        </Grid>
        <Grid
          style={{
            width: '20%',
            maxHeight: '78px',
          }}
        >
          <Typography>{data.clientName}</Typography>
        </Grid>
        <Grid
          style={{
            maxHeight: '78px',
          }}
          sx={{
            width: {mobile: '100%', desktop: '20%'},
          }}
        >
          <Typography>{data.location}</Typography>
        </Grid>
        <Grid
          style={{
            maxHeight: '78px',
          }}
          sx={{
            width: {mobile: '100%', desktop: '20%'},
          }}
        >
          <Typography
            style={{
              fontSize: '15px',
              fontStyle: 'italic',
              color: '#4A4A4A',
              justifyContent: 'center',
              fontFamily: 'Montserrat',
            }}
            onClick={() => isAvailable(data)}
          >
            du {data.beginDate} au {data.endDate}
          </Typography>
        </Grid>
      </Grid>
      <Button
        fullWidth={true}
        style={{
          backgroundColor: '#F50157',
          minWidth: '30px',
          maxWidth: '30px',
          minHeight: '30px',
          maxHeight: '30px',
          borderBottomRightRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        onClick={() => removeChantier(data)}
      >
        <CloseIcon
          htmlColor="white "
          size="small"
          style={{width: '15px'}}
        />
      </Button>
    </Paper>
  );
};

export default ChantierToast;

