import HomeBgReparation from "../../images/svg/homeBg.svg";
import { makeStyles } from "@mui/styles";

const RechercheStyle = makeStyles(() => ({
  TopContainer: {
    display: "flex",
    // flexDirection: "column",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  TopContainerReparation: {
    display: "flex",
    flexDirection: "row",
    backgroundImage: `url(${HomeBgReparation})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "rgba(0,0,0,1)",
  },
  SearchGrid: {
    borderRadius: "10px",
    backgroundColor: "white",
    width: "1300px",
    //height: "130px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "8vh",
    paddingTop: "17px",
    paddingBottom: "16px",
  },
  SearchContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "baseline",
  },
  SurfaceLabel: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    //marginRight: "5vw",
    //marginTop: "0.5vw",
  },
  TextField: {
    marginLeft: "3vw",
    borderRadius: "100px",
    width: "10vw",
    maxWidth: "200px",
  },
  TitleSolution: {
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "38px",
  },
  GridTitle: {
    margin: "auto",
    width: "1300px",
    marginTop: "28px",
    position: "relative",
  },
  GridSolution: {
    placeContent: "start",
    display: "flex",
    marginBottom: "3vh",
    flexWrap: "wrap",
  },
  BottomContainer: {
    //height: "36vh",
    fontSize: "large",
    color: "DimGrey",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10vh",
    width: "1100px",
    textAlign: "center",
    justifyContent: "center",
    position: "relative",
  },
  title: {
    display: "flex",
    width: "100vw",
    marginTop: "8vh",
    marginBottom: "5vh",
    fontSize: "36px",
    color: "white",
    justifyContent: "center",
  },
  button: {
    // marginTop: "2vh",
    // borderRadius: "25px",
    // fontSize: "large",
    // width: "12em",
    // marginLeft: "1vw",
    // marginBottom: "2vh",
    // backgroundColor: "#0079C2",
    // color: "white",
    // display: "block",
    // boxShadow: "none",
    // fontFamily: "Montserrat",

  },
  buttonDisabled: {
    // marginTop: "2vh",
    // borderRadius: "25px",
    // fontSize: "large",
    // width: "12em",
    // marginLeft: "1vw",
    // marginBottom: "2vh",
    // color: "white",
    // display: "block",
    // backgroundColor: "#0079C2",
    // opacity: 0.3,
    // fontFamily: "Montserrat",
  },
}));

export default RechercheStyle;
